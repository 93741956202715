/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect} from 'react';
import clsx from 'clsx';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    Snackbar,
    IconButton
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import * as ib from './ibdata'
import Divider from '@material-ui/core/Divider';
import * as iu from "./ImageUtils"
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import GridOnIcon from '@material-ui/icons/GridOn';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import * as bUtils from './BoardUtility'
import SchoolIcon from '@material-ui/icons/School';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import HistoryIcon from '@material-ui/icons/History';
import CropFreeIcon from '@material-ui/icons/CropFree';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import * as mylocalStorage from "./mylocalStorage"
import GavelIcon from '@material-ui/icons/Gavel';


var gFav = {}
const useStyles = makeStyles((theme) => ({
    icon: {
        // color: theme.palette.primary.main,
        color: '#ffffff',
        "&:hover": {
            color: 'white',
            fill: 'white',
        },
    },
    divider: {
        // backgroundColor: '#72869F',
        backgroundColor: '#FAFAFA',
    },
    listText: {
        color: '#FFFFFF',
        margin: '0 10px'
    },
    tooltip: {
        margin: "4px",
    },
    favIcon: {
        margin: "0",
        padding: "0",
        color: "#ffffff",
        marginLeft: 'auto',
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#1E2639",
        fontSize: "0.8rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        boxShadow:'0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16)'
    },
    customArrow: {
        color: "#1E2639",
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        "&:hover": {
            background: "#ffffffaa"
        }
    },
    itemTop: {
        paddingLeft: '0px'
    },
    itemIconTop: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: '40px'
    }
}));

var options = []
var randompages = "none";
let gSelect = 'Pencil'
function WbcJrLongMenu(props) {
    const inputFile = React.useRef(null)
    const importFile = React.useRef(null)

    const classes = useStyles();
    const teacherR = useSelector((state) => state.teacher);
    const boardTools = useSelector((state) => state.boardTools);

    const timeMachine = useSelector((state) => state.timeMachine);


    const boardConfig = useSelector((state) => state.BoardConfig);
    const [message, setMessage] = React.useState("")
    const [statusMsg, setStatusMsg] = React.useState("")
    const dispatch = useDispatch();
    var newOptions = [
        //pencil
        { name: 'Pencil', label:'Pencil', cb:drawBrush, iconType: 'svg', icon:'/tools/pencilIcon.svg', number:1},
        //text
        { name: 'Text',label:'Text', cb: clickText, iconType: 'svg', icon:'/tools/textIcon.svg', number:2},
        //line
        { name: 'Line1',label:'Line', cb: drawLine,iconType: 'svg', icon:'/tools/lineIcon2.svg', number:3},
        //sqaure
        { name: 'Rectangle',label:'Rectangle', cb: drawRect, iconType: 'svg', icon:'/tools/rectIcon.svg', number:4},
        //circle
        { name: 'Ellipse', label:'Ellipse',cb: drawCircle, iconType: 'svg', icon:'/tools/icon-ellipse.svg', number:5},
        //eraser
        { name: 'Erase',label:'Eraser', id: "button_erase", cb: eraseCb, iconType: 'svg', icon:'/tools/eraseIcon.svg', number:6},
        //hand/select
        { name: 'Pan', label: 'Hand', cb: selectCb, iconType: 'svg', icon: '/tools/PanHand.svg', number: 7},
        //zoom
        { name: 'Zoom', label: 'Zoom', cb: zoomInCb, iconType: 'svg', icon: '/tools/zoomInIcon.svg', number: 8 },
        //color 
        { name: 'Color', label: 'Color', cb: colorCb, number: 9 },
        { name: 'Table', label: 'Table', cb: TableFactory, iconType: 'svg', icon: '/tools/cells.svg', number: 10 },

    ];

    const [currentOptions, setcurrentOption] = React.useState(newOptions)
    const [activatedOption, setActivatedOption] = React.useState(1)
    const newTools = [
        { name: 'Add Media', cb: AddMedia, iconType: 'svg', icon: '/tools/CamAdd.svg' },
        { name: 'Break Down', cb: BreakApart, icon: <GavelIcon /> },
        { name: 'Drop Zone', cb: DropZone, icon: <ArrowDropDownCircleIcon /> },
        { name: 'Grids', cb: AddGrids, iconType: 'svg', icon: '/tools/Grid.svg' },
        { name: 'Infinite Cloner', cb: drawCloner, icon: <FileCopyIcon /> },
        { name: 'Pause Animations', id: "button_pause", iconType: 'svg', icon: '/tools/pauseanime.svg', isAnimate: true },
        { name: 'Select/Crop', cb: drawSelect, icon: <CropFreeIcon /> },
        { name: 'Time Machine', cb: timeMachineToggle, icon: <HistoryIcon /> },
    ]

    useEffect(()=>{
        if (props.statusMsg && props.statusMsg !== '') {
            setStatusMsg(props.statusMsg)
            props.setStatusMsg && props.setStatusMsg('')
        }
    },[props.statusMsg])

    function colorCb(){
        props.cb['ColorPicker']()
    }
    function selectCb(e) {
        let mr = { name: 'Move & Resize', label: 'Mover', cb: selectCb, iconType: 'svg', icon: '/tools/moveresizeIcon.svg', number: 7 }
        let selectOp = { name: 'Pan', label: 'Hand', cb: selectCb, iconType: 'svg', icon: '/tools/PanHand.svg', number: 7 };
        let tt= currentOptions
        let switchTool = (e === gSelect); 

        if (!switchTool) {
            if (e === 'Pan') {
                props.cb[selectOp.name](e)
            } else {
                props.cb[mr.name]()
            }
            gSelect = e
            return 
        }

        if (e === 'Pan') {
                tt.splice(6, 1, mr)
                props.cb[mr.name]()
                e = 'Move & Resize'
        } else {
            tt.splice(6, 1, selectOp)
            props.cb[selectOp.name](e)
            e = 'Pan'
        }
        gSelect = e 
        setcurrentOption([...tt])
    }
    function eraseCb() {
        props.cb['Erase']('Erase')
        gSelect = "Eraser"
    }

    function zoomInCb() {
        props.cb['ZoomIn']()
        gSelect = "ZoomIn"
    }


    const handleClickB = (event, list) => {
    };
    const handleClose = () => {
    };

    function statusSnackbar(msg) {
        setStatusMsg(msg)
    }


    function GridBox(e) {
        props.cb["GridBox"](true)
        handleClose()
    }

    function DotPainter(e) {
        props.cb["DotPainter"](true)
        handleClose()
    }
    function GridPainter(e) {
        props.cb["GridPainter"](true)
        handleClose()
    }
    function drawGridBig() {
        props.cb["drawGrid"](true)
        handleClose()
    }
    function drawElkonin() {
        props.cb["drawElkonin"](true)
        handleClose()
    }
    function clearGrid() {
        props.cb["clearGrid"](true)
        handleClose()
    }
    function drawGrid() {
        props.cb["drawGrid"](false)
        handleClose()
    }
    function drawRedWhite() {
        props.cb["drawRedWhite"]()
        handleClose()
    }
    function drawCollegeLine() {
        props.cb["drawCollegeLine"]()
        handleClose()
    }
    function drawCoordinatePlane() {
        handleClose()
    }

    function drawHandwrite() {
        props.cb["drawHandwrite"]()
        handleClose()
    }
    function drawHandwrite2() {
        props.cb["drawHandwrite2"]()
        handleClose()
    }
    function drawProgramGrid() {
        props.cb["drawProgramGrid"]()
        handleClose()
    }
    function drawMusic() {
        props.cb["drawMusic"]()
        handleClose()
    }
    const skipStudent = { "button_delete": true, "button_mgt": true }
    const skipSimple = {
        "button_save": true,
        "button_boards": true,
        "button_grids": true,
        "button_home": true,
        "button_mgt": true,
        "button_pause": true,
    }
    const keepfour = {
        "drawToolsIcon": true,
        "button_refresh": true,
        "button_move": true,
        "button_brush": true,
        "button_erase": true,
        "button_Text": true,
        "button_tools": true,
    }
    function hideIfInTeams(id) {
        if (window.insideMSTeams === true) {
            if (id === 'button_boards' ||
                id === 'button_home') {
                return true
            }
        }
        return false;
    }
    React.useEffect(() => {
        // setFavList()
    }, [])
    React.useEffect(() => {
            options = newOptions

        var currentOptions1 = [...options]
        var ff = []
        for (let i = 0; i < currentOptions1.length; i++) {
            if (!teacherR && currentOptions1[i].id in skipStudent) {
                continue
            }
            if (gFav[currentOptions1[i].name]) {
                continue
            }

            if (!Boolean(teacherR) && boardConfig.simpleDrawingTools && currentOptions1[i].id in skipSimple) {
                continue
            }
            if (!Boolean(teacherR) && boardConfig.fourToolsStudent && (!(currentOptions1[i].id in keepfour))) {
                continue
            }
            if (!Boolean(teacherR) && boardTools && boardTools[currentOptions1[i].name] && boardTools[currentOptions1[i].name].checked) {
                continue
            }
            let op = newTools.find(n=>n.isAnimate ===true)
            if(op){
                if (props.pauseMode) {
                    op.name = "Play Animations"
                    op.icon = '/tools/playanime.svg'
                } else {
                    op.name = "Pause Animations"
                    op.icon = '/tools/pauseanime.svg'
                }
            }
            if (currentOptions1[i].id === "button_pause") {
                currentOptions1[i].isAnimate= true 
                if (props.pauseMode) {
                    currentOptions1[i].name = "Play Animations"
                    currentOptions1[i].icon = '/tools/playanime.svg'
                } else {
                    currentOptions1[i].name = "Pause Animations"
                    currentOptions1[i].icon = '/tools/pauseanime.svg'
                }
            }
            if (hideIfInTeams(currentOptions1[i].id)) {
                continue
            }
            ff.push(currentOptions1[i])

        }
        // setcurrentOption([...favOptions, ...ff])
    }, [props.eraseMode, props.pauseMode, props.drawMode, teacherR, boardConfig, boardTools])
    React.useEffect(() => {
        var uploadOptions = [...UploadTools]
        var ff = []
        for (let i = 0; i < uploadOptions.length; i++) {
            if ((!Boolean(teacherR) && boardConfig.simpleDrawingTools) || !Boolean(teacherR)) {
                if (uploadOptions[i].name === "Upload Bingo" || uploadOptions[i].name === "Upload GamePlay" || uploadOptions[i].name === "Upload linked PDF") {
                    continue
                }
            }
            ff.push(uploadOptions[i])
        }
    }, [teacherR, boardConfig])
    const grids = [
        { name: 'Grid', cb: drawGrid, icon: <GridOnIcon /> },
        { name: 'Big Grid', cb: drawGridBig, icon: <BorderClearIcon /> },
        { name: 'Hand writing', cb: drawHandwrite, icon: <BorderColorIcon /> },
        { name: 'College Lined', cb: drawCollegeLine, icon: <SchoolIcon /> },
        { name: 'Red & White', cb: drawRedWhite, icon: <SchoolIcon /> },

        { name: 'Coordinate plane', cb: drawCoordinatePlane, icon: <ScatterPlotIcon /> },
        { name: 'Primary lined', cb: drawHandwrite2, icon: <BorderColorIcon /> },
        { name: 'Programming', cb: drawProgramGrid, icon: <GridOnIcon /> },

        { name: 'Music Sheet', cb: drawMusic, icon: <MusicNoteIcon /> },
        { name: 'Syllaboard', cb: drawSyllable, icon: <ImportContactsIcon /> },
        { name: 'Elkonin', cb: drawElkonin, icon: <BorderClearIcon /> },
        { name: 'Grid Painter', cb: GridPainter, icon: <FormatPaintIcon /> },
        { name: 'Grid Box', cb: GridBox, icon: <CheckBoxOutlineBlankIcon /> },
        { name: 'Dot Painter', cb: DotPainter, icon: <FiberManualRecordIcon /> },

        { name: 'Clear Grid', cb: clearGrid, icon: <ClearIcon /> },

    ]
    const UploadTools = [
        { name: 'Upload Files From Computer', cb: handleUploadFile, icon: <ImportantDevicesIcon /> },
        { name: 'Upload Files From Google Drive', cb: handleGoogleUpload, icon: <FilterDramaIcon /> },
        { name: 'Upload Bingo', cb: uploadBingo, iconType: 'svg', icon:'/tools/bingoIcon.svg' },
        { name: 'Upload GamePlay', cb: uploadAmongUs, iconType: 'svg', icon:'/tools/gamePlayIcon.svg' },
        { name: 'Upload linked PDF', cb: uploadPDF, iconType: 'svg', icon:'/tools/pdfIcon.svg' },
    ]
   

    
    const media = [
        { name: 'Add Webcam Photo', cb: WCClick, icon: <AddAPhotoIcon /> },
        { name: 'Add Webcam Video/Audio', cb: MediaClick, icon: <SettingsVoiceIcon /> },
    ]
  


    function timeMachineToggle() {
        handleClose()
        dispatch(Actions.setTimeMachine({
            ...timeMachine,
            open: !timeMachine.open
        }))
    }
   
    function handleUploadFile() {
        handleClose()
        randompages = "none"
        inputFile.current.click();
    }

    function handleGoogleUpload() {
        handleClose()
        randompages = "none"

    }


    function BreakApart() {
        props.cb['BreakApart']()
        handleClose()
    }
    function TableFactory() {
        gSelect = "Table"
        props.cb['TableFactory']()
        handleClose()
    }
   
    function DropZone() {
        props.cb['DropZone']()
        handleClose()
    }
    

    function drawCloner() {
        props.cb['handleCloner']('')
        handleClose()
    }
    
    function uploadBingo() {
        randompages = "bingo"
        inputFile.current.click();
        handleClose()
    }
    function uploadAmongUs() {
        randompages = "amongUs"
        inputFile.current.click();
        handleClose()
    }

    function AddMedia(e) {
        handleClickB(e, media)
    }
    
    function AddGrids(e) {
        handleClickB(e, grids)
    }


    function uploadPDF() {
    }
    
   
    function drawSyllable() {
        props.cb['drawSyllable']('')
        handleClose()
    }
  
    function drawBrush(e) {
        let wo = { name: 'WhiteOut', label: 'WhiteOut', cb: drawBrush, iconType: 'svg', icon: '/tools/whiteOutIcon.svg', number: 1 }
        let penOp = { name: 'Pencil', label: 'Pencil', cb: drawBrush, iconType: 'svg', icon: '/tools/pencilIcon.svg', number: 1 }
        let tt = currentOptions
        
        let switchTool = (e === gSelect); 

        if (!switchTool) {
            if (e === 'Pencil') {
                props.cb['Draw']('Draw')
            } else {
                props.cb['Draw']('Draw', 'white')
            }
            gSelect = e
            return 
        }

        if (e === 'Pencil') {
            tt.splice(0, 1, wo)
            props.cb['Draw']('Draw', 'white')
            e = 'WhiteOut';
        } else {
            tt.splice(0, 1, penOp)
            props.cb['Draw']('Draw')
            e = 'Pencil'
        }
        gSelect = e 
        setcurrentOption([...tt])
    }
    
    function drawCircle(e) {
        handleClose()
        let elipOp = { name: 'Ellipse', label: 'Ellipse', cb: drawCircle, iconType: 'svg', icon: '/tools/icon-ellipse.svg', number:5}
        let cirOp =  { name: 'Circle', label:'Circle',cb: drawCircle, iconType: 'svg', icon:'/tools/icon-circle.svg', number:5}
        let tt = currentOptions

        let switchTool = (e === gSelect); 

        if (!switchTool) {
            if (e === 'Ellipse') {
                props.cb['Circle']()
            } else {
                props.cb['Circle']('Circle')
            }
            gSelect = e
            return 
        }

        if (e === 'Ellipse') {
            tt.splice(4, 1, cirOp)
            props.cb['Circle']('Circle')
            e = 'Circle'
        } else {
            tt.splice(4, 1, elipOp)
            props.cb['Circle']()
            e = 'Ellipse'
        }
        gSelect = e 
        setcurrentOption([...tt])
    }
    function drawRect(e) {
        handleClose()
        let rectOp = { name: 'Rectangle', label: 'Rectangle', cb: drawRect, iconType: 'svg', icon: '/tools/rectIcon.svg', number: 4 }
        let sqrOp = { name: 'Square', label: 'Square', cb: drawRect, iconType: 'svg', icon: '/tools/sqaureIcon.svg', number: 4 }
        let tt = currentOptions

        let switchTool = (e === gSelect); 

        if (!switchTool) {
            if (e === 'Rectangle') {
                props.cb['Rect']()
            } else {
                props.cb['Rect']('Square')
            }
            gSelect = e
            return 
        }

        if (e === 'Rectangle') {
            tt.splice(3, 1, sqrOp)
            props.cb['Rect']('Square')
            e = 'Square'
        } else {
            tt.splice(3, 1, rectOp)
            props.cb['Rect']()
            e = 'Rectangle'
        }
        gSelect = e 
        setcurrentOption([...tt])
    }
    function drawSelect() {
        props.cb['drawSelect']()
        handleClose()
    }
    function drawLine(e) {
        handleClose()
        //Line1 is normal 
        let line1 = { name: 'Line1', label: 'Line', cb: drawLine, iconType: 'svg', icon: '/tools/lineIcon2.svg', number: 3 }
        let line2 = { name: 'Line2', label: 'Line', cb: drawLine, iconType: 'svg', icon: '/tools/lineIcon1.svg', number: 3 }
        let tt = currentOptions
        let switchTool = (e === gSelect); 

        if (!switchTool) {
            if (e === 'Line1') {
                props.cb['Line']()
            } else {
                props.cb['Line']('Line')
            }
            gSelect = e
            return 
        }

        if (e === 'Line1') {
            tt.splice(2, 1, line2)
            props.cb['Line']('Line')
            e = 'Line2'
        } else {
            tt.splice(2, 1, line1)
            props.cb['Line']()
            e = 'Line1'
        }
        gSelect = e
        setcurrentOption([...tt])
    }
    
    async function loadImage(img) {
        const f = img.target.files
        if (f.length !== 1) return
        props.cb["Message"]("Loading image, please wait")
        var res = 1150
        if (boardConfig.boardResolution) {
            var r = ib.boardResoltionType[boardConfig.boardResolution]
            if (r) {
                var touse = r.h > r.w ? r.w : r.h
                if (touse < res) res = touse - 100
            }
            if (boardConfig.boardResolution === "a4") res = 792
        }
        var ff = await iu.UploadFile(f[0], randompages,
            props.sess ? props.sess.ttl : 0, res)
        if (ff) props.cb.loadImage(ff)
    }

    async function loadFile(file) {
        var user = mylocalStorage.getItem('mystoreID');
        bUtils.loadFile(file, user, statusSnackbar)
    }


    //  this is to check whether sidebar is opned? if so not allowing to use features on whiteboard
    function IsAllowedToUse() {
        var lu = mylocalStorage.getItem('sidebar')
        var allowedToUse = true
        if (lu) allowedToUse = false
        return allowedToUse
    }

    const [wcdialog, setWCDialog] = React.useState({ open: false, cb: null })
    function WCClick() {
        if (!IsAllowedToUse()) {
            return
        }
        handleClose()
        if (wcdialog.open) {
            return
        }
        setWCDialog({ open: true, cb: done })

        async function done(e) {
            setWCDialog({ open: false, cb: null })
            if (e) {
                var ff = await iu.UploadFile(e, false,
                    props.sess ? props.sess.ttl : 0)
                if (ff) props.cb.loadImage(ff)
            }
        }
    }
    const [mediaDialog, setMediaDialog] = React.useState({ open: false, cb: null })
    function MediaClick() {
        if (!IsAllowedToUse()) {
            return
        }
        handleClose()
        if (mediaDialog.open) {
            return
        }
        var video = false
        if (boardConfig.StudentWebcamVideo) video = true
        setMediaDialog({ open: true, cb: done, videoOn: video })

        async function done(e) {
            setMediaDialog({ open: false, cb: null })
            if (e) {
                var ff = await iu.wroteFile(e.file, null, null, "mp4")
                if (ff) props.cb.mediaInsert({ file: ff, name: e.name })
            }
        }
    }


    function clickText(){
        props.cb['Text']()
        gSelect = 'Text'
        handleClose()
    }

    function handleCb(option, index) {
        if (option.name !== 'Color') {
            setActivatedOption(option.number)
        }
        option.cb(option.name)
    }

    return (
        <>

            <div id='wbcMenu' className='longMenuDiv'>
                {/* Resetting the value as we are not going to be using the built-in file input value at all. */}
                <input type='file' id='file' accept="application/pdf,image/*" onChange={loadImage} ref={inputFile} style={{ display: 'none' }} value={""} />
                <input type='file' id='importFile' accept=".zip,application/json,.wbc" onChange={loadFile} ref={importFile} style={{ display: 'none' }} value={""} />
                <List id="menuBar" dense={true} style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', overflowX: 'hidden' }}>
                    {currentOptions.map((option, index) => (
                        <React.Fragment key={option.name}>
                            {/* <Tooltip className={classes.tooltip}
                                classes={{
                                    tooltip: classes.customTooltip,
                                    arrow: classes.customArrow
                                }}
                                title={option.name} arrow placement="right"> */}
                            <ListItem button
                                classes={{ root: clsx('tool', classes.item, [classes.itemTop]) }}
                                onClick={(e) => handleCb(option)} id={option.id}
                                selected={option.number === activatedOption} >
                                        <ListItemIcon className={classes.icon}
                                            classes={{
                                                root: clsx(classes.itemIconTop, 'newItemTop')
                                            }}
                                        >
                                          {option.iconType ?
                                        <ListItemIcon className={classes.icon}
                                            classes={{
                                                root: clsx(classes.itemIconTop, 'newItemTop')
                                            }}
                                        >
                                            <img src={option.icon} alt={option.icon} />
                                        </ListItemIcon>
                                        :
                                        <IconButton className='colorICBtn' style={{ background: props.penBtnColor}} aria-label="Pen Opacity"  >
                                        <span ></span>
                                    </IconButton>
                                    }
                                        </ListItemIcon>
                                <ListItemText className='tool-name' primary={option.label ? option.label : option.name} />
                               
                            </ListItem>
                                {/* </Tooltip> */}
                            {option.dnext && (<Divider className={classes.divider} />)}

                        </React.Fragment>

                        // <div key={option.name}>
                        //     <Tooltip arrow title={option.name} placement="left" >
                        //         <IconButton className="minFrameButton" size="small"
                        //             onClick={() => handleClick(option)} >
                        //             {option.icon}
                        //         </IconButton>
                        //     </Tooltip>
                        // </div>
                    ))}
                </List>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={message !== ""}
                    onClose={() => setMessage("")}
                    message={message}
                    key={"top center"}
                    autoHideDuration={3000}
                />

                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={statusMsg !== ""}
                    onClose={() => setStatusMsg("")}
                    message={statusMsg}
                    key={"status SnackBar"}
                    autoHideDuration={3000}
                />
            </div>
        </>
    );
}

export default React.memo(WbcJrLongMenu)
