import * as autoQuery from './graphql/queries'
import * as autoMutation from './graphql/mutations'
import * as autoSub from './graphql/subscriptions'
import * as customops from './graphql/customops'

import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuid } from "uuid";
import { Auth } from 'aws-amplify';
import pako from 'pako'
import * as mylocalStorage from "./mylocalStorage";
import { copyFile } from './ImageUtils';

const OBS = process.env.REACT_APP_OBS

// 946684800 = 1/1/2000 start of day
// AWS TTL mechanism will ignore items with expiry more than 5 years ago.
// Using 950000000 so it is easy to identify in ddb.
// 950000000 = Tuesday, February 8, 2000 8:53:20 AM GMT
export const NO_EXPIRY_TTL = 950000000
export const MAX_SAVED_BOARDS_BASIC_PAID_PLAN = 20;

function getTTL() {
    if (Auth && Auth.user) {

        return getTTL2Week()
    }
    return getTTL2Week()
    // const secondsSinceEpoch = Math.round(Date.now() / 1000);
    // const SECONDS_IN_AN_HOUR = 60 * 60;
    // const expirationTime = secondsSinceEpoch + 8 * SECONDS_IN_AN_HOUR; //expire in 8 hours 
    // return expirationTime
}

function getTTL3Days() {
    const secondsSinceEpoch = Math.round(Date.now() / 1000);
    const SECONDS_IN_AN_HOUR = 60 * 60;
    const HOURS_IN_3_DAYS = 24 * 3
    const expirationTime = secondsSinceEpoch + 3 * SECONDS_IN_AN_HOUR * HOURS_IN_3_DAYS; //expire in 3 hours 
    return expirationTime
}

const wait = ms => new Promise(r => setTimeout(r, ms));
const MAX_RETRY_COUNT = 2;
const RETRY_DELAY = 500; // 0.5 second in milliseconds.
const SUB_RETRY_DELAY = 3000; // 3 seconds in milliseconds
const SUB_MAX_RETRY_COUNT = 3;
const MAX_RELOAD_TIMEOUT = 900; // 15 Mins (in secs)
const s3HostPattern = /^https:\/\/[\w-]+.s3.[\w-]+.amazonaws.com/i;
const s3UrlPattern = /https:\/\/[\w-]+.s3.[\w-]+.amazonaws.com\/public\/(images|paidImages)\/[\w-]+.\w+/gi;

function handleCommonErrors(errStr, gaAction, context) {
    var dt = Math.floor(Date.now().valueOf() / 1000)
    var timeSet = mylocalStorage.getItem('maxSubReloadTime')

    if (!timeSet || ((dt - timeSet) > MAX_RELOAD_TIMEOUT)) {
        let msidx = errStr.indexOf('MaxSubscriptionsReachedError');
        if (msidx !== -1) {
            mylocalStorage.setItem('maxSubReloadTime', dt)
            sendReactGAEvent("MAX_SUBCRIPTION_ERROR", gaAction,
                errStr, context.retryCount);
            // Hit MaxSubscriptions error, reload the page...
            window.location.reload();
            return true;
        }
    }

    return false;
}

function checkAndReSubscribe(retried, gaAction, errorObj,
    unSubObj, subFunc, context) {
    if (retried) return;
    context.retryCount -= 1;

    let errStr = getErrorString(errorObj);
    if (handleCommonErrors(errStr, gaAction, context)) return;

    if (context.retryCount >= (SUB_MAX_RETRY_COUNT - 1)) {
        sendReactGAEvent("SUBCRIPTION_ERROR", gaAction,
            errStr, context.retryCount);
    }
    if (!(context.retryCount > 0)) {
        sendReactGAEvent("SUBCRIPTION_ERROR_GIVEUP", gaAction,
            errStr, context.retryCount);
        return;
    }
    try {
        if (unSubObj) unSubObj.unsubscribe();
    } catch (e) {
        // Ignore error
    }
    context.doList = false;
    wait(SUB_RETRY_DELAY).then(() => {
        subFunc(context);
    });
}

// label has the actual error string
function sendReactGAEvent(category, label, action, value) {
    let msg = {
        category: category,
        // action cannot be more than 500 characters
        action: action.slice(0, 499)
    }

    if (label) msg.label = label.slice(0, 499);
    if (value) msg.value = value
}

export function getErrorString(error) {
    let errArr = null;
    let errStr = "";

    if (error.errors) {
        errArr = error.errors;
    } else if (error.error && error.error.errors) {
        errArr = error.error.errors;
    } else if (error.stack && typeof error.stack === "string") {
        let tmpIdx = error.stack.indexOf('\n');
        if (tmpIdx !== -1) errStr = error.stack.slice(0, tmpIdx);
    } else if (error.message) {
        errStr = error.message;
    }

    if (errArr) {
        for (let idx in errArr) {
            let item = errArr[idx];

            if (item.errorType && item.message) {
                errStr = `${item.errorType}: ${item.message}`
            } else if (item.message) {
                errStr = item.message
            } else if (item.errorType) {
                errStr = item.errorType
            }

            if (errStr !== "") break;
        }
    }

    if (errStr === "") errStr = "getErrorString: Could not parse error"

    // replace "@" symbol from error string b/c reactGA gets confused
    // and thinks that there is an email and redacts the whole error string
    return errStr.replace("@", "--");
}

function trySubscribe(subObj, subArgs, subAction) {
    var sub = null;
    try {
        sub = subObj.subscribe(subArgs)
    } catch (error) {
        sendReactGAEvent("TRY_SUBCRIPTION_ERROR", subAction,
            getErrorString(error));
        console.log("trySubscribe: got error:", error);
    }

    return sub;
}

function getQueryName(query) {
    let q1 = query.replace(/^\s+/g, '');
    let q2;
    try {
        q2 = q1.split('(')[0]
        return q2.split(" ")[1]
    } catch (e) {
        // Ignore error
    }

    let errIdx = q1.indexOf("\n");
    return (errIdx !== -1) ? q1.slice(0, errIdx) : query.slice(0, 20);
}

// based on: https://stackoverflow.com/questions/38213668/promise-retry-design-patterns
// answer by: Yair Kukielka
const gqlOperationWithRetry = (query, args, delay, retryCount) => new Promise((resolve, reject) => {
    if ((delay === null) || (delay === undefined)) delay = RETRY_DELAY;
    if ((retryCount === null) || (retryCount === undefined)) retryCount = MAX_RETRY_COUNT;
    //console.log("gqlOperationWithRetry:", args);
    return API.graphql(graphqlOperation(query, args))
        .then(resolve)
        .catch((error) => {
            let errStr = getErrorString(error);
            let qStr = getQueryName(query);
            console.log("gqlOperationWithRetry:", error, args);
            try {
                let doRetry = true;
                if (("errors" in error) && Array.isArray(error.errors)) {
                    for (let idx in error.errors) {
                        if (error.errors[idx].errorType === "DynamoDB:ConditionalCheckFailedException") {
                            doRetry = false;
                            errStr = `DynamoDB:ConditionalCheckFailedException`
                            qStr = `${qStr}: ${JSON.stringify(args)}`;
                            break;
                        }
                        if (error.errors[idx].errorType === "DynamoDB:DynamoDbException") {
                            doRetry = false;
                            errStr = `DynamoDB:DynamoDbException`
                            qStr = `${qStr}: ${JSON.stringify(args)}`;
                            break;
                        }
                        // TODO: Add checks for more errors here.
                    }
                } else if (error.message || error.stack) {
                    // Try to retry
                } else {
                    // Could not figure out the error type.
                    doRetry = false;
                }
                if (doRetry) {
                    // Check & Retry the API call
                    retryCount = retryCount - 1;

                    if (retryCount > 0) {
                        if (retryCount >= (MAX_RETRY_COUNT - 1)) {
                            sendReactGAEvent("GQL_OPER_ERROR_RETRY", qStr,
                                errStr, retryCount);
                        }
                        return wait(delay)
                            .then(gqlOperationWithRetry.bind(null, query, args, delay, retryCount))
                            .then(resolve)
                            .catch(reject);
                    }
                }
            } catch (e) {
                // got an error while processing API call error, just break out
                sendReactGAEvent("GQL_OPER_HANDLER_ERROR", qStr,
                    getErrorString(e), retryCount);
                console.log("gqlOperationWithRetry: Got an error while processing API call error:", e)
            }

            sendReactGAEvent(retryCount ? "GQL_OPER_ERROR" : "GQL_OPER_ERROR_GIVEUP",
                qStr, errStr, retryCount);
            return reject(error);
        })
})

function replaceChars(origStr, allowedLen, oneChar) {
    let retStr = "";
    if (origStr.length > allowedLen) {
        let repLen = allowedLen / 2;

        if (Math.floor(repLen) !== repLen) {
            // It an odd number
            repLen = Math.floor(repLen) - 2;
            retStr = origStr.slice(0, repLen) + oneChar.repeat(5) + origStr.slice(origStr.length - repLen, origStr.length)
        } else {
            repLen = repLen - 2;
            retStr = origStr.slice(0, repLen) + oneChar.repeat(4) + origStr.slice(origStr.length - repLen, origStr.length)
        }
    } else {
        retStr = origStr;
    }

    return retStr;
}

export function getUserString(userName, userEmail) {
    let MAX_EMAIL_LEN = 56;
    if (userName && userEmail) {
        try {
            let socialLogin = "";
            if (userName.startsWith("Google")) {
                socialLogin = "(via Google)"
            } else if (userName.startsWith("Facebook")) {
                socialLogin = "(via Facebook)"
            } else if (userName.startsWith("Microsoft")) {
                socialLogin = "(via Microsoft)"
            }

            let email = userEmail;

            // upto MAX_EMAIL_LEN chars show nicely in the dialog box
            if (email.length > MAX_EMAIL_LEN) {
                let sp = email.split("@");
                if ((sp[0].length > (MAX_EMAIL_LEN / 2)) &&
                    (sp[1].length > (MAX_EMAIL_LEN / 2))) {
                    // both parts (username and domain) are greater than MAX_EMAIL_LEN / 2
                    email = replaceChars(sp[0], MAX_EMAIL_LEN / 2, '*') + '@' + replaceChars(sp[1], MAX_EMAIL_LEN / 2, '*');
                } else if ((sp[0].length > (MAX_EMAIL_LEN / 2)) &&
                    (sp[1].length <= (MAX_EMAIL_LEN / 2))) {
                    // only username is greater than MAX_EMAIL_LEN / 2
                    email = replaceChars(sp[0], MAX_EMAIL_LEN - sp[1].length, '*') + '@' + sp[1];
                } else {
                    // only domain is greater than MAX_EMAIL_LEN / 2
                    email = sp[0] + '@' + replaceChars(sp[1], MAX_EMAIL_LEN - sp[0].length, '*');
                }
            }
            return `${email} ${socialLogin}`
        } catch (e) {
            // Ignore error
            console.log("getUserString:", e);
        }
    }
    return "";
}

export function getTTL2Week() {
    const secondsSinceEpoch = Math.round(Date.now() / 1000);
    const SECONDS_IN_AN_HOUR = 60 * 60;
    const HOURS_IN_2_WEEK = 24 * 14
    // Reduced from previous 6 weeks to 4 weeks
    const expirationTime = secondsSinceEpoch + 2 * SECONDS_IN_AN_HOUR * HOURS_IN_2_WEEK; //expire in 3 hours 
    return expirationTime
}

export function genJoinCode() {
    var array = new Uint8Array(7);
    window.crypto.getRandomValues(array);
    var code = ""
    const chars = "abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    for (var i = 0; i < array.length; i++) {
        code += chars[array[i] % chars.length]
    }
    return code;
}

export const KEEPALIVE_TIMEOUT_SECONDS = 60;

export function createSession(id, name, content, parentId,
    pageNumber, parentBoardID, boardConfig, classr, isGroup = false, ttl = 0) {
    const cmd = {
        id: id, 'name': name, 'content': content, ttl: (!ttl) ? getTTL() : ttl,
        'parentID': parentId, pageNumber: pageNumber,
        'isGroup': isGroup, joinCode: genJoinCode().toString()
    }
    if (boardConfig) cmd['boardConfig'] = boardConfig
    if (parentBoardID) {
        cmd['parentBoardID'] = parentBoardID
    } else {
        cmd['parentBoardID'] = id//parentId for all otherwise its page to page 
    }
    var luid = mylocalStorage.getItem('mystoreID');
    if (!luid) {
        luid = uuid()
        mylocalStorage.setItem('mystoreID', luid)
    }
    cmd['CreatorLocalID'] = luid
    if (classr) cmd["Classroom"] = classr
    return gqlOperationWithRetry(autoMutation.createSession, { input: cmd });
}

export function createSessionPlain(cmd) {
    return gqlOperationWithRetry(autoMutation.createSession, { input: cmd });
}
export function deleteSessionPlain(id) {
    return gqlOperationWithRetry(autoMutation.deleteSession, { input: { id: id } })
}
export function createSessionIfNotThere(id) {
    getSession(id).then((res) => {
        var sess = res.data.getSession
        if (!sess) createSessionNotFound(id)
    })
}
export function createSessionNotFound(id) {
    return new Promise((resolve, reject) => {
        const pgSplit = id.split("-pgNum-")
        if (pgSplit.length !== 2) {
            reject(new Error("Invalid URL"))
            return
        }
        getSession(pgSplit[0] + "-pgNum-1").then((res) => {
            var pg1 = res.data.getSession
            if (!pg1) {
                reject(new Error("Invalid URL with no page1"))
                return
            }
            pg1.id = id
            pg1.pageNumber = pgSplit[1]
            const parentSplit = pg1.parentBoardID.split("-pgNum-")
            pg1.parentBoardID = parentSplit[0] + "-pgNum-" + pgSplit[1]
            delete pg1['createdAt']
            delete pg1['updatedAt']
            delete pg1['Objects']
            delete pg1['Users']
            if (pg1['savedOwner'] === null) {
                delete pg1['savedOwner']
            }
            if (pg1['Classroom'] === null) {
                delete pg1['Classroom']
            }
            delete pg1['Users']
            // When a session is being created, we cannot have folderID be equal to
            // an empty string ("") or null. If we don't have a valid folderID,
            // remove the key from the object being created.
            if (!(pg1["folderID"])) {
                delete pg1["folderID"]
            }
            delete pg1['MultiBoard']
            gqlOperationWithRetry(autoMutation.createSession, { input: pg1 }).then(r => {
                resolve(r)
            })
        })
    })
}

export async function findCreateSession(classroom) {
    return new Promise(async (resolve, reject) => {
        var nt = null
        var luid = mylocalStorage.getItem('mystoreID');

        do {
            const cmd = {
                limit: 100, nextToken: nt, sortDirection: "ASC",
                CreatorLocalID: luid, filter: { Classroom: { eq: classroom } }
            }
            var res = await gqlOperationWithRetry(autoQuery.queryByCreatorLocalId, cmd)
            const dat = res.data.queryByCreatorLocalId
            if (dat && dat.items) {
                for (let i = 0; i < dat.items.length; i++) {
                    const d = dat.items[i]
                    resolve(d)
                }
            }
            nt = dat.nextToken
        } while (nt !== null)
        resolve(null)
    })
}
export async function findSessionbyID(classroom, luid) {
    return new Promise(async (resolve, reject) => {
        var nt = null
        do {
            const cmd = {
                limit: 100, nextToken: nt, sortDirection: "ASC",
                CreatorLocalID: luid, filter: { Classroom: { eq: classroom } }
            }
            var res = await gqlOperationWithRetry(autoQuery.queryByCreatorLocalId, cmd)
            const dat = res.data.queryByCreatorLocalId
            if (dat && dat.items) {
                for (let i = 0; i < dat.items.length; i++) {
                    const d = dat.items[i]
                    resolve(d)
                }
            }
            nt = dat.nextToken
        } while (nt !== null)
        resolve(null)
    })
}
export async function deleteUserAndBoard(classroom, luid) {
    findSessionbyID(classroom, luid).then((r) => {
        var pp = r.id.split("-pgNum-")
        getboardsbyparent(pp[0], null, delPages)
        function delPages(items) {
            if (!items) return
            items.forEach((item) => {
                try {
                    deletePage(item.id, function () { })
                } catch (err) {
                    console.log("ERROR DELETE", err)
                    // Ignore it
                }
            })
        }
    })
    const localId = luid + "-CL-" + classroom
    getLocalUsers(localId).then((dat) => {
        const lu = dat.data.getLocalUsers

        if (lu && lu.CurrentUser) {
            deleteUser(lu.CurrentUser)
        }
    })
    await new Promise(r => setTimeout(r, 1000));

    deleteLocalUsers(localId)
}
export async function findCreateSessionByName(classroom) {
    return new Promise(async (resolve, reject) => {
        var nt = null
        var luid = mylocalStorage.getItem('mystoreID');
        var name = mylocalStorage.getItem('myname');
        var luname = name.toLowerCase()
        luname = luname.replace(/\s/g, "")
        do {
            const cmd = {
                limit: 100, nextToken: nt, sortDirection: "ASC", Classroom: classroom
            }

            var res = await gqlOperationWithRetry(customops.queryByClassroom, cmd)
            const dat = res.data.queryByClassroom
            if (dat && dat.items) {
                for (let i = 0; i < dat.items.length; i++) {
                    const d = dat.items[i]
                    if (d.CreatorLocalID === luid) {
                        resolve(d)
                        return
                    }
                }
                // // did not find any
                // if (!timeSet || dt - timeSet > 100) {
                //     resolve(null)
                //     return
                // }

                for (let i = 0; i < dat.items.length; i++) {
                    const d = dat.items[i]
                    if (d.isGroup) continue
                    // const localId = d.CreatorLocalID + "-CL-" + classroom
                    if (!d.Users || !d.Users.items) continue

                    for (let j = 0; j < d.Users.items.length; j++) {
                        const uu1 = d.Users.items[j]
                        var js
                        try {
                            js = JSON.parse(uu1.content)
                        } catch {
                            continue
                        }

                        if (js.localID === d.CreatorLocalID) {
                            if (!uu1.name || uu1.name === "") continue
                            let uname = uu1.name.toLowerCase().replace(/\s/g, "")
                            if (uname === luname) {
                                // set old luid back 
                                mylocalStorage.setItem("mystoreID", js.localID)
                                resolve(d)
                                return
                            }
                        }
                    }
                }
                nt = dat.nextToken
            }
        } while (nt !== null)
        resolve(null)
    })
}

export function delObject(id) {
    return gqlOperationWithRetry(autoMutation.deleteObject, { input: { id: id } });
}

export function deletePage(id, cb) {
    listObject(id, null, function (items) {
        items.forEach((item) => {
            delObject(item.id)
        })
    })
    gqlOperationWithRetry(autoMutation.deleteSession,
        { input: { id: id } }).then(() => {
            if (cb) cb()
        });
}

export async function duplicatePage(id, newNum, copy) {
    return new Promise(async (resolve, reject) => {
        const pgSplit = id.split("-pgNum-")
        // var pages = await getboardsbyparentSync(pgSplit[0])
        // //delete page
        // for (let i = pages.length - 1; i >= page; i--) {
        //     var foo = await updatePageNumer(pages[i].id, i + 2)
        // }
        const oldSessionID = id
        var oldPageVal = await getSession(oldSessionID)
        var sess = oldPageVal.data.getSession
        if (sess) {
            var pluson = newNum
            sess.id = pgSplit[0] + "-pgNum-" + pluson
            sess.pageNumber = newNum
            sess.parentBoardID = sess.id
            delete sess['Objects']
            delete sess['Users']
            delete sess['MultiBoard']
            delete sess['folderID']
            if (sess['savedOwner'] === null) {
                delete sess['savedOwner']
            }
            var p = await createSessionPlain(sess)
            if (copy) {
                listObject(oldSessionID, null, async function (items) {
                    items.forEach((item) => {
                        delete item['Session']
                        delete item['id']
                        item.SessionID = sess.id

                        createObjPlain(item)
                    })
                    await new Promise(r => setTimeout(r, 300));
                    resolve(p.data.createSession)
                })
            } else {
                await new Promise(r => setTimeout(r, 300));
                resolve(p.data.createSession)

            }
        }
    })
}

export async function updateClassBoardsOrderAll(sess, bo) {
    return new Promise(async (resolve, reject) => {
        const pgSplit = sess.id.split("-pgNum-")
        var pages
        if (sess.Classroom)
            pages = await getSessionByClassroomSync(sess.Classroom)
        else
            pages = await getboardsbyparentSync(pgSplit[0])
        for (let i = 0; i < pages.length; i++) {
            var page = pages[i]
            var bc = JSON.parse(page.boardConfig)
            bc.boardOrder = bo
            page.boardConfig = JSON.stringify(bc)
            await updateSession(page)
        }
        resolve()
    })
}

export async function updateClassBoardsTools(sess, bo) {
    return new Promise(async (resolve, reject) => {
        const pgSplit = sess.id.split("-pgNum-")
        var pages
        if (sess.Classroom)
            pages = await getSessionByClassroomSync(sess.Classroom)
        else
            pages = await getboardsbyparentSync(pgSplit[0])
        for (let i = 0; i < pages.length; i++) {
            var page = pages[i]
            var bc = JSON.parse(page.boardConfig)
            bc.boardTools = bo
            page.boardConfig = JSON.stringify(bc)
            await updateSession(page)
        }
        let luid = mylocalStorage.getItem('mystoreID');
        var lu = await getUserProfie(luid)
        if (lu && lu.data.getUserProfile) {
            var r = lu.data.getUserProfile
            var p = r.boardConfig ? JSON.parse(r.boardConfig) : {}
            p.boardTools = bo
            r.boardConfig = JSON.stringify(p)
            updateUserProfile(r)
        }
        resolve()
    })
}

export async function updateClassBoardsOrder(sess) {
    return new Promise(async (resolve, reject) => {
        const pgSplit = sess.id.split("-pgNum-")
        var pages
        if (sess.Classroom)
            pages = await getSessionByClassroomSync(sess.Classroom)
        else
            pages = await getboardsbyparentSync(pgSplit[0])
        for (let i = 0; i < pages.length; i++) {
            var page = pages[i]
            var bc = JSON.parse(page.boardConfig)
            bc.boardHasSkipPage = true
            if (page.id === sess.id) bc.hidden = true
            page.boardConfig = JSON.stringify(bc)
            await updateSession(page)
        }
        resolve()
    })
}
export async function deletePageNumber(id) {
    return new Promise(async (resolve, reject) => {
        deletePage(id, done)
        async function done() {
            resolve()
        }
    })
}
export function updatePageNumer(id, newPage) {
    return new Promise((resolve, reject) => {
        const pgSplit = id.split("-pgNum-")
        const oldParent = id.split("-pgNum-")
        var newParent = oldParent[0] + "-pgNum-" + newPage
        var newID = pgSplit[0] + "-pgNum-" + newPage
        var prom = []
        getSession(id).then(async (r) => {
            var sess = r.data.getSession
            if (!sess) {
                return
            }
            var items = await listObjectSync(id)

            items.forEach((item) => {
                item.SessionID = newID
                delete item['Session']
                var p = updateObject(item)
                prom.push(p)
            })
            sess.id = newID
            sess.pageNumber = newPage
            sess.parentBoardID = newParent
            delete sess['Objects']
            delete sess['Users']
            delete sess['MultiBoard']
            delete sess['folderID']

            var p = createSessionPlain(sess)
            prom.push(p)
            p = deleteSessionPlain(id)
            prom.push(p)
            Promise.all(prom).then(() => {
                resolve()
            })
        })
    })

}
export function delSession(id, cb) {
    const ttl = getTTL()
    const pgSplit = id.split("-pgNum-")
    const ids = uuid()
    const pg1 = ids + "-pgNum-" + pgSplit[1]

    createSession(pg1, "backup", "backup", ids, pgSplit[1]).then((res) => {
        const back = res.data.createSession
        //move all old objects
        listObject(id, null, function (items) {
            items.map((item) => {
                item.SessionID = back.id
                item.ttl = ttl
                delete item['Session']
                updateObject(item)
                return null
            })
        })
        gqlOperationWithRetry(autoMutation.deleteSession, { input: { id: id } }).then(() => {
            if (cb) cb()
        });
    })
}

function checkObjLocked(obj) {
    if (obj.objType !== "drawPaper") return false

    try {
        if ('type' in obj) {
            obj['type'] = JSON.parse(obj['type'])
            if (obj.type && obj.type.compressed) {
                obj.content = pako.inflate(obj.content, { to: 'string' });
            }
        }
    } catch (e) {
        console.error("cannot decode", e, obj)
        return false
    }
    var rr
    try {
        rr = JSON.parse(obj.content)
    } catch {
        return false
    }
    if (!rr || !rr.length || rr.length < 2) return false
    if (rr[1] && rr[1].data && rr[1].data.lock) return true

    return false
}
export async function delAllObjSession(id, group, userId) {
    listObject(id, null, function (items) {
        items.forEach((item) => {
            if (group && userId && item.CreatedBy && userId !== item.CreatedBy) {
                return
            }
            if (!checkObjLocked(item))
                delObject(item.id)
        })
    })
}

export function updateSession(cmd) {
    delete cmd['Objects']
    delete cmd['Users']
    delete cmd['MultiBoard']
    // If a session is moved from within a folder to the root folder,
    // folderID is set to null so that the folderID field is removed
    // from the session object. folderID can be set to null while
    // updating a session. It cannot be set to an empty string ("")
    // while updating a session
    if (cmd["folderID"] === "") {
        delete cmd["folderID"]
    }
    if (cmd["folderID"] === undefined) {
        delete cmd["folderID"]
    }
    return gqlOperationWithRetry(autoMutation.updateSession, { input: cmd });
}

export function getSession(id) {
    return gqlOperationWithRetry(autoQuery.getSession, { 'id': id });
}

export function getSessionByJoinCode(joinCode) {
    return gqlOperationWithRetry(customops.queryByJoinCode, { 'joinCode': joinCode });
}

export function getUserProfie(id) {
    return gqlOperationWithRetry(autoQuery.getUserProfile, { 'id': id });
}

export function createUserProfile(id, name, email, avatar) {
    var emailOptIn = mylocalStorage.getItem('emailOptIn');
    const cmd = { id: id, 'name': name, 'email': email, 'emailOptIn': emailOptIn !== null ? emailOptIn : false }
    //if value is there ok else by default true email }

    if (avatar && avatar !== "") {
        cmd['avatar'] = avatar;
    }

    return gqlOperationWithRetry(autoMutation.createUserProfile, { input: cmd });
}

export function updateUserProfile(obj) {
    var name = mylocalStorage.getItem('myname');
    if (name && obj.name === null) {
        obj.name = name
    }

    return gqlOperationWithRetry(autoMutation.updateUserProfile, { input: obj });
}

function updateClassIfNeeded(cb, url, up) {
    if (!url) {
        cb(up)
        return
    }
    const JOINURL = url.split("/board/")
    if (JOINURL.length < 2) {
        cb(up)
        return
    }
    var luid = mylocalStorage.getItem('backupLocalID');
    if (!luid) {
        luid = mylocalStorage.getItem('mystoreID'); //timing if not updated yet
        if (!luid) {
            cb(up)
            return
        }
    }
    if (luid === up.id) {
        cb(up)
        return
    }
    getSession(JOINURL[1]).then((r) => {
        const sess = r.data.getSession
        if (!sess || !sess.Classroom) {
            cb(up)
            return
        }
        getClassroom(sess.Classroom, function (cl) {
            if (cl) {
                if (cl.TeacherID === luid) {
                    cl.TeacherID = up.id

                    // Newly logged in user needs to be added to teacher list
                    // as well for all controls to work properly after a login.
                    if (cl.TeacherList) {
                        if (cl.TeacherList.indexOf(up.id) < 0) {
                            cl.TeacherList.push(up.id);
                        }
                    } else {
                        cl.TeacherList = [up.id];
                    }

                    delete (cl['Teacher'])
                    delete (cl['Users'])
                    updateClassroom(cl)
                }
            }
        })
        // delete local user
        const localId = luid + "-CL-" + sess.Classroom
        getLocalUsers(localId).then((dat) => {
            const lu = dat.data.getLocalUsers
            if (lu && lu.CurrentUser) {
                deleteUser(lu.CurrentUser)
            }
        })
        deleteLocalUsers(localId)
        delUsers(null)
        // delete all users by this name
        function delUsers(nt) {
            const cmd = {
                limit: 100, nextToken: nt, sortDirection: "ASC",
                UserProfile: luid
            }
            gqlOperationWithRetry(autoQuery.byProfileSorted, cmd).then((re3) => {
                var dats = re3.data.byProfileSorted
                for (let i = 0; i < dats.items.length; i++) {
                    var up = dats[i]
                    if (up && up.Session && up.Session.Classroom === sess.Classroom) {
                        deleteUser(up)
                    }
                }
                //update class to the right user
                if (dats.nextToken) {
                    delUsers(dats.nextToken)
                }
            })
        }

        function getBoards(nt) {
            const cmd = {
                limit: 100, nextToken: nt, sortDirection: "ASC",
                CreatorLocalID: luid, filter: { Classroom: { eq: sess.Classroom } }
            }
            gqlOperationWithRetry(autoQuery.queryByCreatorLocalId, cmd).then((res) => {
                const dats = res.data.queryByCreatorLocalId
                var promises = []
                for (let i = 0; i < dats.items.length; i++) {
                    var dat = dats.items[i]
                    if (dat && luid !== up.id) {
                        dat.CreatorLocalID = up.id
                        dat.savedOwner = up.id;
                        promises.push(updateSession(dat))
                    }
                }

                //update class to the right user
                if (!dats.nextToken) {
                    Promise.all(promises).then(() => {
                        cb(up)
                    })
                } else {
                    Promise.all(promises).then(() => {
                        getBoards(dats.nextToken)
                    })
                }
            })
        }
        getBoards(null)
    })
}

export function findCreateUserProfile(id, email, avatar, name, url, cb, signup) {
    getUserProfie(id).then((res) => {
        var emailOptIn = mylocalStorage.getItem('emailOptIn');
        if (res && res.data.getUserProfile) {
            var u = res.data.getUserProfile;
            var needsUpdate = false;
            if (avatar && avatar !== "" && res.data.getUserProfile.avatar !== avatar) {
                u['avatar'] = avatar;
                needsUpdate = true;
            }
            if (name && name !== "" && !res.data.getUserProfile.name) {
                // If the profile did not have a name set, update
                // it from the social login (if we got any).
                u['name'] = name;
                needsUpdate = true;
            }
            if (emailOptIn !== '' && !res.data.getUserProfile.emailOptIn) {
                u['emailOptIn'] = emailOptIn
                needsUpdate = true;
            }
            if (needsUpdate) {
                updateUserProfile(u).then((re) => {
                    updateClassIfNeeded(cb, url, re.data.updateUserProfile)
                });
            } else {
                updateClassIfNeeded(cb, url, res.data.getUserProfile)
            }
        } else {
            var tmpName = mylocalStorage.getItem('myname');

            if (!(tmpName && tmpName !== "") && name) {
                // If the user has not set a name, use the one
                // that we got (if any) from a social login
                // (google or facebook).
                tmpName = name;
                mylocalStorage.setItem('myname', tmpName);
            }

            createUserProfile(id, tmpName, email, avatar).then((re) => {
                updateClassIfNeeded(cb, url, re.data.createUserProfile)
            }).catch(error => { console.log('user exist already', error) })
        }
    }).catch(e => {
        alert("Please check the TIME on your computer and make sure it is right.")
    })
}
export function createObject(id, name, sessionID, content, objType, userid, intype = null, ttl = 0, svg) {
    const cmd = { 'id': id, 'name': name, 'content': content, 'SessionID': sessionID, ended: false, objType: objType };
    (!ttl) ? cmd.ttl = getTTL() : cmd.ttl = ttl;
    if (userid) cmd['CreatedBy'] = userid
    if (intype) cmd['type'] = intype
    let sss = mylocalStorage.getItem('syncDisabled')
    if (sss) cmd['DisableSync'] = 'disabled'
    if (svg) cmd.SVGObj = svg
    return gqlOperationWithRetry(autoMutation.createObject, { input: cmd });
}

export function reCreateObject(id, name, sessionID, content, objType, userid, ended = false, animate = null, type = "", ttl = 0) {
    const cmd = { 'id': id, 'name': name, 'content': content, 'SessionID': sessionID, ended: ended, objType: objType, animate: animate, type: type };
    (!ttl) ? cmd.ttl = getTTL() : cmd.ttl = ttl;
    if (userid) cmd['CreatedBy'] = userid
    let sss = mylocalStorage.getItem('syncDisabled')
    if (sss) cmd['DisableSync'] = 'disabled'
    if (!type || type === "") cmd["type"] = JSON.stringify({ 'compressed': false })
    return gqlOperationWithRetry(autoMutation.createObject, { input: cmd });
}

export function getObject(id) {
    return gqlOperationWithRetry(autoQuery.getObject, { id: id });
}
export function updateAnimateObject(inp) {
    const cmd = { 'id': inp.id, animate: inp.animate }
    return gqlOperationWithRetry(autoMutation.updateObject, { input: cmd });
}

export function updateObject(cmd) {
    delete cmd['Session']
    return gqlOperationWithRetry(autoMutation.updateObject, { input: cmd });
}

export function listObject(sessionID, nextToken, cb) {
    const cmd = { limit: 100, nextToken: nextToken, sortDirection: "ASC", SessionID: sessionID }
    gqlOperationWithRetry(autoQuery.sessionSorted, cmd).then(res => {
        const dat = res.data.sessionSorted
        cb(dat.items)
        if (dat.nextToken) {
            listObject(sessionID, dat.nextToken, cb)
        }
    })
}

export async function listObjectSync(sessionID) {
    var allList = []
    var nt = null
    do {
        const cmd = { limit: 100, nextToken: nt, sortDirection: "ASC", SessionID: sessionID }
        var res = await gqlOperationWithRetry(autoQuery.sessionSorted, cmd)
        const dat = res.data.sessionSorted
        allList = [...allList, ...dat.items]
        nt = dat.nextToken
    } while (nt !== null)
    return allList
}


export async function getboardsbyparentSync(parentID) {
    var allList = []
    var nt = null
    do {
        const cmd = { limit: 100, nextToken: nt, sortDirection: "ASC", parentID: parentID }

        var res = await gqlOperationWithRetry(customops.queryByParent, cmd)
        const dat = res.data.queryByParent
        allList = [...allList, ...dat.items]
        nt = dat.nextToken
    } while (nt !== null)
    return allList
}

export function callObjectcb(sessionID, nextToken, cb) {
    listObject(sessionID, nextToken).then((res) => {
        const dat = res.data.listObjects
        if (dat.nextToken) {
            listObject(sessionID, dat.nextToken).then((res2) => {
                const dat2 = res.data.listObjects

                console.log("FOUND CO NT2", res2, dat2.items.length, dat2.nextToken)

            })
        }
    })
}

// context args: sessionID, cb, delCB, subCB, doList, retryCount=SUB_MAX_RETRY_COUNT
export function ObjectSubscribe(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList) listObject(context.sessionID, null, context.cb)
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToSessionObjects, { SessionID: context.sessionID }))
    var s2, s4;
    let retried = false;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToSessionObjects
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "ObjectSubscribe:subscribeToSessionObjects",
                error, s4, ObjectSubscribe, context);
            retried = true;
        }
    }, "ObjectSubscribe:subscribeToSessionObjects");
    const s3 = API.graphql(graphqlOperation(autoSub.delSessionObject, { SessionID: context.sessionID }))
    s4 = trySubscribe(s3, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.delSessionObject
            context.delCB(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "ObjectSubscribe:delSessionObject",
                error, s2, ObjectSubscribe, context);
            retried = true;
        }
    }, "ObjectSubscribe:delSessionObject");
    context.subCB([s2, s4])
}

/* user stuff */
export function findUserLoop(filter, nextToken, cb, userList, content, session) {
    var cmd = { limit: 100, nextToken: nextToken, SessionID: session }
    if (filter && Object.keys(filter).length > 0) {
        cmd['filter'] = filter
    }
    gqlOperationWithRetry(autoQuery.byUsersSessionSorted, cmd).then(res => {
        const dat = res.data.byUsersSessionSorted;
        userList = userList.concat(dat.items);
        if (dat.nextToken) {
            findUserLoop(filter, dat.nextToken, cb, userList, content);
        } else {
            // Send a complete list in one callback at the end
            cb(userList, content);
        }
    })
}

export function findUser(sessionID, content, userProfileID, cb) {
    var filter = {}

    if (userProfileID) filter['UserProfile'] = { 'eq': userProfileID };
    if (content['email'] || content['localID']) filter['or'] = [];
    if (content['email']) filter['or'].push({ 'content': { 'contains': `"email":"${content['email']}"` } })
    if (content['localID']) filter['or'].push({ 'content': { 'contains': `"localID":"${content['localID']}"` } })

    return findUserLoop(filter, null, cb, [], content, sessionID);
}

export function createUser(name, sessionID, content, userProfileID, userAvatar, ttl = 0) {
    const cmd = { 'name': name, 'content': content, 'SessionID': sessionID };
    (!ttl) ? cmd.ttl = getTTL() : cmd.ttl = ttl;
    if (userProfileID) cmd['UserProfile'] = userProfileID
    if (userAvatar) cmd['avatar'] = userAvatar;
    return gqlOperationWithRetry(autoMutation.createUser, { input: cmd });
}

export function deleteUser(cmd) {
    return gqlOperationWithRetry(autoMutation.deleteUser, { input: { id: cmd.id } });
}

export function updateUser(cmd) {
    var cmdCopy = JSON.parse(JSON.stringify(cmd));
    delete cmdCopy['Session']
    delete cmdCopy['updatedAt']
    delete cmdCopy['createdAt']
    return gqlOperationWithRetry(autoMutation.updateUser, { input: cmdCopy });
}

export function listUser(sessionID, nextToken, cb) {
    const cmd = { limit: 100, nextToken: nextToken, SessionID: sessionID }
    gqlOperationWithRetry(autoQuery.byUsersSessionSorted, cmd).then(res => {
        const dat = res.data.byUsersSessionSorted
        cb(dat.items)
        if (dat.nextToken) {
            listUser(sessionID, dat.nextToken, cb)
        }
    })
}

// noList was inverted and changed to doList
// context args: sessionID, cb, doList, subCB, retryCount=SUB_MAX_RETRY_COUNT
export function UserSubscribe(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList) listUser(context.sessionID, null, context.cb)
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToSessionUsers, { SessionID: context.sessionID }))
    var s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToSessionUsers
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(false, "UserSubscribe",
                error, null, UserSubscribe, context);
        }
    }, "UserSubscribe");
    context.subCB(s2);
}

// context args: sessionID, cb, delcb, subCB, retryCount=SUB_MAX_RETRY_COUNT
export function SessionSubscribe(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToSession, { id: context.sessionID }))
    var s2, s4;
    let retried = false;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToSession
            context.cb(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SessionSubscribe:subscribeToSession",
                error, null, SessionSubscribe, context);
            retried = true;
        }
    }, "SessionSubscribe:subscribeToSession");
    const s3 = API.graphql(graphqlOperation(autoSub.subscribeToDelSession, { id: context.sessionID }))
    s4 = trySubscribe(s3, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToDelSession
            context.delcb(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SessionSubscribe:subscribeToDelSession",
                error, null, SessionSubscribe, context);
            retried = true;
        }
    }, "SessionSubscribe:subscribeToDelSession");
    context.subCB([s2, s4]);
}

function getDate() {
    var today = new Date();
    today.setHours(today.getHours() - 6);
    return today
}
export function listSession(cb, nextToken) {

    var cmd = { limit: 100, nextToken: nextToken }
    if (OBS) {
        var f = getDate()
        cmd['filter'] = { createdAt: { gt: f } }
    }
    gqlOperationWithRetry(autoQuery.listSessions, cmd).then(res => {
        const dat = res.data.listSessions
        cb(dat.items)
        if (dat.nextToken) {
            listSession(cb, dat.nextToken)
        }
    })
}

// context args: cb, doList, subCB, retryCount=SUB_MAX_RETRY_COUNT
export function SessionSubscribeAll(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList) listSession(context.cb, null);
    const s1 = API.graphql(graphqlOperation(autoSub.onCreateSession, null))
    var s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.onCreateSession
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(false, "SessionSubscribeAll",
                error, null, SessionSubscribeAll, context);
        }
    }, "SessionSubscribeAll");
    context.subCB(s2);
}

export function createSessionSave(id, name, user, parentId, pageNumber, parentBoardID, boardConfig, classr, jc) {
    var luid = mylocalStorage.getItem('mystoreID');
    if (!luid) {
        luid = uuid()
        mylocalStorage.setItem('mystoreID', luid)
    }
    const cmd = {
        id: id, 'name': name, ttl: getTTL2Week(),
        'parentID': parentId, pageNumber: pageNumber, 'savedOwner': user
    }
    if (!user) {
        cmd['savedOwner'] = luid
    }
    if (jc) {
        cmd['joinCode'] = jc
    } else {
        cmd['joinCode'] = genJoinCode()
    }
    if (classr) {
        const pgSplit = id.split("-pgNum-")
        if (pgSplit.length > 1 && pgSplit[1] === "1") {
            getClassroom(classr, function (cl) {
                if (cl === null || cl === undefined) {
                    createClassroom({ id: parentId, TeacherID: luid, name: "myclass" })
                } else {
                    try {
                        delete cl['Teacher']
                        delete cl['Users']
                        delete cl['createdAt']
                        delete cl['updatedAt']
                        cl.TeacherID = luid
                        cl.id = parentId
                        cl.ttl = cmd.ttl
                        createClassroom(cl)
                    } catch (error) {
                        console.log("Class already exists", error)
                    }
                }
            })
        }
        cmd['Classroom'] = parentId
    }
    if (boardConfig) cmd['boardConfig'] = boardConfig
    if (parentBoardID) {
        cmd['parentBoardID'] = parentBoardID
    } else {
        cmd['parentBoardID'] = id//parentId for all otherwise its page to page 
    }
    cmd['CreatorLocalID'] = luid
    return gqlOperationWithRetry(autoMutation.createSession, { input: cmd });
}

export function createMultiBoard(parentId, childId, ttl = 0) {
    const cmd = { id: parentId + "-" + childId, parentBoardID: parentId, childBoardID: childId };
    (!ttl) ? cmd.ttl = getTTL() : cmd.ttl = ttl;
    return gqlOperationWithRetry(autoMutation.createMultiBoard, { input: cmd });
}

export function listMultiBoardbyParent(parentID, cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, parentBoardID: parentID }
    gqlOperationWithRetry(autoQuery.queryByParentId, cmd).then(res => {
        const dat = res.data.queryByParentID
        if (dat) cb(dat.items)
        if (dat.nextToken) {
            listMultiBoardbyParent(parentID, cb, dat.nextToken)
        }
    })
}

// context args: parentID, cb, delcb, doList, subCB, retryCount=SUB_MAX_RETRY_COUNT
export function SessionMultiBoardByParent(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    let retried = false;
    if (context.doList) listMultiBoardbyParent(context.parentID, context.cb, null);
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToMbByParent, { parentBoardID: context.parentID }))
    var s2, s4;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToMBByParent
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SessionMultiBoardByParent:subscribeToMBByParent",
                error, s4, SessionMultiBoardByParent, context);
            retried = true;
        }
    }, "SessionMultiBoardByParent:subscribeToMBByParent");
    const s3 = API.graphql(graphqlOperation(autoSub.subscribeToMbByParentDel, { parentBoardID: context.parentID }))
    s4 = trySubscribe(s3, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToMBByParentDel
            context.delcb(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SessionMultiBoardByParent:subscribeToMBByParentDel",
                error, s2, SessionMultiBoardByParent, context);
            retried = true;
        }
    }, "SessionMultiBoardByParent:subscribeToMBByParentDel");
    context.subCB([s2, s4]);
}

export function updateClassGroupTTL(classroomID, ttl, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, sortDirection: "ASC", Classroom: classroomID }
    gqlOperationWithRetry(autoQuery.queryByGroupsClassroom, cmd).then(res => {
        const dat = res.data.queryByGroupsClassroom
        dat.items.forEach((item) => {
            // update class group TTL
            let inputDict = { id: item.id, ttl: ttl };
            gqlOperationWithRetry(autoMutation.updateClassGroup, { input: inputDict })
        })
        if (dat.nextToken) {
            updateClassGroupTTL(classroomID, ttl, dat.nextToken)
        }
    })
}

export function updateClassroomEventsTTL(classroomID, ttl, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, sortDirection: "ASC", Classroom: classroomID }
    gqlOperationWithRetry(autoQuery.queryByEventsClassroom, cmd).then(res => {
        const dat = res.data.queryByEventsClassroom
        dat.items.forEach((item) => {
            // update classroom events TTL
            let inputDict = { id: item.id, ttl: ttl };
            gqlOperationWithRetry(autoMutation.updateClassroomEvent, { input: inputDict })
        })
        if (dat.nextToken) {
            updateClassroomEventsTTL(classroomID, ttl, dat.nextToken)
        }
    })
}

export function updateLocalUsersTTL(classroomID, ttl, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, sortDirection: "ASC", ClassroomID: classroomID }
    gqlOperationWithRetry(autoQuery.queryLocalUserByClass, cmd).then(res => {
        const dat = res.data.queryLocalUserByClass
        dat.items.forEach((item) => {
            // update localusers TTL
            let inputDict = { id: item.id, ttl: ttl };
            gqlOperationWithRetry(autoMutation.updateLocalUsers, { input: inputDict })
        })
        if (dat.nextToken) {
            updateLocalUsersTTL(classroomID, ttl, dat.nextToken)
        }
    })
}

export function updateMultiBoardTTL(parentBoardID, ttl, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, parentBoardID: parentBoardID }
    gqlOperationWithRetry(autoQuery.queryByParentId, cmd).then(res => {
        const dat = res.data.queryByParentID
        dat.items.forEach((item) => {
            // update MultiBoard TTL
            let inputDict = { id: item.id, ttl: ttl };
            gqlOperationWithRetry(autoMutation.updateMultiBoard, { input: inputDict })
        })
        if (dat.nextToken) {
            updateMultiBoardTTL(parentBoardID, ttl, dat.nextToken)
        }
    })
}

export function updateUserTTL(sessionID, ttl, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, sortDirection: "ASC", SessionID: sessionID }
    gqlOperationWithRetry(autoQuery.byUsersSessionSorted, cmd).then(res => {
        const dat = res.data.byUsersSessionSorted
        dat.items.forEach((item) => {
            // update user TTL
            let inputDict = { id: item.id, ttl: ttl };
            gqlOperationWithRetry(autoMutation.updateUser, { input: inputDict })
        })
        if (dat.nextToken) {
            updateUserTTL(sessionID, ttl, dat.nextToken)
        }
    })
}

function getUrlFromMap(urlMap, fullUrl) {
    const tUrl = fullUrl.split("?X-Amz")[0];
    return urlMap[tUrl];
}

function setUrlInMap(urlMap, fullUrl, newUrl) {
    const tUrl = fullUrl.split("?X-Amz")[0];
    urlMap[tUrl] = newUrl;
}

function getMultiPageId(urlMap, oldUuid) {
    var multiPageMap = getUrlFromMap(urlMap, 'multiPage');
    if (!multiPageMap) {
        multiPageMap = {};
        setUrlInMap(urlMap, 'multiPage', multiPageMap);
    }
    return multiPageMap[oldUuid];
}

function setMultiPageId(urlMap, oldUuid, newUuid) {
    var multiPageMap = getUrlFromMap(urlMap, 'multiPage');
    multiPageMap[oldUuid] = newUuid;
}

async function checkCopyUrl(urlMap, fullUrl, multiPage = false, inKey = null, xtn = null, noExpire = false) {
    let checkUrl = getUrlFromMap(urlMap, fullUrl);
    if (!checkUrl) {
        // console.log("Did not find existing copy, making a new one", fullUrl);
        var o, oo, xx;
        if (!multiPage && (fullUrl.indexOf('-pg-') > 0)) {
            multiPage = true;
        }
        if (multiPage) {
            o = fullUrl.split('/');
            oo = o[o.length - 1].split('-pg-');
            xx = oo[1].split('.');
            xtn = xx[1];
            var g = getMultiPageId(urlMap, oo[0]);
            if (g) {
                inKey = g + '-pg-' + xx[0];
                // console.log("Found old uuid:", oo, inKey);
            } else {
                g = uuid();
                inKey = g + '-pg-' + xx[0];
                // console.log("Setting old uuid:", oo, g, inKey);
                // Have to set the ID before the call to copyFile, since
                // the 'await' keyword switches context to another item's processing
                setMultiPageId(urlMap, oo[0], g);
            }
        }
        checkUrl = await copyFile(fullUrl, inKey, xtn, noExpire);
        setUrlInMap(urlMap, fullUrl, checkUrl);
    } else {
        // console.log("Found existing copy:", fullUrl, checkUrl);
    }

    return checkUrl;
}

async function handleImage(item, urlMap, noExpire = false) {
    var tContent = item.content;
    var content = null;
    tContent = JSON.parse(tContent);
    if (tContent.type === 'image') {
        if (tContent.url) {
            if (tContent.url.match(s3HostPattern)) {
                // console.log("updateObjTTL: gotItem: image", item, tContent);
                tContent.url = await checkCopyUrl(urlMap, tContent.url, false, null, null, noExpire);
                content = JSON.stringify(tContent);
            } else {
                // console.log("Ignoring non-s3 image url:", tContent[1].source);
            }
        } else {
            // console.log("updateObjTTL: gotItem: NO URL: image:", item, tContent);
        }
    } else if (tContent.type === "bingo") {
        if (tContent.url) {
            if (tContent.url.match(s3HostPattern)) {
                // console.log("updateObjTTL: gotItem: bingo", item, tContent);
                let entries = Object.keys(tContent.pageArray);
                const tUrl = tContent.url.split("?X-Amz")[0];
                let uds = tUrl.split('.');
                let xtn = uds[uds.length - 1];
                let uss = tUrl.split('/');
                let oldUuid = uss[uss.length - 1].split('-pg-')[0];
                let prefix = uss.slice(0, uss.length - 1).join('/');
                let found = false;
                for (let ii = 0; ii < entries.length; ii++) {
                    let fullUrl = prefix + '/' + oldUuid + '-pg-' + entries[ii] + '.' + xtn;
                    let newUrl = await checkCopyUrl(urlMap, fullUrl, true, null, xtn, noExpire);
                    if (!found && (fullUrl === tContent.url)) {
                        tContent.url = newUrl;
                        found = true;
                    }
                }
                content = JSON.stringify(tContent);
            } else {
                // console.log("Ignoring non-s3 bingo url:", tContent[1].source);
            }
        } else {
            // console.log("updateObjTTL: gotItem: NO URL: bingo:", item, tContent);
        }
    }

    return content;
}

async function handleDrawPaperRaster(item, urlMap, tContent2, t, noExpire = false) {
    var content = null;
    if (tContent2[1].source) {
        if (tContent2[1].source.match(s3HostPattern)) {
            // console.log("updateObjTTL: gotItem: drawPaper", item, tContent2);
            var b = (tContent2[1].data && tContent2[1].data.bingo) ? true : false;
            tContent2[1].source = await checkCopyUrl(urlMap, tContent2[1].source, b, null, null, noExpire);
            content = JSON.stringify(tContent2);
            if (t && t.compressed) {
                content = pako.deflate(content, { to: 'string' });
            }
        } else {
            // console.log("Ignoring non-s3 drawPaper source url:", tContent2[1].source);
        }
    } else {
        // console.log("updateObjTTL: gotItem: NO SOURCE: drawPaper:", item, tContent2);
    }

    return content;
}

function stringReplaceAll(origStr, whatToReplace, whatToReplaceWith) {
    var b = new RegExp(whatToReplace, 'g');
    return origStr.replace(b, whatToReplaceWith);
}

async function handleDrawPaperGroup(urlMap, tContent, t, noExpire = false, refresh) {
    // console.log("Found Group", tContent.match(s3UrlPattern));
    var files = tContent.match(s3UrlPattern);
    var files2 = [...new Set(files)];
    var copied = false;
    var content;

    // console.log("found files in Group", files, files2);
    for (let idx in files2) {
        let process = false
        if (!refresh) process = true
        else if (noExpire && (files2[idx] && !files2[idx].includes('/paidImages'))) {
            process = true;
        } else if (!noExpire && (files2[idx] && files2[idx].includes('/paidImages'))) {
            process = true;
        }
        if (!process) continue
        let newUrl = await checkCopyUrl(urlMap, files2[idx], false, null, null, noExpire);
        // console.log("copied url:", newUrl, files2[idx]);
        tContent = stringReplaceAll(tContent, files2[idx], newUrl);
        copied = true;
    }
    if (copied) {
        if (t && t.compressed) {
            content = pako.deflate(tContent, { to: 'string' });
        } else {
            content = tContent;
        }
    }

    return content;
}

export async function updateObjsContentInternal(item, ttl, urlMap, refresh = false, noExpire = false) {
    var content = null;
    if (item.objType === 'drawPaper') {
        var tContent = item.content;
        var t = null;
        if (item.type) {
            t = JSON.parse(item.type);
            if (t.compressed) {
                tContent = pako.inflate(tContent, { to: 'string' });
            }
        }
        var tContent2 = JSON.parse(tContent);
        // console.log("drawPaper:", tContent2, tContent, s3UrlPattern);
        if (tContent2[0] === "Raster") {
            // console.log("Found Raster");
            if (!refresh) {
                content = await handleDrawPaperRaster(item, urlMap, tContent2, t, noExpire);
            } else if (noExpire && !tContent2[1].source.includes('/paidImages')) {
                content = await handleDrawPaperRaster(item, urlMap, tContent2, t, noExpire);
            } else if (!noExpire && tContent2[1].source.includes('/paidImages')) {
                content = await handleDrawPaperRaster(item, urlMap, tContent2, t, noExpire);
            }
        } else if (tContent2[0] === "Group" || tContent2[0] === "Path") {
            content = await handleDrawPaperGroup(urlMap, tContent, t, noExpire, refresh);
            // var mediaUrl = tContent2[1].data.linkData && tContent2[1].data.linkData.file
        }

    } else if (item.objType === 'image') {
        var tContent3 = JSON.parse(item.content);
        if (!refresh) {
            content = await handleImage(item, urlMap, noExpire);
        } else if (noExpire && !tContent3.url.includes('/paidImages')) {
            content = await handleImage(item, urlMap, noExpire);
        } else if (!noExpire && tContent3.url.includes('/paidImages')) {
            content = await handleImage(item, urlMap, noExpire);
        }
    }
    return content
}

export async function updateObjsContent(item, ttl, urlMap, refresh = false, noExpire = false) {
    let content = await updateObjsContentInternal(item, ttl, urlMap, refresh, noExpire)
    // update Object TTL
    let inputDict = { id: item.id, ttl: ttl };
    if (content) {
        // console.log("updateObj: updating content:", content);
        inputDict['content'] = content;
    }
    gqlOperationWithRetry(autoMutation.updateObject, { input: inputDict })
}

export async function updateObjTTL(sessionID, ttl, nextToken, urlMap, noExpire = false) {
    const cmd = { limit: 100, nextToken: nextToken, sortDirection: "ASC", SessionID: sessionID }
    var res = await gqlOperationWithRetry(autoQuery.sessionSorted, cmd);
    const dat = res.data.sessionSorted
    for (let idx3 in dat.items) {
        let item = dat.items[idx3];
        updateObjsContent(item, ttl, urlMap, true, noExpire)
    }
    if (dat.nextToken) {
        updateObjTTL(sessionID, ttl, dat.nextToken, urlMap, noExpire);
    }
}

export function updateGamePlayTTL(sessionID, ttl, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, sortDirection: "ASC", Session: sessionID }
    gqlOperationWithRetry(autoQuery.queryGamePlayBySession, cmd).then(res => {
        const dat = res.data.queryGamePlayBySession
        dat.items.forEach((item) => {
            // update GamePlay TTL
            let inputDict = { id: item.id, _version: item._version, ttl: ttl };
            gqlOperationWithRetry(autoMutation.updateGamePlay, { input: inputDict })
        })
        if (dat.nextToken) {
            updateGamePlayTTL(sessionID, ttl, dat.nextToken)
        }
    })
}

function adjustContentForMove(obj) {
    if (obj.objType !== "drawPaper") {
        return obj
    }
    try {
        var type = JSON.parse(obj.type)
    } catch { }
    if (type && type.compressed) {
        var content = pako.inflate(obj.content, { to: 'string' });
        var data = JSON.parse(content)
        if (Array.isArray(data) && data[0] === "Group") {
            const paperInfo = data[1]
            if (paperInfo) {
                if (paperInfo.data && paperInfo.data.spinner === true) {
                    paperInfo.data.spinnerId = uuid() // Generate a new spinnerId for the new copy
                    obj.content = JSON.stringify(data)
                    return prepObj(obj, true)
                }
            }
        }
    }
    return obj
}

export function moveObj(sessionID, newSessionID, nextToken, ttl) {
    if (!ttl) ttl = getTTL();
    const cmd = { limit: 100, nextToken: nextToken, sortDirection: "ASC", SessionID: sessionID }
    gqlOperationWithRetry(autoQuery.sessionSorted, cmd).then(res => {
        const dat = res.data.sessionSorted

        for (let i = 0; i < dat.items.length; i++) {

            let item = dat.items[i]
            // create new obj in new session
            item = adjustContentForMove(item)
            item.SessionID = newSessionID
            item.ttl = ttl

            // let content = await updateObjsContentInternal(item, ttl, urlMap) too slow to load 
            delete item['id']
            delete item['Session']
            // if (content) item.content = content;

            gqlOperationWithRetry(autoMutation.createObject, { input: item })
        }
        if (dat.nextToken) {
            moveObj(sessionID, newSessionID, dat.nextToken, ttl)
        }
    })
}


export function createObjPlain(item) {
    return gqlOperationWithRetry(autoMutation.createObject, { input: item })
}
export function createObj(sessionID, item, cb, sessTtl) {
    // const dat = obj //jsonobjt
    // create new obj with new sessionID
    item.SessionID = sessionID
    item.ttl = sessTtl
    item.name = 'import'
    delete item['id']
    delete item['Session']
    delete item['createdAt']
    delete item['updatedAt']
    delete item['imgB64']
    // console.log('obj item',item)
    gqlOperationWithRetry(autoMutation.createObject, { input: item }).then(res => {
        // console.log('success res', res)
        cb(res)
    })
}


export function getboardsbyparent(parentID, nextToken, cb) {
    const cmd = { limit: 100, nextToken: nextToken, sortDirection: "ASC", parentID: parentID }

    gqlOperationWithRetry(autoQuery.queryByParent, cmd).then(res => {
        const dat = res.data.queryByParent
        cb(dat.items)
        if (dat.nextToken) {
            getboardsbyparent(parentID, dat.nextToken, cb)
        } else {
            cb(null)
        }
    })
}
export async function refreshBoard(id, urlMap, noExpire = false) {
    var ttl = 0
    ttl = getTTL2Week();
    const pgSplit = id.split("-pgNum-")
    // getboardsbyparent(pgSplit[0], null, gotPages)
    let pages = await getboardsbyparentSync(pgSplit[0]);
    await gotPages(pages);

    // Since we have just the ID, just make a call to
    // getClassroom. If there is no such classroom, we
    // will get a null in the callback function
    let classroom = await getClassroomSync(pgSplit[0]);

    if (classroom) {
        updateMultiBoardTTL(classroom.id + "-pgNum-1", ttl, null);
        updateLocalUsersTTL(classroom.id, ttl, null);
        updateClassroomEventsTTL(classroom.id, ttl, null);
        updateClassGroupTTL(classroom.id, ttl, null);
        await updateClassroom({ id: classroom.id, ttl: ttl });
    }

    async function gotPages(items) {
        // console.log("gotPages:", items);
        if (!items) return
        for (let ii = 0; ii < items.length; ii++) {
            let item = items[ii];
            // console.log("Updating session:", item.id);
            await updateObjTTL(item.id, ttl, null, urlMap, noExpire);
            updateUserTTL(item.id, ttl, null)
            updateGamePlayTTL(item.id, ttl, null)
            let updateDict = { id: item.id, ttl: ttl };

            // If the session has an empty string folderID
            // then the updateSession will fail. We have to
            // remove the folderID column in that case. To do this,
            // we update with the field set to null to remove it from
            // the session object in DynamoDB.
            if (item.folderID === "") {
                updateDict['folderID'] = null;
            }

            await updateSession(updateDict);
        }
    }

    return ttl;
}

export function saveBoard(name, user, id, cb) {
    const pgSplit = id.split("-pgNum-")
    const ids = uuid()

    //find all the pages
    getboardsbyparent(pgSplit[0], null, gotPages)

    function gotPages(items) {
        if (!items) return
        items.forEach((item) => {
            const ipgSplit = item.id.split("-pgNum-")
            const pg1 = ids + "-pgNum-" + ipgSplit[1]
            var pbid = pg1

            if (item.id !== item.parentBoardID) {
                //complext? copy the object from the parents too
                //pbid = item.parentBoardID
            }
            createSessionSave(pg1, name, user, ids, ipgSplit[1], pbid, item.boardConfig,
                item.Classroom).then((res) => {
                    const back = res.data.createSession
                    moveObj(item.id, back.id, null, back.ttl)
                    if (item.id !== item.parentBoardID) {
                        moveObj(item.parentBoardID, back.id, null, back.ttl)

                    }
                    if (ipgSplit[1] === "1" && cb) {
                        updateEvents(back, item)
                        cb(pg1)
                    }
                    //move all old objects
                })
        })
    }
    function updateEvents(sess, item) {
        function gotEvent(events) {
            for (let i = 0; i < events.length; i++) {
                var e = events[i]
                if (e.For === e.Classroom) {
                    //copy only classroom events 
                    e.For = sess.Classroom
                    e.Classroom = sess.Classroom
                    var rep = e.event.replace(item.Classroom, sess.Classroom)
                    rep = rep.replace(item.Classroom, sess.Classroom)
                    e.event = rep
                    e.id = rep
                    e.ttl = sess.ttl
                    createClassroomEvent(e)
                }
            }
        }
        function gotClass(classes) {
            for (let i = 0; i < classes.length; i++) {
                var e = classes[i]
                var cc = JSON.parse(e.content)
                if (e.content.includes("precog")) {
                    createGroupForClass(e.name, cc.members, sess, null)
                }
            }
        }
        if (item.Classroom) {
            listClassroomEventByClass(item.Classroom, gotEvent, null)
            listClassGroupByClass(item.Classroom, gotClass, null)
        }
    }
}


export function importBoard(user, sessions, objects, cb) {
    const ids = uuid()
    //get all sessions
    //todo check with sid and add logic to fetch and check whether already session is there or not?
    if (!sessions) return
    sessions.forEach((item) => {
        const ipgSplit = item.id.split("-pgNum-")
        const pg1 = ids + "-pgNum-" + ipgSplit[1]
        var pbid = pg1

        createSessionSave(pg1, item.name, user, ids, ipgSplit[1], pbid, item.boardConfig,
            item.Classroom).then((res) => {
                const back = res.data.createSession
                //filter out the objects which has pgSplit[0] - old sessionId
                // console.log('response', back)
                if (!objects || objects.length === 0) {
                    cb(back)
                    return
                }
                var filteredObjs = objects.filter(o => o.SessionID === item.id)
                // todo: need  to tweak in the code for the case co-teacher as co-teachers board will have sessionId and objects relate but in case of coteacher those object will be having the teacher's board id and the objectes are not imported for co-teacher
                if (!filteredObjs || filteredObjs.length === 0) {
                    cb(back)
                    return
                }
                // console.log('filterobj', filteredObjs)
                var objCount = 0
                for (var ob of filteredObjs) {
                    createObj(back.id, ob, cdDoneObj, back.ttl)
                }

                function cdDoneObj(item) {
                    objCount++
                    if (objCount === filteredObjs.length) {
                        cb(back)
                    }
                }
            })
    })
}

export function makePageCopy(id, sess, cb) {
    const pgSplit = id.split("-pgNum-")

    getboardsbyparent(pgSplit[0], null, gotPages)
    function gotPages(items) {
        if (!items) return
        var allItems = []
        var lastpage = 0
        for (let i = 0; i < items.length; i++) {
            const item = items[i]
            const ipgSplit = item.id.split("-pgNum-")
            const pggg = parseInt(ipgSplit[1])
            allItems[pggg] = item
            if (lastpage < pggg) lastpage = pggg
        }
        var last = lastpage + 1

        for (let i = 1; i <= lastpage; i++) {
            if (!allItems[i]) {
                last = i
                break
            }
        }

        if (last === 0) last = items.length
        var np = last
        const pg1 = pgSplit[0] + "-pgNum-" + np
        var pbid = pg1
        createSessionSave(pg1, sess.name, sess.savedOwner, pgSplit[0],
            np, pbid, sess.boardConfig,
            sess.Classroom, sess.joinCode).then((res) => {
                const back = res.data.createSession
                moveObj(sess.id, back.id, null, back.ttl)
                if (sess.id !== sess.parentBoardID) {
                    moveObj(sess.parentBoardID, back.id, null, back.ttl)
                }
                cb(np, true)
                //move all old objects
            })
    }
}

// If null is passed for path, the result will be
// that the folderID field will be removed from
// the session object. Since there is a secondary index
// on the folderID field, we cannot set it to an empty
// string if we have to move anything to the root folder.
// We need to delete the field from the object in that case.
// To not update the path, pass the path as "undefined"
export function setName(row, path, passCurUpdatedAt) {
    const newName = row.name
    var pp = row.id.split("-pgNum-")
    getboardsbyparent(pp[0], null, renamePages)
    function renamePages(items) {
        if (!items) return
        items.forEach((item) => {
            let tmpItem = {
                id: item.id,
                name: newName
            }
            if (path !== undefined) tmpItem.folderID = path;
            if (passCurUpdatedAt) tmpItem.updatedAt = item.updatedAt;
            updateSession(tmpItem)
        })
    }
}

export function listSessionByOwner(ownerid, cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, savedOwner: ownerid, sortDirection: "ASC" }
    cmd['filter'] = { pageNumber: { eq: 1 } }

    gqlOperationWithRetry(customops.queryBySavedOwner, cmd).then(res => {
        const dat = res.data.queryBySavedOwner
        if (dat) cb(dat.items)
        if (dat.nextToken) {
            listSessionByOwner(ownerid, cb, dat.nextToken)
        }
    })
}

// context args: ownerid, cb, delcb, doList, subCB, retryCount=SUB_MAX_RETRY_COUNT
export function SessionSubscribeByOwner(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList) listSessionByOwner(context.ownerid, context.cb, null);
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToSessionbyOwner, { savedOwner: context.ownerid }))
    var s2, s4;
    let retried = false;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToSessionbyOwner
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SessionSubscribeByOwner:subscribeToSessionbyOwner",
                error, s4, SessionSubscribeByOwner, context);
            retried = true;
        }
    }, "SessionSubscribeByOwner:subscribeToSessionbyOwner");
    const s3 = API.graphql(graphqlOperation(autoSub.subscribeToSessionDelbyOwner, { savedOwner: context.ownerid }))
    s4 = trySubscribe(s3, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToSessionDelbyOwner
            context.delcb(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SessionSubscribeByOwner:subscribeToSessionDelbyOwner",
                error, s2, SessionSubscribeByOwner, context);
            retried = true;
        }
    }, "SessionSubscribeByOwner:subscribeToSessionDelbyOwner");
    context.subCB([s2, s4]);
}

export function listSessionByparentBoardID(parentBoardID, cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, parentBoardID: parentBoardID }
    gqlOperationWithRetry(autoQuery.queryByparentBoardId, cmd).then(res => {
        const dat = res.data.queryByparentBoardID
        if (dat) cb(dat.items)
        if (dat.nextToken) {
            listSessionByparentBoardID(parentBoardID, cb, dat.nextToken)
        }
    })
}

// context args: parentBoardID, cb, delcb, doList, subCB, retryCount=SUB_MAX_RETRY_COUNT
export function SessionSubscribeByparentBoardID(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList) listSessionByparentBoardID(context.parentBoardID, context.cb, null);
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToSessionbyparentBoardId, { parentBoardID: context.parentBoardID }))
    var s2, s4;
    let retried = false;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToSessionbyparentBoardID
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SessionSubscribeByparentBoardID:subscribeToSessionbyparentBoardID",
                error, s4, SessionSubscribeByparentBoardID, context);
            retried = true;
        }
    }, "parentBoardID:subscribeToSessionbyparentBoardID");
    const s3 = API.graphql(graphqlOperation(autoSub.subscribeToSessionDelbyparentBoardId, { parentBoardID: context.parentBoardID }))
    s4 = trySubscribe(s3, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToSessionDelbyparentBoardID
            context.delcb(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SessionSubscribeByparentBoardID:subscribeToSessionDelbyparentBoardID",
                error, s2, SessionSubscribeByparentBoardID, context);
            retried = true;
        }
    }, "SessionSubscribeByparentBoardID:subscribeToSessionDelbyparentBoardID");
    context.subCB([s2, s4]);
}

export function createClassroom(cmd) {
    if (!cmd.ttl) cmd.ttl = getTTL();
    cmd['TeacherList'] = [cmd['TeacherID']]
    return gqlOperationWithRetry(autoMutation.createClassroom, { input: cmd });
}

export function updateClassroom(cmd) {
    return gqlOperationWithRetry(autoMutation.updateClassroom, { input: cmd });
}

export function getClassroom(id, cb) {
    gqlOperationWithRetry(autoQuery.getClassroom, { id: id }).then(res => {
        const dat = res.data.getClassroom
        cb(dat)
    })
}

export async function getClassroomSync(id) {
    let res = await gqlOperationWithRetry(autoQuery.getClassroom, { id: id });
    return res.data.getClassroom;
}

// context args: id, cb, doList, subCB, retryCount=SUB_MAX_RETRY_COUNT
export function ClassroomSubscribe(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList) getClassroom(context.id, context.cb);
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToClassroomById, { id: context.id }))
    var s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToClassroomById
            context.cb(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(false, "ClassroomSubscribe",
                error, null, ClassroomSubscribe, context);
        }
    }, "ClassroomSubscribe");
    context.subCB(s2);
}

export function deleteLocalUsers(id) {
    return gqlOperationWithRetry(autoMutation.deleteLocalUsers, { input: { id: id } });
}
export function getLocalUsers(id) {
    return gqlOperationWithRetry(autoQuery.getLocalUsers, { id: id });
}

export function createLocalUsers(cmd) {
    if (!cmd.ttl) cmd.ttl = getTTL();
    return gqlOperationWithRetry(autoMutation.createLocalUsers, { input: cmd });
}

export function updateLocalUsers(cmd) {
    return gqlOperationWithRetry(autoMutation.updateLocalUsers, { input: cmd });
}

export function listLocalUsersByClass(ClassroomID, cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, ClassroomID: ClassroomID }
    gqlOperationWithRetry(autoQuery.queryLocalUserByClass, cmd).then(res => {
        const dat = res.data.queryLocalUserByClass
        if (dat) cb(dat.items)
        if (dat.nextToken) {
            listLocalUsersByClass(ClassroomID, cb, dat.nextToken)
        }
    })
}

// context args: ClassroomID, cb, delCB, doList, subCB, retryCount=SUB_MAX_RETRY_COUNT
export function SubscribeLocalUsersByClass(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList) listLocalUsersByClass(context.ClassroomID, context.cb, null)
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToLocalUsersByClass, { ClassroomID: context.ClassroomID }))
    var s2, s4;
    let retried = false;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToLocalUsersByClass
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SubscribeLocalUsersByClass:subscribeToLocalUsersByClass",
                error, s4, SubscribeLocalUsersByClass, context);
            retried = true;
        }
    }, "SubscribeLocalUsersByClass:subscribeToLocalUsersByClass");
    const s3 = API.graphql(graphqlOperation(autoSub.subscribeToDelLocalUsersByClass, { ClassroomID: context.ClassroomID }))
    s4 = trySubscribe(s3, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToDelLocalUsersByClass
            context.delCB(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SubscribeLocalUsersByClass:subscribeToDelLocalUsersByClass",
                error, s2, SubscribeLocalUsersByClass, context);
            retried = true;
        }
    }, "SubscribeLocalUsersByClass:subscribeToDelLocalUsersByClass");
    context.subCB([s2, s4]);
}

export async function getSessionByClassroomSync(Classroom) {
    var allList = []
    var nt = null
    do {
        const cmd = { limit: 100, nextToken: nt, sortDirection: "ASC", Classroom: Classroom }

        var res = await gqlOperationWithRetry(customops.queryByClassroom, cmd)
        const dat = res.data.queryByClassroom
        allList = [...allList, ...dat.items]
        nt = dat.nextToken
    } while (nt !== null)
    return allList
}

export function listSessionByClassroom(Classroom, cb, nextToken, custom, filter) {
    let q = custom ? customops.queryByClassroom : autoQuery.queryByClassroom
    var cmd = { limit: 100, nextToken: nextToken, Classroom: Classroom, sortDirection: "ASC" }
    if (filter) {
        cmd['filter'] = filter
    }
    gqlOperationWithRetry(q, cmd).then(res => {
        const dat = res.data.queryByClassroom
        if (dat) cb(dat.items)
        if (dat.nextToken) {
            listSessionByClassroom(Classroom, cb, dat.nextToken, custom, filter)
        }
    })
}

// context args: Classroom, cb, doList, subCB, retryCount=SUB_MAX_RETRY_COUNT
export function SessionSubscribeByClassroom(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList) listSessionByClassroom(context.Classroom, context.cb, null, false, null);
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToSessionbyClassroom, { Classroom: context.Classroom }))
    var s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToSessionbyClassroom
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(false, "SessionSubscribeByClassroom",
                error, null, SessionSubscribeByClassroom, context);
        }
    }, "SessionSubscribeByClassroom");
    context.subCB(s2);
}

export async function addPage(sess, req) {
    var boardOrder = null
    let max = 0
    var plusOne = 0
    if (sess.boardConfig) {
        var bc = JSON.parse(sess.boardConfig)
        if (bc.boardOrder) {
            boardOrder = bc.boardOrder.order
            for (let i in boardOrder) {
                let m = boardOrder[i]
                if (m > max) {
                    max = m;
                }
            }
            plusOne = max + 1
            boardOrder[req] = plusOne
        }
    }
    if (boardOrder) {
        bc.boardOrder.order = boardOrder
        bc.boardOrder.max = plusOne
        await updateClassBoardsOrderAll(sess, bc.boardOrder)
        await duplicatePage(sess.id, plusOne, false)
        return plusOne
    }
    return req
}

export function clearAllBoards(sess, mode) {
    function updateBoards(items) {
        if (!items) return
        items.forEach((item) => {
            var teacherBoard = false
            if (item.parentBoardID === item.id) teacherBoard = true
            if (mode === "all" || !teacherBoard)
                delAllObjSession(item.id)
            if (mode === "studentAndPages" && !teacherBoard) {

                deleteSessionPlain(item.id)
            }
        })
    }
    if (mode === "all") {
        getboardsbyparent(sess.parentID, null, gotBoards)
    }
    function gotBoards(items) {
        updateBoards(items)
    }
    if (sess.Classroom) {
        listSessionByClassroom(sess.Classroom, gotBoards, null, false, null)
        if (mode === "studentAndPages") {
            listLocalUsersByClass(sess.Classroom, foundUser, null)
        }
    }
    function foundUser(us) {
        var luid = mylocalStorage.getItem('mystoreID');
        us.forEach((u) => {
            var vv = u.id.split("-CL-")
            var local = vv[0]
            if (local === luid) {
                return
            }
            if (u && u.CurrentUser) {
                deleteUser(u.CurrentUser)
            }
            deleteLocalUsers(u.id)
        })

    }
}

export function VideoConferenceSet(sess, enable) {
    // var jj = sess.boardConfig ? JSON.parse(sess.boardConfig) : {}
    // jj['Video'] = true
    // sess.boardConfig = JSON.stringify(jj)
    // updateSession(sess)
    function updateBoards(items) {
        if (!items) return
        items.forEach((item) => {
            let jj = item.boardConfig ? JSON.parse(item.boardConfig) : {}
            jj['Video'] = enable
            item.boardConfig = JSON.stringify(jj)
            updateSession(item)
        })
    }
    getboardsbyparent(sess.parentID, null, gotBoards)
    function gotBoards(items) {
        updateBoards(items)
    }
    if (sess.Classroom) {
        listSessionByClassroom(sess.Classroom, gotBoards, null, false, null)
    }
}

export function boardConfigSet(sess, key, enable) {
    // var jj = sess.boardConfig ? JSON.parse(sess.boardConfig) : {}
    // jj['Video'] = true
    // sess.boardConfig = JSON.stringify(jj)
    // updateSession(sess)
    function updateBoards(items) {
        if (!items) return
        items.forEach((item) => {
            let jj = item.boardConfig ? JSON.parse(item.boardConfig) : {}
            jj[key] = enable
            item.boardConfig = JSON.stringify(jj)
            updateSession(item)
        })
    }
    getboardsbyparent(sess.parentID, null, gotBoards)
    function gotBoards(items) {
        updateBoards(items)
    }
    if (sess.Classroom) {
        listSessionByClassroom(sess.Classroom, gotBoards, null, false, null)
    }
}

export function getUserInfo(u) {
    var name = null;
    var avatar = null;

    if (!u || !u.username) return { "name": "unknown", "avatar": null }

    if (u.username.startsWith("Facebook_")) {
        // This is a facebook user
        const fbUserId = u.username.split("_")[1];
        avatar = `https://graph.facebook.com/${fbUserId}/picture?type=large`
        if (u.attributes.name) {
            name = u.attributes.name;
        }
    } else if (u.username.startsWith("Google_")) {
        if (u.attributes.picture) {
            avatar = u.attributes.picture;
            avatar = avatar.replace(/=s96-c$/, "=s192-c");
        }

        if (u.attributes.name) {
            name = u.attributes.name;
        }
    }

    return { "name": name, "avatar": avatar };
}

/**** groups */

function getCurrentSession() {
    const JOINURL = window.location.href.split("/board/")
    var sessID = JOINURL[1]
    return getSession(sessID)
}
export function createClassGroup(cmd) {
    if (!cmd.ttl) cmd.ttl = getTTL();
    return gqlOperationWithRetry(autoMutation.createClassGroup, { input: cmd });
}

export function updateClassGroup(cmd) {
    return gqlOperationWithRetry(autoMutation.updateClassGroup, { input: cmd });
}
export function deleteClassGroup(cmd) {
    return gqlOperationWithRetry(autoMutation.deleteClassGroup, { input: { id: cmd.id } });

}
export function listClassGroupByClass(ClassroomID, cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, Classroom: ClassroomID }
    gqlOperationWithRetry(autoQuery.queryByGroupsClassroom, cmd).then(res => {
        const dat = res.data.queryByGroupsClassroom
        if (dat) cb(dat.items, false)
        if (dat.nextToken) {
            listClassGroupByClass(ClassroomID, cb, dat.nextToken)
        }
    })
}

// context args: ClassroomID, cb, delCB, doList, subCB, retryCount=SUB_MAX_RETRY_COUNT
export function SubscribeClassGroupByClass(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList) listClassGroupByClass(context.ClassroomID, context.cb, null)
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToClassGroup, { Classroom: context.ClassroomID }))
    let retried = false;
    var s2, s4;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToClassGroup
            context.cb([data], true)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SubscribeClassGroupByClass:subscribeToClassGroup",
                error, s4, SubscribeClassGroupByClass, context);
            retried = true;
        }
    }, "SubscribeClassGroupByClass:subscribeToClassGroup");
    const s3 = API.graphql(graphqlOperation(autoSub.subscribeToDelClassGroup, { Classroom: context.ClassroomID }))
    s4 = trySubscribe(s3, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToDelClassGroup
            context.delCB(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SubscribeClassGroupByClass:subscribeToDelClassGroup",
                error, s2, SubscribeClassGroupByClass, context);
            retried = true;
        }
    }, "SubscribeClassGroupByClass:subscribeToDelClassGroup");
    context.subCB([s2, s4]);
}

function createGroupForClass(name, members, sess, cb) {
    if (sess) {
        const id = uuid()
        const pg1 = id + "-pgNum-1"
        var foundPreCog = false
        for (let i = 0; i < members.length; i++) {
            if (members[i].type && members[i].type === "precog") {
                foundPreCog = true
            }
        }
        var bc = sess.boardConfig;
        try {
            bc = JSON.parse(sess.boardConfig)
            if (!bc.hasPreCogs && foundPreCog) {
                bc.hasPreCogs = true
                sess.boardConfig = JSON.stringify(bc)
                updateSession(sess)
            }
        } catch (e) {
            console.log("ERROR IS ", e)
        }

        //parentBoard should ID 1 too incase teacher clicked on new board
        const pgSplit = sess.parentBoardID.split("-pgNum-")
        const ppid = pgSplit[0] + "-pgNum-1"
        createMultiBoard(ppid, id, sess.ttl)
        createSession(pg1, "group-" + name, "somecontent",
            id, 1, ppid, sess.boardConfig, sess.Classroom, true, sess.ttl).then((res2) => {
                const content = JSON.stringify({ members: members })
                const cmd = {
                    name: name, Classroom: sess.Classroom, content: content,
                    SessionID: pg1, ttl: res2.ttl
                }
                createClassGroup(cmd).then((gp) => {
                    const grp = gp.data.createClassGroup
                    if (cb) cb(grp)
                })
            })
    }
}
export function createNewGroup(name, members, cb) {
    getCurrentSession().then((res) => {
        var sess = res.data.getSession
        createGroupForClass(name, members, sess, cb)
    })
}
export function updateGroupForClass(old, members) {
    var foundPreCog = false
    for (let i = 0; i < members.length; i++) {
        if (members[i].type && members[i].type === "precog") {
            foundPreCog = true
        }
    }
    if (!foundPreCog) {
        updateClassGroup(old)
        return
    }
    getCurrentSession().then((res) => {
        var sess = res.data.getSession
        var bc = sess.boardConfig;
        try {
            bc = JSON.parse(sess.boardConfig)
            if (!bc.hasPreCogs && foundPreCog) {
                bc.hasPreCogs = true
                sess.boardConfig = JSON.stringify(bc)
                updateSession(sess)
            }
        } catch (e) {
            console.log("ERROR IS ", e)
        }
        updateClassGroup(old)
    })
}

/// classroom events

export function getClassroomEvent(id) {
    return gqlOperationWithRetry(autoQuery.getClassroomEvent, { 'id': id });
}

export function createClassroomEvent(cmd) {
    if (!cmd.ttl) cmd.ttl = getTTL();
    return gqlOperationWithRetry(autoMutation.createClassroomEvent, { input: cmd });
}

export function updateClassroomEvent(cmd) {
    delete cmd['createdAt']
    delete cmd['name']
    delete cmd['Render']
    delete cmd['RenderButtons']

    return gqlOperationWithRetry(autoMutation.updateClassroomEvent, { input: cmd });
}
export function deleteClassroomEvent(cmd) {
    return gqlOperationWithRetry(autoMutation.deleteClassroomEvent, { input: { id: cmd.id } });

}
export function listClassroomEventByClass(ClassroomID, cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, Classroom: ClassroomID }
    gqlOperationWithRetry(autoQuery.queryByEventsClassroom, cmd).then(res => {
        const dat = res.data.queryByEventsClassroom
        if (dat) cb(dat.items, false)
        if (dat.nextToken) {
            listClassroomEventByClass(ClassroomID, cb, dat.nextToken)
        }
    })
}

export async function listClassroomEventByClassSync(ClassroomID, filter) {
    var allList = []
    var nt = null
    do {
        var cmd = { limit: 100, nextToken: nt, Classroom: ClassroomID }
        if (filter) cmd['filter'] = filter
        var res = await gqlOperationWithRetry(autoQuery.queryByEventsClassroom, cmd)
        const dat = res.data.queryByEventsClassroom
        allList = [...allList, ...dat.items]
        nt = dat.nextToken
    } while (nt !== null)
    return allList
}

export function delChat(ClassroomID) {
    listClassroomEventByClass(ClassroomID, found, null)
    function found(evs) {
        evs.forEach((e) => {
            if (e.type === "Chat") deleteClassroomEvent(e)
        })
    }
}

// context args: ClassroomID, cb, doList, subCB, retryCount=SUB_MAX_RETRY_COUNT
export function SubscribeClassroomEventByClass(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList) listClassroomEventByClass(context.ClassroomID, context.cb, null)
    var s2, s4;
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToClassroomEvents, { Classroom: context.ClassroomID }))
    let retried = false;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToClassroomEvents
            context.cb([data], true)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SubscribeClassroomEventByClass",
                error, s4, SubscribeClassroomEventByClass, context);
            retried = true;
        }
    }, "SubscribeClassroomEventByClass");
    const s3 = API.graphql(graphqlOperation(autoSub.subscribeToClassroomEventsDel, { Classroom: context.ClassroomID }))
    s4 = trySubscribe(s3, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToClassroomEventsDel
            context.delCB(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SubscribeClassroomEventByClass:delSessionObject",
                error, s2, SubscribeClassroomEventByClass, context);
            retried = true;
        }
    }, "ObjectSubscribe:delSessionObject");
    context.subCB([s2, s4]);
}

/// classroom events
export function createErrorLog(cmd) {
    cmd.ttl = getTTL2Week()
    return gqlOperationWithRetry(autoMutation.createErrorLog, { input: cmd });
}

var subs = {}
var timer = null
export function listObjectFilter(sessionID, nextToken, cb, uptime, errcb) {
    const cmd = {
        limit: 100, nextToken: nextToken,
        sortDirection: "ASC", SessionID: sessionID,
    }
    if (uptime) {
        cmd['filter'] = { createdAt: { gt: uptime } }
    }
    gqlOperationWithRetry(autoQuery.sessionSorted, cmd).then(res => {
        const dat = res.data.sessionSorted
        cb(dat.items)
        if (dat.nextToken) {
            listObjectFilter(sessionID, dat.nextToken, cb, uptime)
        }
    }).catch(e => {
        if (errcb) errcb(e)
    })
}

function MonitorCB() {
    const dt2 = Date.now()
    // if (window.performance && window.performance.memory) {
    //     console.log("MEM", window.performance.memory)
    //     console.log("USED", window.performance.memory.usedJSHeapSize/(1024*1024))
    // }   

    for (let ss in subs) {
        const rr = subs[ss]
        listObjectFilter(ss, null, objects, rr.lastObjTS, errCB)
        function errCB(e) {
            console.log("ERROR FETCH", e)
            if (rr.cb) rr.cb()
            return
        }
        function objects(dats) {
            for (var i = 0; i < dats.length; i++) {
                const d = dats[i]
                const dt1 = new Date(d.createdAt)
                const secs = (dt2 - dt1) / 1000
                if (secs > 30) {
                    if (rr.cb) {
                        rr.cb()
                        return
                    }
                }
            }

        }
    }
}
export function gotObjMonitor(obj) {
    if (obj.SessionID in subs) {
        if (subs[obj.SessionID].lastObjTS) {
            const inDt = new Date(obj.createdAt)
            const oldDt = new Date(subs[obj.SessionID].lastObjTS)
            if (inDt < oldDt) {
                return
            }
        }
        subs[obj.SessionID].lastObj = obj
        subs[obj.SessionID].lastObjTS = obj.createdAt
    }
}
export function AddSubMonitor(sessId, cb) {
    if (!timer) {
        timer = setInterval(MonitorCB, 120 * 1000);
    }
    if (sessId in subs) {
        return
    }
    subs[sessId] = { lastObj: null, lastObjTS: null, cb: cb }
}

export function RemoveSubMonitor(sessId) {
    delete subs[sessId]
    if (subs === {}) {
        ResetSubsMonitor()
    }
}

export function ResetSubsMonitor() {
    if (timer) {
        clearInterval(timer)
        timer = null
    }
    subs = {}
}

export function runScript(scr, cb) {
    const script = document.createElement("script");
    script.src = scr;
    script.async = true;

    document.body.appendChild(script);
    script.onload = () => {
        cb();
    };
}

export function sendClassEvent(msg, state, classID, board, value, ttl, update, createcb) {
    var event = classID + "-" + board + msg
    var jj = { action: msg, state: state, value: value }
    var cmd = {
        id: event, ttl: ttl,
        event: event, Classroom: classID,
        type: msg, State: state, Content: JSON.stringify(jj)
    }
    cmd['For'] = board
    getClassroomEvent(event).then((r) => {
        const f = r.data.getClassroomEvent
        if (f) {
            if (update) updateClassroomEvent(cmd)
        } else {

            createClassroomEvent(cmd).then((r) => {
                if (createcb) {
                    var obj = r.data.createClassroomEvent
                    createcb(obj)
                }
            })
        }
    })
}

export function updateClassEvent(cmd) {
    cmd.Content = JSON.stringify(cmd.Content)
    updateClassroomEvent(cmd)
}

export function GetFoldersByOwner(user, cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, userProfileId: user }
    gqlOperationWithRetry(autoQuery.foldersByOwner, cmd).then(res => {
        const dat = res.data.foldersByOwner
        if (dat) cb(dat.items)
        if (dat.nextToken) {
            GetFoldersByOwner(user, cb, dat.nextToken)
        } else {
            cb(null);
        }
    })
}

// context args: user, cb, delcb, subCB, doList=true, retryCount=SUB_MAX_RETRY_COUNT
export function SubscribeFoldersByOwner(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList === undefined) context.doList = true;
    if (context.doList) GetFoldersByOwner(context.user, context.cb, null)
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToFoldersbyOwner, { userProfileId: context.user }))
    var s2, s4;
    let retried = false;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToFoldersbyOwner
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SubscribeFoldersByOwner:subscribeToFoldersbyOwner",
                error, s4, SubscribeFoldersByOwner, context);
            retried = true;
        }
    }, "SubscribeFoldersByOwner:subscribeToFoldersbyOwner");
    const s3 = API.graphql(graphqlOperation(autoSub.subscribeToDelFoldersbyOwner, { userProfileId: context.user }))
    s4 = trySubscribe(s3, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToDelFoldersbyOwner
            context.delcb(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SubscribeFoldersByOwner:subscribeToDelFoldersbyOwner",
                error, s2, SubscribeFoldersByOwner, context);
            retried = true;
        }
    }, "SubscribeFoldersByOwner:subscribeToDelFoldersbyOwner");
    context.subCB([s2, s4]);
}

export function createFolder(name, user, path) {
    const cmd = {
        name: name,
        userProfileId: user,
        parentFolderPath: path
    }
    return gqlOperationWithRetry(autoMutation.createFolder, { input: cmd })
}

export function updateFolder(obj) {
    return gqlOperationWithRetry(autoMutation.updateFolder, { input: obj })
}

export function deleteFolder(delId) {
    return gqlOperationWithRetry(autoMutation.deleteFolder, { input: { id: delId } })
}

export function checkStudentMove(parentObj, session) {
    listObject(session, null, function (items) {
        for (let i = 0; i < items.length; i++) {
            const obj = items[i]
            if (obj.objType !== "drawPaper") continue
            if (!obj.content) continue
            try {
                if ('type' in obj) {
                    obj['type'] = JSON.parse(obj['type'])
                    if (obj.type && obj.type.compressed) {
                        obj.content = pako.inflate(obj.content, { to: 'string' });
                        delete obj['type']
                    }
                }
            } catch (e) {
                console.error("cannot decode", e, obj)
                continue
            }

            var rr
            try {
                rr = JSON.parse(obj.content)
            } catch {
                continue
            }
            if (!rr || rr.length <= 1) continue
            if (rr[1] && rr[1].data && rr[1].data.studentMoveParent === parentObj.data.studentMoveParentID) {
                parentObj.visible = false
                parentObj.remove()
                //remove the parent
                return
            }
        }
    })
}

function prepObj(obj, compressed) {
    var copy = JSON.parse(JSON.stringify(obj))
    if (!copy.content) return null

    delete copy['created']
    copy.type = JSON.stringify({ 'compressed': compressed })
    if (compressed)
        copy.content = pako.deflate(copy.content, { to: 'string' });
    return copy
}

export function createRaster(x, y, img, session, ttl = 0, cb) {
    if (!ttl) ttl = getTTL()
    var raster = ["Raster", {
        "applyMatrix": false, "matrix": [1, 0, 0, 1, x, y], "data": { "palette": true }, "crossOrigin": "anonymous",
        "source": img
    }]
    const id = uuid()
    createObject(id, "name", session, null, "drawPaper", null, null, ttl).then(res => {
        var obj = res.data.createObject
        // if finshed by now we should update 

        obj.content = JSON.stringify(raster)
        var copy = prepObj(obj, true)
        if (copy) {
            copy.id = obj.id
            delete copy['Session']
            updateObject(copy).then((oo) => {
                const ob = oo.data.updateObject
                if (cb && ob) cb(ob)
            })
        }
    })
}

// export function getCommunityBoardbyGrade(cb, nextToken) {
//     const cmd = { limit: 100, nextToken: nextToken}
//     gqlOperationWithRetry(autoQuery.queryByGrade, cmd).then(res => {
//         const dat = res.data.queryByGrade
//         if (dat) cb(dat.items)
//         if (dat.nextToken) {
//             getCommunityBoardbyGrade(cb, dat.nextToken)
//         } else {
//             cb(null);
//         }
//     })
// }

export async function listCommunityBoardsSync() {
    var allList = []
    var nt = null
    do {
        const cmd = { limit: 100, nextToken: nt }
        var res = await gqlOperationWithRetry(autoQuery.listCommunityBoards, cmd)
        const dat = res.data.listCommunityBoards
        allList = [...allList, ...dat.items]
        nt = dat.nextToken
    } while (nt !== null)
    return allList
}

export function createCommunityBoard(cmd) {
    return gqlOperationWithRetry(autoMutation.createCommunityBoard, { input: cmd })
}

export function deleteCommunityBoard(delId) {
    return gqlOperationWithRetry(autoMutation.deleteCommunityBoard, { input: { id: delId } })
}

export function findUserProfile(email, cb) {
    const cmd = {
        filter: { email: { eq: email } },
        limit: 10,
        nextToken: null
    }
    gqlOperationWithRetry(autoQuery.listUserProfiles, cmd).then(res => {
        const dat = res.data.listUserProfiles
        if (dat.items) {
            cb(dat.items)
        }
    })

}

export function createPersonalPalette(cmd) {
    return gqlOperationWithRetry(autoMutation.createPersonalPalette, { input: cmd })
}

export function updatePersonalPalette(obj) {
    return gqlOperationWithRetry(autoMutation.updatePersonalPalette, { input: obj })
}

export function deletePersonalPalette(delId) {
    return gqlOperationWithRetry(autoMutation.deletePersonalPalette, { input: { id: delId } })
}

export function ListPersonalPalette(user, cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken, owner: user }
    gqlOperationWithRetry(autoQuery.byPOwnerSorted, cmd).then(res => {
        const dat = res.data.byPOwnerSorted
        if (dat) cb(dat.items)
        if (dat.nextToken) {
            ListPersonalPalette(user, cb, dat.nextToken)
        } else {
            cb(null);
        }
    })
}

// context args: user, cb, delcb, subCB, doList=true, retryCount=SUB_MAX_RETRY_COUNT
export function SubscribePersonalPalettesByOwner(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList === undefined) context.doList = true;
    if (context.doList) ListPersonalPalette(context.user, context.cb, null)
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToPalette, { owner: context.user }))
    var s2, s4;
    let retried = false;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToPalette
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "subscribeToPalette:subscribeToPalette",
                error, s4, SubscribePersonalPalettesByOwner, context);
            retried = true;
        }
    }, "SubscribePersonalPalettesByOwner:subscribeToPalette");
    const s3 = API.graphql(graphqlOperation(autoSub.subscribeToDelPalette, { owner: context.user }))
    s4 = trySubscribe(s3, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToDelPalette
            context.delcb(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SubscribePersonalPalettesByOwner:subscribeToDelPalette",
                error, s2, SubscribePersonalPalettesByOwner, context);
            retried = true;
        }
    }, "SubscribePersonalPalettesByOwner:subscribeToDelPalette");
    context.subCB([s2, s4]);
}


export function createCommunityPalette(cmd) {
    return gqlOperationWithRetry(autoMutation.createCommunityPalette, { input: cmd })
}

export function updateCommunityPalette(obj) {
    return gqlOperationWithRetry(autoMutation.updateCommunityPalette, { input: obj })
}

export function deleteCommunityPalette(delId) {
    return gqlOperationWithRetry(autoMutation.deleteCommunityPalette, { input: { id: delId } })
}

export function ListCommunityPalette(cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken }
    gqlOperationWithRetry(autoQuery.listCommunityPalettes, cmd).then(res => {
        const dat = res.data.listCommunityPalettes
        if (dat) cb(dat.items)
        if (dat.nextToken) {
            ListCommunityPalette(cb, dat.nextToken)
        } else {
            cb(null);
        }
    })
}

// context args: user, cb, delcb, subCB, doList=true, retryCount=SUB_MAX_RETRY_COUNT
export function SubscribeCommunityPalettes(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList === undefined) context.doList = true;
    if (context.doList) ListCommunityPalette(context.cb, null)
    const s1 = API.graphql(graphqlOperation(autoSub.onCreateCommunityPalette, { owner: context.user }))
    var s2, s4;
    let retried = false;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.onCreateCommunityPalette
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "subscribeToPalette:onCreateCommunityPalette",
                error, s4, SubscribeCommunityPalettes, context);
            retried = true;
        }
    }, "SubscribeCommunityPalettes:onCreateCommunityPalette");
    const s3 = API.graphql(graphqlOperation(autoSub.onDeleteCommunityPalette, { owner: context.user }))
    s4 = trySubscribe(s3, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.onDeleteCommunityPalette
            context.delcb(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "SubscribeCommunityPalettesByOwner:onDeleteCommunityPalette",
                error, s2, SubscribeCommunityPalettes, context);
            retried = true;
        }
    }, "SubscribeCommunityPalettesByOwner:onDeleteCommunityPalette");
    context.subCB([s2, s4]);
}

export function createGamePlay(obj, sessionTTL) {
    const ttl = (sessionTTL) ? sessionTTL : getTTL()
    obj.ttl = ttl

    return API.graphql(graphqlOperation(autoMutation.createGamePlay, { input: obj }))
}

export function updateGamePlay(obj) {
    obj.expectedVersion = obj.version
    return API.graphql(graphqlOperation(autoMutation.updateGamePlay, { input: obj }))
}

export function findGamePlay(id) {
    return API.graphql(graphqlOperation(autoQuery.getGamePlay, { 'id': id }));
}

// context args: user, cb, delcb, subCB, doList=true, retryCount=SUB_MAX_RETRY_COUNT
export function SubscribeGamePlay(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToGamePlay, { id: context.id }))
    var s2
    let retried = false;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToGamePlay
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "subscribeToGamePlay",
                error, s2, SubscribeGamePlay, context);
            retried = true;
        }
    }, "subscribeToGamePlay:subscribeToGamePlay");

    context.subCB([s2]);
}

export async function getLanguages() {
    var translateURL = "https://www.googleapis.com/language/translate/v2/languages" +
        "?key=AIzaSyAo_Srf7D6PODgjHD3bPOjab-jDzwK9FvU"

    const response = await fetch(translateURL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
    var r = await response.json();
    if (r && r.data) return r.data.languages
    return null
}
export async function gTranslate(text, lang) {

    // Example POST method implementation:
    async function postData(url = '', data = {}) {

        var translateURL = "https://www.googleapis.com/language/translate/v2/" +
            "?key=" + data.key +
            "&target=" + data.target +
            "&q=" + data.q

        const response = await fetch(translateURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.json();
        // // Default options are marked with *
        // const response = await fetch(url, {
        //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "X-HTTP-Method-Override": "GET",
        //         key: data.key 
        //     },
        //     redirect: 'follow', // manual, *follow, error
        //     body: JSON.stringify(data) // body data type must match "Content-Type" header
        // });
        // return response.json(); // parses JSON response into native JavaScript objects
    }

    const params = {
        "key": "AIzaSyAo_Srf7D6PODgjHD3bPOjab-jDzwK9FvU",
        "q": text,
        "target": lang,
        "alt": "json",
    }

    var ret = await postData('https://translation.googleapis.com/language/translate/v2', params)
    if (ret && ret.data && ret.data.translations) {
        return ret.data.translations[0].translatedText
    }
    return null
}

export function lockClass(sess, msg, state, classLevel, timer) {

    var event = classLevel ? sess.Classroom + "-" + msg : sess.parentID + "-" + msg
    var jj = { action: msg, state: state, bytimer: timer, creator: sess.CreatorLocalID }
    var cmd = {
        id: event, ttl: sess.ttl,
        event: event, Classroom: sess.Classroom,
        type: msg, State: "Sent", Content: JSON.stringify(jj)
    }
    if (!state) {
        cmd.State = "Done"
    }
    if (classLevel) {
        cmd['For'] = sess.Classroom
    } else {
        cmd['For'] = sess.parentID
    }
    getClassroomEvent(event).then((r) => {
        const f = r.data.getClassroomEvent
        if (f) {
            updateClassroomEvent(cmd)
        } else {
            createClassroomEvent(cmd)
        }
    })
}

export function dateConvert(date) {
    var lang = window.navigator.userLanguage || window.navigator.language;
    var options = {
        weekday: "short",
        month: "2-digit",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    };

    return date.toLocaleString(lang, options);
}

export function gamePlayGarbage(obj, classR) {
    var now = new Date()
    var rightLoc = {}
    function gotObjects(items) {
        items.forEach((obj) => {
            if (obj.objType === "drawPaper") {
                if ('type' in obj) {
                    obj['type'] = JSON.parse(obj['type'])
                    if (obj.type && obj.type.compressed) {
                        obj.content = pako.inflate(obj.content, { to: 'string' });
                    }
                }
                var rr = null
                if (obj.content) {
                    try {
                        rr = JSON.parse(obj.content)
                    } catch {

                    }
                }
                if (rr && rr[0] === "Group" && rr[1] && rr[1].data && rr[1].data.gamePlayAvatar) {
                    var url = rr[1].data.gamePlayAv.url
                    var sess = obj.SessionID
                    var create = new Date(obj.createdAt)
                    var diff = now - create
                    if (diff < 1000) {
                        //too receent 
                        return
                    }
                    if (rightLoc[url]) {
                        if (rightLoc[url] !== sess) {
                            delObject(obj.id)
                        }
                    } else {
                        delObject(obj.id)
                    }
                }

            }
        })
    }
    function updateBoards(items) {
        if (!items) return
        items.forEach((item) => {
            listObject(item.id, null, gotObjects)
        })
    }
    function gotBoards(items) {
        updateBoards(items)
    }
    for (let i in obj.content.location) {
        var lu = obj.content.location[i]
        for (let l in lu) {
            var r = lu[l]
            if (r.url) {
                rightLoc[r.url] = i
            }
        }
    }
    if (classR) {
        listSessionByClassroom(classR, gotBoards, null, false, null)
    }
}

export function debounceClick(ctx) {
    if (ctx.timer !== null) {
        clearTimeout(ctx.timer);
        ctx.timer = null;
    }
    ctx.timer = setTimeout(function () {
        ctx.function(ctx.args)
        clearTimeout(ctx.timer);
        ctx.timer = null
    }, 200)
}

export function copyToClipboard(e, text) {
    navigator.clipboard.writeText(text)
    return
    // var dummy = document.createElement("textarea");
    // // to avoid breaking orgain page when copying more words
    // // cant copy when adding below this code
    // // dummy.style.display = 'none'
    // document.body.appendChild(dummy);
    // //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    // dummy.value = text;
    // console.log("COPY",text) 
    // dummy.select();
    // document.execCommand("copy");
    // document.body.removeChild(dummy);
}

export function copyObjtoClipBoard(obj) {
    return JSON.stringify({
        name: "WBC_OBJ", type: "image",
        obj: obj.exportJSON()
    })
}

export async function processBoardCorrections(sess, answerKey) {
    let items = await listObjectSync(sess.id)
    var points = 0
    var found = {}
    var foundDZ = {}
    var pgScoreCount = 1
    items.forEach((item) => {
        if (item.objType === "drawPaper") {
            expandObj(item)
            if (item.content && item.content.length > 1 && item.content[1]) {
                let d = item.content[1].data
                if (d && d['DropZoneID']) {
                    if (!foundDZ[d['DropZoneID']]) foundDZ[d['DropZoneID']] = []
                    foundDZ[d['DropZoneID']] = foundDZ[d['DropZoneID']].concat(d['DropZoneMeat'])
                }
            }
        }

        if (item.objType === "text") {
            expandObj(item)
            if (item.content && item.content.textBoxID) {
                let ans = answerKey[item.content.textBoxID]
                if (ans) {

                    // check answer here 
                    let got = item.content.text ? item.content.text.trim() : ""
                    let up1 = got.toUpperCase();
                    let up2 = ans.answer.toUpperCase();
                    var vals = [up2]

                    if (up2.includes(";")) {
                        vals = up2.split(';')
                    }
                    var correct = false
                    for (let i in vals) {
                        let v = vals[i].trim()
                        up1 = up1.replace(/\n/g, '');
                        if (ans.ignoreSpecial) {
                            up1 = up1.replace(/[^a-zA-Z0-9]/g, '');
                            v = v.replace(/[^a-zA-Z0-9]/g, '');
                        }
                        if (up1 === v) {
                            correct = true
                            break
                        }
                    }
                    if (correct) {
                        item.content.color = "green"
                        points = points + ans.points
                        found[item.content.textBoxID] = "" + ans.points
                    } else {
                        item.content.color = "red"
                        found[item.content.textBoxID] = "0"
                    }
                    item.content = JSON.stringify(item.content)
                    delete item['Session']
                    delete item['updatedAt']
                    updateObject(item)
                }
            }
            if (item.content && item.content.pointObj) {
                if (item.content.text.includes("Score")) {
                    var f22 = item.content.text.split("] Correction Count:")[1]
                    if (f22) {
                        pgScoreCount = parseInt(f22) + 1
                    }

                }
                delObject(item.id)
            }
        }
    })
    var pg = sess.id.split("-pgNum-")[1]
    var total = 0
    if (answerKey.pages[pg]) {
        for (let i in answerKey.pages[pg]) {
            var a = answerKey.pages[pg][i]
            if (a.dropZone) {
                if (a.dropZone.id in foundDZ) {
                    let same = compA(foundDZ[a.dropZone.id], a.dropZone.answers)
                    if (same) {
                        found[i] = "" + a.ansKey.points
                        points = points + a.ansKey.points

                    } else {
                        found[i] = "0"
                    }
                }
            }
            total = total + answerKey.pages[pg][i].ansKey.points
            if (found[i]) {
                createItem(found[i] === "0" ? "red" : "green", answerKey.pages[pg][i].location.x + 10,
                    answerKey.pages[pg][i].location.y + 20, "[" + found[i] + "/" + answerKey.pages[pg][i].ansKey.points + "]", "5")
            } else {
                createItem("red", answerKey.pages[pg][i].location.x + 10,
                    answerKey.pages[pg][i].location.y + 20, "[0/" + answerKey.pages[pg][i].ansKey.points + "]", "5")
            }
        }

        //final score
        var finalScore = "Score: " + points
        if (total > 0) {
            var percent = (100.0 * points / total).toFixed(2);
            finalScore = "Score: " + percent + "% [" + points + "/" + total + "] Correction Count:" + pgScoreCount;
        }
        createItem("green", 80, 110, finalScore, "6")
    }
    function compA(a, b) {
        if (a.length !== b.length) return false
        a.sort()
        b.sort()
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) return false
        }
        return true
    }
    function createItem(color, x, y, text, sz) {
        var lastItem = {
            id: uuid(),
            objType: "text",
            ended: true,
            SessionID: sess.id
        }
        var content = {
            "type": "text", "points": { "x": x, "y": y }, "text": text, "ended": true, pointObj: true,
            "color": color, "brushRadius": sz, "font": "Roboto", "size": { "w": 231, "h": 152 }
        }

        lastItem.content = JSON.stringify(content)
        createObjPlain(lastItem)
    }
}

export function expandObj(obj) {
    try {
        if ('type' in obj) {
            obj['type'] = JSON.parse(obj['type'])
            if (obj.type && obj.type.compressed) {
                obj.content = pako.inflate(obj.content, { to: 'string' });
            }
        }
    } catch (e) {
    }
    try {
        obj.content = JSON.parse(obj.content)
    } catch {
        return false
    }
}

export async function lockSingle(classR, single, state) {
    var pages = await getSessionByClassroomSync(classR)
    var found = null
    for (let i = 0; i < pages.length; i++) {
        var page = pages[i]
        if (single && single === page.CreatorLocalID) {
            found = page
            break
        }
    }
    if (found) lockClass(found, "LockClass", state, false, false)
}

export function autoCorrect(classR, single, pageNum) {
    return new Promise(async (resolve, reject) => {
        var pages = await getSessionByClassroomSync(classR)
        var answerKey = { pages: {} }

        for (let i = 0; i < pages.length; i++) {
            var page = pages[i]
            if (page.id === page.parentBoardID) {
                let ff2 = page.id.split("-pgNum-")[1]
                if (pageNum && parseInt(ff2) !== parseInt(pageNum)) {
                    continue
                }
                let items = await listObjectSync(page.id)
                items.forEach((item) => {
                    if (item.objType === "drawPaper") {
                        expandObj(item)
                        if (item.content && item.content.length > 1 && item.content[1]) {
                            let d = item.content[1].data
                            if (d && d["answerKey"]) {
                                answerKey[d.linkData.id] = d["answerKey"]
                                if (!answerKey.pages[ff2]) {
                                    answerKey.pages[ff2] = {}
                                }
                                let mx = 0
                                let my = 2000
                                if (item.content[1].segments) {
                                    for (let r in item.content[1].segments) {
                                        let rr2 = item.content[1].segments[r]
                                        let x = rr2[0]
                                        let y = rr2[1]
                                        if (x > mx) mx = x
                                        if (y < my) my = y
                                    }
                                }
                                answerKey.pages[ff2][d.linkData.id] = { ansKey: d["answerKey"], dropZone: d['DropZone'] }
                                if (mx > 0) {
                                    answerKey.pages[ff2][d.linkData.id]["location"] = { x: mx, y: my }
                                }
                            }
                        }
                    }
                })
            }
        }
        for (let i = 0; i < pages.length; i++) {
            let page = pages[i]
            let ff2 = page.id.split("-pgNum-")[1]
            if (pageNum && parseInt(ff2) !== parseInt(pageNum)) {
                continue
            }

            if (single && single !== page.CreatorLocalID) {
                continue
            }
            if (page.id !== page.parentBoardID) await processBoardCorrections(page, answerKey)
        }
        resolve()
    })
}


async function removeBoardCorrection(sess, answerKey) {
    let items = await listObjectSync(sess.id)
    items.forEach((item) => {
        if (item.objType === "text") {
            expandObj(item)
            if (item.content && item.content.pointObj) {
                delObject(item.id)
            }
        }
    })
}

export function autoCorrectRemove(classR, single, pageNum) {
    return new Promise(async (resolve, reject) => {
        var pages = await getSessionByClassroomSync(classR)
        var answerKey = { pages: {} }

        for (let i = 0; i < pages.length; i++) {
            let page = pages[i]
            if (page.id !== page.parentBoardID) await removeBoardCorrection(page, answerKey)
        }
    })
}
export function listServiceNotices(cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken }
    gqlOperationWithRetry(autoQuery.listServiceNotices, cmd).then(res => {
        const dat = res.data.listServiceNotices
        if (dat) cb(dat.items)
        if (dat.nextToken) {
            listServiceNotices(cb, dat.nextToken)
        } else {
            cb(null);
        }
    })
}

export function truncateString(str, num) {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
        return str
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...'
}

export function createLatencyCalc(obj) {
    return API.graphql(graphqlOperation(autoMutation.createLatencyCalculation, { input: obj }))
}

export function createLatencyConfig(obj) {
    return API.graphql(graphqlOperation(autoMutation.createLatencyEndPoints, { input: obj }))
}

export function getLantencyConfig(cb) {
    const id = "LATENCY_CONFIG"
    API.graphql(graphqlOperation(autoQuery.getLatencyEndPoints, { 'id': id })).then((r) => {
        var c = r.data.getLatencyEndPoints
        if (!c) {
            createLatencyConfig({ id: id, content: JSON.stringify({ disabled: true }) }).then((p) => {
                c = p.data.createLatencyEndPoints
                if (c) {
                    c.content = JSON.parse(c.content)
                    cb(c)
                }
            })
        } else {
            c.content = JSON.parse(c.content)
            cb(c)
        }
    })
}

export const boardResoltionType = {
    'small': { w: 1280, h: 800 },
    'chromebook': { w: 1366, h: 625 },
    'ipad': { w: 1024, h: 700 },
    'a4': { w: 750, h: 900 },
    'medium': { w: 1440, h: 900 },
    'default': { w: 2000, h: 1024 },
}

export function deepCompare() {
    var i, l, leftChain, rightChain;

    function compare2Objects(x, y) {
        var p;

        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
            return true;
        }

        // Compare primitives and functions.
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if (x === y) {
            return true;
        }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if ((typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)) {
            return x.toString() === y.toString();
        }

        // At last checking prototypes as good as we can
        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        // Check for infinitive linking loops
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
        }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        for (p in y) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            }
            else if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        for (p in x) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            }
            else if (typeof y[p] !== typeof x[p]) {
                return false;
            }

            switch (typeof (x[p])) {
                case 'object':
                case 'function':

                    leftChain.push(x);
                    rightChain.push(y);

                    if (!compare2Objects(x[p], y[p])) {
                        return false;
                    }

                    leftChain.pop();
                    rightChain.pop();
                    break;

                default:
                    if (x[p] !== y[p]) {
                        return false;
                    }
                    break;
            }
        }

        return true;
    }

    if (arguments.length < 1) {
        return true; //Die silently? Don't know how to handle such case, please help...
        // throw "Need two or more arguments to compare";
    }

    for (i = 1, l = arguments.length; i < l; i++) {

        leftChain = []; //Todo: this can be cached
        rightChain = [];

        if (!compare2Objects(arguments[0], arguments[i])) {
            return false;
        }
    }

    return true;
}

export function sendShowCaseEvent(studentID, state, sess) {
    var msg = "SHOWCASE"
    var event = sess.Classroom + "-" + msg + "-" + studentID
    var jj = { action: msg, state: state, student: studentID }
    var cmd = {
        id: event, ttl: sess.ttl,
        event: event, Classroom: sess.Classroom,
        type: msg, State: "Sent", Content: JSON.stringify(jj)
    }
    if (!state) {
        cmd.State = "Done"
    }
    cmd['For'] = sess.Classroom
    getClassroomEvent(event).then((r) => {
        const f = r.data.getClassroomEvent
        if (f) {
            updateClassroomEvent(cmd)
        } else {
            createClassroomEvent(cmd)
        }
    })
}

export function sendFormSubmit(formID, sess, name, form) {
    var msg = "FORMSUBMIT"
    var luid = mylocalStorage.getItem('mystoreID');

    var event = sess.Classroom + "-" + msg + "@" + formID + "#" + luid
    var jj = {
        action: msg, state: "Sent", student: luid, name: name,
        form: form
    }
    var cmd = {
        id: event, ttl: sess.ttl,
        event: event, Classroom: sess.Classroom,
        type: msg, State: "Sent", Content: JSON.stringify(jj)
    }

    cmd['For'] = sess.Classroom
    getClassroomEvent(event).then((r) => {
        const f = r.data.getClassroomEvent
        if (f) {
            updateClassroomEvent(cmd)
        } else {
            createClassroomEvent(cmd)
        }
    })
}

export function clearUndefined(c) {
    var x = { ...c }
    var keys = Object.keys(x)
    for (let k in keys) {
        var key = keys[k]
        var o = x[key]
        if (typeof (o) === 'undefined') {
            delete x[key]
        }
    }
    return x
}

export async function getService() {
    const items = await API.get('serviceApi', '/service', {
        // 'queryStringParameters': {
        //   'order': 'byPrice'
        // }
    });
    return items
}

export async function postData(data) {
    const response = await fetch("http://localhost:5000/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: data
    })
    var r = await response.json();
    return r
}

export function updateOembedLink() {
    var link = document.getElementById('oembed-link');
    var encStr = encodeURIComponent(window.location.href);

    if (link) {
        link.type = "application/json+oembed"
        link.href = `https://oembed.whiteboard.chat/oembed?url=${encStr}&format=json`
    }
}


export async function getImage(img) {
    // const response = await fetch("http://localhost:5000/items", {
    let res = await API.post('serviceApi', '/voiceToText', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: { "type": "GETIMAGE", "value": img }
    })
    return res
}

export async function copyBoard(boardData, instanceObj, userEmailId, cb, errCb) {
    var dict = { 'boardData': boardData, 'instanceObj': instanceObj, 'userEmailId': userEmailId }
    API.post('serviceApi', '/copyboard', {
        'body': dict
    }).then((res) => {
        cb(res.message)
    }).catch(error => errCb("Export board failed. Please try again later."))
}

function exportCSV(data, boardName) {
    const filename = boardName + '.csv';
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURI(data));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export function doReport(classR) {
    return new Promise(async (resolve, reject) => {
        var pages = await getSessionByClassroomSync(classR)
        var report = {}
        var evt = {}
        var rh = {}
        var chat = {}
        var poll = {}
        var name = "WBC"
        var events = await listClassroomEventByClassSync(classR, null)
        for (let i = 0; i < events.length; i++) {

            var e = events[i]
            let f = e.For.split(":")
            if (e.type === "RaiseHand") {
                if (f.length <= 1) continue
                let nm = f[1]
                try {
                    let ct = JSON.parse(e.Content)
                    if (!(nm in rh)) {
                        rh[nm] = ""
                    }
                    var tt = ct.kind
                    rh[nm] = tt
                } catch { }
            }
            if (e.type === "FORMSUBMIT") {
                try {
                    let ct = JSON.parse(e.Content)
                    let nm = ct.name
                    if (!(nm in poll)) {
                        poll[nm] = ""
                    }
                    let tt = ""
                    for (let i = 0; i < ct.form.length; i++) {
                        let f = ct.form[i]
                        tt = tt + f.val + ":" + f.set + ";"
                    }
                    poll[nm] = poll[nm] + "  " + tt
                } catch { }
            }
            if (e.type === "Chat") {
                try {
                    let ct = JSON.parse(e.Content)
                    let nm = ct.from ? ct.from.name : null
                    if (!nm) continue
                    if (!(nm in chat)) {
                        chat[nm] = 0
                    }
                    chat[nm]++
                } catch { }
            }
            if (e.type === "Poll") {
                if (f.length <= 1) continue
                let nm = f[1]
                try {
                    let ct = JSON.parse(e.Content)
                    if (!(nm in evt)) {
                        evt[nm] = ""
                    }
                    let tt = ct.text
                    tt = tt.replace(/,/g, ';')
                    evt[nm] += " " + tt + ","
                } catch { }
            }
        }
        for (let i = 0; i < pages.length; i++) {
            var page = pages[i]
            if (page.id !== page.parentBoardID) {
                var r = await processPage(page)
                if (!r) continue
                report[r.name + '-' + r.pgNum] = r.name + ',' + r.pgNum + "," + r.pageCreated + "," + r.objectCount + "," + r.lastObj + "," + r.timeSpent + "," + r.Score
                if (r.pgNum === "1") {
                    if (evt[r.name]) {
                        report[r.name + '-' + r.pgNum] += "," + evt[r.name]
                    } else {
                        report[r.name + '-' + r.pgNum] += ","
                    }
                    if (poll[r.name]) {
                        report[r.name + '-' + r.pgNum] += "," + poll[r.name]
                    } else {
                        report[r.name + '-' + r.pgNum] += ","
                    }
                    if (rh[r.name]) {
                        report[r.name + '-' + r.pgNum] += "," + rh[r.name]
                    } else {
                        report[r.name + '-' + r.pgNum] += ","
                    }
                    if (chat[r.name]) {
                        report[r.name + '-' + r.pgNum] += "," + chat[r.name]
                    } else {
                        report[r.name + '-' + r.pgNum] += ","
                    }
                }
            } else {
                name = page.name
            }
        }
        var final = "Name, Page Number, Page Created, Number of Objects, Last Annotation, Time Spent, Page Score, Poll Responses, Forms, Last Raise Hand, Chat count\n"
        var k = Object.keys(report).sort()
        for (let i = 0; i < k.length; i++) {
            let rr = report[k[i]]
            final += rr + "\n"
        }
        exportCSV(final, name)
        async function processPage(page) {
            var r = {}
            var savedUser
            r['pageCreated'] = dateConvert(new Date(page.createdAt)).replace(/,/g, ";");
            page.Users.items.forEach(u => {
                var xx = JSON.parse(u.content)
                if (xx.localID === page.CreatorLocalID) {
                    u.content = xx
                    savedUser = u
                }
            })
            if (!savedUser) {
                return null
            }
            let items = await listObjectSync(page.id)
            var count = 0
            r['Score'] = ""
            for (let i = 0; i < items.length; i++) {
                const item = items[i]
                if (item.CreatedBy !== savedUser.id) {
                    if (item.objType === "text") {
                        expandObj(item)
                        if (item.content && item.content.pointObj && item.content.text.includes("Score")) {
                            r['Score'] = item.content.text
                        }
                    }
                    continue
                }
                if (!r.firstObj) {
                    r.firstObj = new Date(item.updatedAt)
                }
                r.lastObj = new Date(item.updatedAt)
                count++
            }
            if (!count) {
                return null
            }
            r['objectCount'] = count
            var diff = r.lastObj - new Date(page.createdAt)
            r['firstObj'] = dateConvert(r.firstObj).replace(/,/g, ";");
            r['lastObj'] = dateConvert(r.lastObj).replace(/,/g, ";");
            r['timeSpent'] = Math.floor((diff / 1000) / 60);
            r['name'] = savedUser.name
            r['pgNum'] = page.id.split('-pgNum-')[1]
            return r
        }
        resolve()
    })
}

export function getCorsURL(url, cb) {
    var createCORSRequest = function (method, url) {
        var xhr = new XMLHttpRequest();
        if ("withCredentials" in xhr) {
            // Most browsers.
            xhr.open(method, url, true);
        } else {
            // CORS not supported.
            xhr = null;
        }
        return xhr;
    };
    console.log("FETCH URL", url)
    var method = 'GET';
    var xhr = createCORSRequest(method, url);

    xhr.onload = function () {
        // Success code goes here.
        cb(xhr)
    };

    xhr.onerror = function () {
        // Error code goes here.
        console.log("ERROR=======")
    };

    xhr.send();
}

const ESCROOMNAME = "escapeRoom"
export function getEscapeRoomPage(sess, pgnum) {
    var lu = mylocalStorage.getItem(ESCROOMNAME)
    if (!lu) return null
    lu = JSON.parse(lu)
    if (lu.board !== sess.Classroom) {
        mylocalStorage.removeItem(ESCROOMNAME)
        return null
    }
    return lu.pages[pgnum]
}

export function setEscapeRoomPage(sess, pgnum, val) {
    var lu = mylocalStorage.getItem(ESCROOMNAME)
    if (lu) {
        lu = JSON.parse(lu)
        if (lu.board !== sess.Classroom) {
            mylocalStorage.removeItem(ESCROOMNAME)
            lu = null
        }
    }
    if (!lu) {
        lu = {
            board: sess.Classroom,
            pages: {}
        }
    }
    var oldpg = lu.pages[pgnum]
    lu.pages[pgnum] = {
        ...oldpg,
        ...val
    }
    mylocalStorage.setItem(ESCROOMNAME, JSON.stringify(lu))
}
/*****/
export function createEngagementEvent(cmd) {
    cmd.ttl = getTTL3Days()
    return gqlOperationWithRetry(autoMutation.createEngagementEvent, { input: cmd })
}

export function updateEngagementEvent(obj) {
    return gqlOperationWithRetry(autoMutation.updateEngagementEvent, { input: obj })
}

export function deleteEngagementEvent(delId) {
    return gqlOperationWithRetry(autoMutation.deleteEngagementEvent, { input: { id: delId } })
}

export function ListEngagementEvent(user, cb, nextToken, Classroom) {
    const cmd = { limit: 100, nextToken: nextToken, owner: user, Classroom: Classroom }
    gqlOperationWithRetry(autoQuery.querybyEngagementClass, cmd).then(res => {
        const dat = res.data.querybyEngagementClass
        if (dat) cb(dat.items)
        if (dat.nextToken) {
            ListEngagementEvent(user, cb, dat.nextToken)
        } else {
            cb(null);
        }
    })
}

export async function ListEngagementEventSync(Classroom) {
    var allList = []
    var nt = null
    do {
        const cmd = { limit: 100, nextToken: nt, sortDirection: "ASC", Classroom: Classroom }

        var res = await gqlOperationWithRetry(autoQuery.querybyEngagementClass, cmd)
        const dat = res.data.querybyEngagementClass
        allList = [...allList, ...dat.items]
        nt = dat.nextToken
    } while (nt !== null)
    return allList
}

// context args: user, cb, delcb, subCB, doList=true, retryCount=SUB_MAX_RETRY_COUNT
export function SubscribeEngagementEventsByOwner(context) {
    if (context.retryCount === undefined) context.retryCount = SUB_MAX_RETRY_COUNT;
    if (context.doList === undefined) context.doList = true;
    if (context.doList) ListEngagementEvent(context.user, context.cb, null, context.Classroom)
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToEngagementEvent, { Classroom: context.Classroom }))
    var s2;
    let retried = false;
    s2 = trySubscribe(s1, {
        next: (value) => {
            if (context.retryCount < SUB_MAX_RETRY_COUNT) context.retryCount = SUB_MAX_RETRY_COUNT;
            const data = value.value.data.subscribeToEngagementEvent
            context.cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
            checkAndReSubscribe(retried, "subscribeToEngagementEvent:subscribeToEngagementEvent",
                error, null, SubscribeEngagementEventsByOwner, context);
            retried = true;
        }
    }, "SubscribeEngagementEventsByOwner:subscribeToEngagementEvent");
    context.subCB([s2]);
}
