/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable no-useless-escape  */
/* eslint-disable no-eval */

import React, { useState } from 'react';
import * as ib from './ibdata'
import { LazyBrush } from 'lazy-brush'
import { Catenary } from 'catenary-curve'
import ResizeObserver from 'resize-observer-polyfill'
//import styleVariables from '!!sass-variable-loader!./../styles/_variables.scss'
import { v4 as uuid } from "uuid";
import pako from 'pako'
import Peer from 'peerjs';

import paper, { Path } from 'paper';
import Point from 'catenary-curve/lib/Point';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import * as iu from "./ImageUtils"
import MiniDrawer from "./MiniDrawer"

import TableFactoryDialog from "./TableFactoryDialog"
import PeerConnection from "./PeerConnection"
import clsx from "clsx";
import * as mylocalStorage from "./mylocalStorage"

import BoardColorPicker from './BoardColorPicker'

var apiBoardCreated = false

// import *  as lat from "./latency.js"
const styleVariables = {
    'colorPrimary': "#f2530b",
    'colorOthers': "#3da3db",
    'bgColor': "#ffffff",
    'toolColor': "#E5E5E5"
}

const profilesMap = {
    "daVinci": true,
    "VanGogh": true,
    "Rembrandt": true,
    "Monet": true,
    "Michelangelo": true,
    "Picasso": true,
    "Matisse": true,
    "Frida": true,
}

const profiles = Object.keys(profilesMap);

const verbsMap = {
    "Calm": true,
    "Content": true,
    "Joyful": true,
    "Tranquil": true,
    "Gloomy": true,
    "Dark": true,
    "Exciting": true,
    "Natural": true,
    "Clear": true,
    "Abstract": true,
    "Lively": true,
    "Vivid": true,
    "Intense": true,
}

const verbs = Object.keys(verbsMap);

export const epiColors = [
    "#003D7F",
    "#EF6C35",
    "#004899",
    "#F4B400",
    "#71C001",
    "#9F8FCE",
    "#F0728A",
    "#EF6C35",
    "#ED7D31",
    "#4A86E3",
    "#913791",
    "#0F9D58",
    "#882ff6",
    "#3174F5",
]
const defaultFrameColor = "#3174F5"
var maxWidth = 2000
var maxHeight = 1280
var smallMaxWidth = 1440
var smallMaxHeight = 1280
var mobileWidth = 1000
var mobileHeight = 800
const initObj = { id: null, ended: false, content: null, created: false }
const defaultObj = { id: null, ended: false, content: null, created: false }
var session = null
var myObj = initObj
var objDict = {}
const defaultPeer = { connection: null, id: null, oldmouse: null }
var peers = {}
var myPeerID = null
var debounceTimer = null
export var myName = "noone"
var gSessionID = null
var gParentSession = null
var gLocked = false
var onceMessage = false
var paperObj = {}
var moveObjs = {}
var animatePath = null
var gpause = false
var aniMessage = false
var gUser = null
var drawGridCfg = null
var ggridView = { open: false }
var gMultiBoard = { multiBoardOption: false, participantsSeeEachOther: false, amIParent: false, parentID: null }
var lastTap = 0;
var dtTimeout
var savedLocalUsers = {}
var lastText;
var lastObjDraw = false;
var onceGA = true
var keepaliveTimer = null;
var gSession = null;
var renamed = false
var selectedObj = null
var gGrid = null
var gTables = 0
var gBackGround = null
var gTeacher = 0
var boundBRect = null
var defaultTool = "draw"
var boundRect = null
var debouceRect = null
var defaultTimer = null
var gSyncDisabled = false

var gUsers = []

var buttonClicked
var gText = 5
var gEnter = false;
var gSpeechText = false;
var contorlButtonClick = false
var observeCanvas;
var savePos = null
var gStopLight = {}
var gStopLightParent = {}
var gTextBox = {}
var inpBox = null
var hasInput = false
var inValue = null
var gCtx = {}
var gDropZone = {}
var gridPainter = {}
var gResolution = "default"
var gBoardTools = {}
var gPeer = null
var gZoomEnabled = true
var myRect = { obj: null, start: null, end: null }
var gLoadMaps = {}
var magicDrawObj = {}
var gPanned = false
var gScrollFollow = null
var positionTimer = null

const DEFTOOLCURSOR = {
    "draw": "auto",
    "edit": "crosshair",
    "moveResize": "move",
    "selectAnimate": "pointer",
}

var gShowcase = null
var evCache = []
var prevDiff = -1;

function mkColor(color, opacity) {
    if (!opacity) return color;
    if (color && !color.includes("#")) return color;
    const onlyColor = color && color.substr(0, 7);
    const alpha = (Math.floor(255 * opacity / 100) + 0x100).toString(16).substr(-2).toUpperCase()
    return onlyColor + alpha;
}

function getElPosition(el) {
    var xPos = 0;
    var yPos = 0;

    while (el) {
        if (el.tagName === "BODY") {
            // deal with browser quirks with body/window/document and page scroll
            var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
            var yScroll = el.scrollTop || document.documentElement.scrollTop;

            xPos += (el.offsetLeft - xScroll + el.clientLeft);
            yPos += (el.offsetTop - yScroll + el.clientTop);
        } else {
            // for all other non-BODY elements
            xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
            yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }

        el = el.offsetParent;
    }
    return {
        x: xPos,
        y: yPos
    };
}


function WhiteBoard(props) {
    const [sess, setSession] = React.useState(null);
    const [ctx, setCtx] = React.useState(gCtx)
    var [user, setUser] = React.useState(null)

    const [eraseMode, seteraseMode] = React.useState(false)
    const [drawMode, setdrawMode] = React.useState({ name: "draw" })
    const [inkColor, setInkColor] = React.useState(defaultFrameColor);

    const [authBoardOnly, setAuthBoardOnly] = React.useState(false)
    const [multBoardCfg, setMultiBoardCfg] = React.useState(gMultiBoard)

    // const [highlighterMode, setHighlighterMode] = React.useState(false);
    const [overlayHelpOpen, setOverlayHelpOpen] = React.useState(true);

    const boardTools = useSelector((state) => state.boardTools)

    const authDialog = useSelector((state) => state.authDialog);
    const teacherR = useSelector((state) => state.teacher);

    const richText = useSelector((state) => state.richText);

    const dispatch = useDispatch();

    const authUser = useSelector((state) => state.user);
    const noUser = useSelector((state) => state.noUser);

    const boardConfig = useSelector((state) => state.BoardConfig);
    const tab = useSelector((state) => state.tab);
    const [userRole, setUserRole] = useState(null)


    const [openTip, setopenTip] = React.useState(false)
    const [parentBoardData, setParentBoardData] = useState([])

    const [penBtnColor, setPenBtnColor] = React.useState(defaultFrameColor);


    const LAZY_RADIUS = 0
    const BRUSH_RADIUS = 2
    const SCROLLMODE_DRAW = true
    const mobile = (typeof window.orientation !== 'undefined')
    const minWidthForTour = 700
    var ua = window.navigator.userAgent;
    var smallScreen = false
    //CrOS
    if (onceGA && (ua.indexOf("CrOS") !== -1) && window.screen.width < 1280) {
        //low end chomebook
        onceGA = false
        maxWidth = smallMaxWidth
        maxHeight = smallMaxHeight
    } else {
        onceGA = false
    }
    if (mobile) {
        if (window.screen.width < 500) {
            maxWidth = mobileWidth
            maxHeight = mobileHeight
            smallScreen = true
        }
    }
    function setMyObj() {
        myObj = JSON.parse(JSON.stringify(defaultObj))
    }
    function clearWidgetCount() {

    }
    function clearGlobal() {
        paperObj = {}
        moveObjs = {}
        objDict = {}
        gridPainter = {}
        gGrid = null
        gBackGround = null
        dispatch(Actions.setBackGround(null))
        clearWidgetCount()
        setMyObj()
    }
    var inkColorDebounceTimer = null
    function setInkColorDebounced(col) {
        if (inkColorDebounceTimer !== null) {
            clearTimeout(inkColorDebounceTimer);
            inkColorDebounceTimer = null;
        }
        inkColorDebounceTimer = setTimeout(function () {
            // console.log("Setting color to = ", col);
            if (col !== "#ffffff") {
                setInkColor(p => col);
                mylocalStorage.setItem("savedInk", col)
            }
            clearTimeout(inkColorDebounceTimer);
            inkColorDebounceTimer = null;
        }, 200);
    }
    window.onload = function () {
        //paper.install(window);
        //  paper.view.onFrame = onFrame
    }
    if (authBoardOnly && !authDialog.open && !authUser) {
        function signedIn() {
            dispatch(Actions.openAuthDialog({ open: false, siCB: null }))
        }
        dispatch(Actions.openAuthDialog({ open: true, siCB: signedIn }))

    }

    function findcreatelocalUser() {
        var authid = authUser && authUser.username ? authUser.username : null
        var luid = mylocalStorage.getItem('mystoreID');
        if (!luid) {
            luid = uuid()
            mylocalStorage.setItem('mystoreID', luid)
        }
        if (authid && luid !== authid) {
            mylocalStorage.setItem('mystoreID', authid)
            mylocalStorage.setItem('backupLocalID', luid);
            luid = authid //from now on the authenicated user and the stored user will be same
        }
        return luid
    }

    function findUpdateLocalUserIfNeeded() {
        /**
         * Since this is called from useEffect for handling
         * user timeouts, we can no longer use sess, instead
         * we have to use a global session object (gSession).
         */
        if (gSession && gSession.Classroom) {
            var luid = findcreatelocalUser()
            const localId = luid + "-CL-" + gSession.Classroom
            ib.getLocalUsers(localId).then((dat) => {
                // var sp = document.getElementById('canvas_container')
                // var win = {height: window.innerHeight, width: window.innerWidth, sy: sp.scrollTop, sx: sp.scrollLeft}
                const lu = dat.data.getLocalUsers
                if (!lu) {
                    const userid = gUser ? gUser.id : null
                    if (userid) {
                        ib.createLocalUsers({
                            id: localId, CurrentSessionId: gSessionID,
                            CurrentUserId: userid, ClassroomID: gSession.Classroom,
                            ttl: gSession.ttl
                        })
                    }
                } else {
                    ib.updateLocalUsers({ id: localId, CurrentSessionId: gSessionID, CurrentUserId: gUser.id })
                }
            })
        }
    }
    React.useEffect(() => {
        var updateSession = false
        if (sess && authUser) {
            if (sess.savedOwner === null) {
                //who created this?
                if (sess.CreatorLocalID && sess.CreatorLocalID === findcreatelocalUser()) {
                    sess.savedOwner = authUser.username
                    updateSession = true
                }
                if (!sess.CreatorLocalID) {
                    sess.savedOwner = authUser.username
                    updateSession = true
                }
            }
        }
        if (sess && boardConfig && authUser) {
            const jp = JSON.stringify(boardConfig)
            if (sess.boardConfig !== jp && sess.savedOwner === authUser.username) {
                if (boardConfig.multiBoardOption) {
                    if (!sess.Classroom) {
                        const pgSplit = sess.parentBoardID.split("-pgNum-")
                        if (pgSplit[0] === sess.parentID) {
                            //here 
                            ib.createClassroom({ id: sess.parentID, TeacherID: authUser.username, name: "myclass", ttl: sess.ttl })
                            sess.Classroom = sess.parentID
                            findUpdateLocalUserIfNeeded() // for the teacher
                        }
                    }
                }
                if (sess.parentBoardID === sess.id) {
                    //only allowed for non inherited boards 
                    var oc = {}
                    try {
                        oc = JSON.parse(sess.boardConfig)
                    } catch { }
                    var nc = { ...oc, ...boardConfig }
                    if (sess && sess.Classroom) nc.multiBoardOption = true
                    var rr = JSON.stringify(nc)
                    var f1 = ib.clearUndefined(nc)
                    var f2 = ib.clearUndefined(JSON.parse(sess.boardConfig))
                    if (!ib.deepCompare(f1, f2)) {
                        sess.boardConfig = rr
                        updateSession = true
                    }
                }
            }
        }

        if (sess && sess.boardConfig) {
            function getPrevPage(pnum) {
                var cp = pnum
                var prevPage = pnum - 1
                if (sess && sess.boardConfig) {
                    try {
                        var bc = JSON.parse(sess.boardConfig)
                    } catch { }

                    if (bc.boardOrder) {
                        for (let l in bc.boardOrder.order) {
                            if (bc.boardOrder.order[l] === pnum) {
                                cp = l
                            }
                        }
                        var requestedID = bc.boardOrder.order[cp - 1]
                        if (requestedID) return requestedID
                    }
                }
                return prevPage
            }
            function getMapPage(pnum) {
                if (sess && sess.boardConfig) {
                    try {
                        var bc = JSON.parse(sess.boardConfig)
                    } catch { }

                    if (bc.boardOrder) {
                        for (let l in bc.boardOrder.order) {
                            if (bc.boardOrder.order[l] === pnum) {
                                return l
                            }
                        }
                    }
                }
                return pnum
            }
            const j = JSON.parse(sess.boardConfig)
            function redir(w) {
                alert("Cannot go to this page yet - check - " + w)
                const JOINURL = window.location.href.replace("/board", "/join")
                window.location.href = JOINURL
                setTimeout(function () {
                    window.location.reload()
                }, 300);
            }
            if (j.boardOrder && sess.pageNumber > 1 && sess.id !== sess.parentBoardID && !gTeacher) {
                if (j.boardOrder.password) {
                    var act = getMapPage(sess.pageNumber)
                    if (j.boardOrder.password[act]) {
                        var lu = ib.getEscapeRoomPage(sess, act)
                        var check = j.boardOrder.password[act]
                        if (check && typeof check !== 'string') {
                            if (check.type === "password") {
                                if (!lu || lu.password !== check.name) {
                                    redir("password")
                                }
                            }
                            if (check.type === "score") {
                                var pnum = getPrevPage(sess.pageNumber)
                                lu = ib.getEscapeRoomPage(sess, pnum)
                                if (!lu || parseInt(lu.score) < parseInt(check.name)) {
                                    redir("score")
                                }
                            }
                        }
                    }
                }
            }
            if ('boardAuthUsersOnly' in j) {
                setAuthBoardOnly(j.boardAuthUsersOnly)
            }
            if ('boardShowGrid' in j) {
                if (!drawGridCfg && j.boardShowGrid) {
                    // if (!mobile) drawGrid(ctx.context.grid)
                    drawGridCfg = "grid"
                } else {
                    if (drawGridCfg && !j.boardShowGrid) {
                        // if (!mobile) ctx.context.grid.clearRect(0, 0, ctx.context.grid.canvas.width, ctx.context.grid.canvas.height)
                    }
                    drawGridCfg = null
                }
            }
            if ('boardShowMusicGrid' in j && drawGridCfg === null) {
                if (!drawGridCfg && j.boardShowMusicGrid) {
                    // if (!mobile) drawMusicGrid(ctx.context.grid)
                    drawGridCfg = "music"
                } else {
                    if (drawGridCfg && !j.boardShowMusicGrid) {
                        // ctx.context.grid.clearRect(0, 0, ctx.context.grid.canvas.width, ctx.context.grid.canvas.height)
                    }
                    drawGridCfg = null
                }
            }
            if ('multiBoardOption' in j) {
                var ff = multBoardCfg
                ff.multiBoardOption = j.multiBoardOption
                ff.participantsSeeEachOther = j.participantsSeeEachOther
                if (ff.multiBoardOption) {
                    ff.parentID = sess.parentBoardID
                    if (ff.parentID === sess.id) {
                        //I am on the parent
                        ff.amIParent = true
                    } else {
                        // I should subscribe to the parent too?
                        ff.amIParent = false
                    }
                } else {
                    if (sess && sess.Classroom) ff.multiBoardOption = true
                }
                gMultiBoard = ff
                setMultiBoardCfg({ ...ff })
            }
        }

        if (renamed) {
            renamed = false;
            updateSession = true;
        }

        if (updateSession && sess) {
            ib.updateSession(sess)
        }

    }, [authUser, sess, boardConfig])


    React.useEffect(() => {
        if (user && authUser) {
            if (user.UserProfile !== authUser.username) {
                user.UserProfile = authUser.username
                delete user['Session']
                ib.updateUser(user)
                gUser = user
            } else {
                var needsUpdate = false;

                if (!(user && user.name && user.name !== "") && authUser.attributes.name) {
                    user.name = authUser.attributes.name;
                    needsUpdate = true;
                }
                if (!(user && user.avatar && user.avatar !== "")) {
                    var userInfo = ib.getUserInfo(authUser);
                    if (userInfo.avatar) {
                        user.avatar = userInfo.avatar;
                        needsUpdate = true;
                    }
                }

                if (needsUpdate) {
                    ib.updateUser(user);
                    gUser = user;
                }
            }
        }
    }, [user, authUser])

    const keepaliveTimerFiredCB = React.useCallback(() => {
        //check time to see if 5 mins or 1
        var dt = new Date()
        var ft = Math.floor(dt.getTime() / 60000) * 60000
        var ffq = ft % 300000
        if (ffq !== 0) {
            return
        }
        if (gSession) {
            findUpdateLocalUserIfNeeded();
        }
        if (gUser) {
            updUser(gUser)
        }
    }, [sess, user]);

    const [canvasClass, setCanvasClass] = React.useState("canvas_reg outlineNone")


    function updUser(u1) {
        ib.updateUser(u1).then((u) => {
            const r = u.data.updateUser
            if (!r) return
            var updTime = new Date(r.updatedAt)
            var locTime = new Date()
            var delta = (locTime - updTime) / 1000
            if (Math.abs(delta) > 3) dispatch(Actions.setDrift(delta))
        });
    }
    React.useEffect(() => {
        keepaliveTimer = window.setInterval(keepaliveTimerFiredCB,
            ib.KEEPALIVE_TIMEOUT_SECONDS * 1000);
        gShowcase = null
        ggridView.open = false
        setSession(() => { gSession = null; return null; });
        if (mylocalStorage.getItem("zoomDisabled")) {
            gZoomEnabled = false
        }
        var notif = mylocalStorage.getItem('setNotif')
        if (notif) {
            dispatch(Actions.setNotification(notif))
        }
        let sss = mylocalStorage.getItem('syncDisabled')
        if (sss) {
            dispatch(Actions.setSyncDisabled(sss))
        }
        dispatch(Actions.setChatMsg(null))
        dispatch(Actions.setBoardLock(null))
        dispatch(Actions.setPageLock(null))

        dispatch(Actions.setRichText({ open: false, object: null, cb: textEdit, loc: null }))
        gLocked = false
        if (window.location.href.includes("/export/")) gLocked = true
        gGrid = null
        let ra = Math.floor(Math.random() * epiColors.length)

        let ct = gCtx
        // ct.sidebar = document.getElementById('sidebar')
        ct.canvasContainer = document.getElementById('Cc')
        ct.message = document.getElementById('message')
        ct.colorSelect = document.getElementById('colorSelect')
        ct.uname = document.getElementById('uname')
        ct.minNav = document.getElementById('minNav')
        var col = epiColors[ra]
        var col2 = mylocalStorage.getItem('mycolor');
        if (col2) col = col2
        ct.color = col
        ct.SavedColor = col
        setPenBtnColor(col)
        // ct.colorSelect.value = ct.color
        // ct.uname.style.color = ct.color
        let icol = col
        if (mylocalStorage.getItem("inkCopy")) {
            icol = mylocalStorage.getItem("savedInk")
            if (!icol) icol = col
        }
        setInkColor(icol)
        ct.scrollLock = SCROLLMODE_DRAW
        ct.button = {}
        const button = {
            // lazy: 'button_lazy',
            draw: 'button_draw',
            scrollLock: 'button_scroll',
        }
        Object.keys(button).forEach(b => {
            ct.button[b] = document.getElementById(button[b])
        })

        ct.slider = {}
        const slider = {
            brush: 'slider_brush',
            lazy: 'slider_lazy',
            opacity: 'slider_opacity',
        }
        Object.keys(slider).forEach(s => {
            ct.slider[s] = document.getElementById(slider[s])
        })

        // Set initial value for range sliders
        if (ct.slider && ct.slider.brush && ct.slider.brush.value) {
            ct.slider.brush.value = BRUSH_RADIUS
        }
        // ct.slider.lazy.value = LAZY_RADIUS
        var opa = 100;
        var opa2 = mylocalStorage.getItem('myopacity');
        if (opa2) opa = opa2;
        ct.opacity = opa;
        ct.lineStyle = null;

        ct.canvas = {}
        ct.context = {}
        var canvas = {
            // interface: 'canvas_interface',
            drawing: 'canvas_drawing',
            // temp: 'canvas_temp',
            // others: 'canvas_others',
        }
        //  if (!mobile) canvas['grid'] = 'canvas_grid'
        paper.setup('canvas_drawing');
        ct.tempLayer = new paper.Layer();
        ct.tempLayer.visible = true
        ct.drawLayer = new paper.Layer();
        ct.drawLayer.activate();
        // var tool = new paper.Tool();

        // tool.onMouseDrag = paperMouseDrag;
        // tool.onMouseUp = paperMouseUp;
        // tool.onMouseDown = paperMouseDown;

        Object.keys(canvas).forEach(c => {
            const el = document.getElementById(canvas[c])
            ct.canvas[c] = el
            ct.context[c] = el.getContext('2d')

            if (mobile && smallScreen) {
                //not changing 
            } else {
                ct.canvas[c].minWidth = maxWidth + "px !important"
                ct.canvas[c].minHeight = maxHeight + "px !important"
            }
        })
        var mobSize = mobile && smallScreen ? "canvas_mobile" : "canvas_reg"
        if (maxWidth === smallMaxWidth) mobSize = "canvas_cb"
        if (mobSize !== "canvas_reg") {
            mobSize = mobSize + " outlineNone"
            setCanvasClass(mobSize)
        }

        ct.canvas.interface = ct.canvas.drawing
        ct.context.interface = ct.context.drawing

        ct.catenary = new Catenary()

        ct.lazy = new LazyBrush({
            radius: LAZY_RADIUS,
            enabled: true,
            initialPoint: {
                x: window.innerWidth / 2,
                y: window.innerHeight / 2
            }
        })

        ct.points = []
        ct.drawPath = new Path();

        ct.mouseHasMoved = true
        ct.valuesChanged = true
        ct.isDrawing = false
        ct.isPressing = false

        ct.points = []
        ct.mode = defaultTool
        ct.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
        ct.drawBrushRadius = BRUSH_RADIUS
        // var br = mylocalStorage.getItem("brushSize")
        // if (br) {
        //     ct.drawBrushRadius = br
        //     if (ct && ct.slider && ct.slider.brush && ct.slider.brush.value) ct.slider.brush.value = br
        //     setCurrentBrush(br)
        // }
        ct.eraseBrushRadius = 5 * ct.drawBrushRadius
        ct.brushRadius = ct.drawBrushRadius
        ct.chainLength = LAZY_RADIUS

        ct.dpi = 1
        setCtx(gCtx)
        // console.log("platform = ", window.navigator.platform);


        function rem() {
            if (keepaliveTimer) {
                window.clearInterval(keepaliveTimer);
            }
            document.onpaste = null;
            observeCanvas && observeCanvas.unobserve(document.getElementById('Cc'));
            // if(document.getElementById('sidebar')){
            //     observeSidebar.unobserve(document.getElementById('sidebar'));
            // }
        }

        const savedGridOpts = mylocalStorage.getItem("gridOptions");
        if (savedGridOpts && savedGridOpts !== undefined) {
        }
        return rem;
    }, [])

    React.useEffect(() => {
        // Determine the tourType even if we don't show
        // the tour so manual start of tour shows the right one.
        var doTour = false;
        gTeacher = teacherR

        if (sess && sess.Classroom !== null) {
            if (Boolean(teacherR)) {
                // console.log("Detected teacher")
                doTour = true;
            } else {
                // console.log("Detected student board")
            }
        } else if (sess && sess.Classroom === null) {
            // console.log("Detected collaboration board")
            doTour = true;
        } // else {
        // console.log("Not detected board type yet")
        // }
        if (isApiMode) {
            doTour = false
            mylocalStorage.setItem('tourDone', true)
        }

        if (window.screen.width < minWidthForTour) {
        }

        let tourDone = mylocalStorage.getItem('tourDone')
        if (tourDone && tourDone === "true") {
            doTour = false;
        }
        setOverlayHelpOpen(p => doTour);

    }, [sess, teacherR]);

    React.useEffect(() => {
        clearGlobal()
        gSessionID = props.match.params.boardid
        gParentSession = null
    }, [props])

    React.useEffect(() => {

        function init() {
            // Listeners for mouse events
            ctx.canvas.interface.addEventListener('mouseout', handleMouseOut)
            if (!mobile) ctx.canvas.interface.addEventListener('mousedown', handlePointerDown)
            ctx.canvas.interface.addEventListener('mouseup', handlePointerUp)
            ctx.canvas.interface.addEventListener('mousemove', pointerMove)
            ctx.canvas.interface.addEventListener('contextmenu', (e) => handleContextMenu(e))
            ctx.canvas.interface.addEventListener("mousewheel", handleMouseWheel, false);
            ctx.canvas.interface.addEventListener('pointerdown', spointerdown)


            ctx.canvas.interface.addEventListener('click', (e) => handleClick(e))
            ctx.canvas.interface.addEventListener('keyup', (e) => handleKeyUp(e))

            // ctx.canvas.interface.addEventListener('drop', (e) => handleDrop(e))
            // ctx.canvasContainer.addEventListener('paste', (e) => handleDrop(e))

            // Listeners for touch events
            ctx.canvas.interface.addEventListener('touchstart', (e) => handleTouchStart(e))
            ctx.canvas.interface.addEventListener('touchend', (e) => handleTouchEnd(e))
            ctx.canvas.interface.addEventListener('touchmove', (e) => handleTouchMove(e))

            // Listeners for click events on butons
            // ctx.button.draw.addEventListener('click', (e) => handleButtonDraw(e))
            if (mobile)
                ctx.button.scrollLock.addEventListener('click', (e) => handleButtonScroll(e))

            // ctx.colorSelect.addEventListener('input', (e) => colorChange(e))
            // ctx.uname.addEventListener('input', (e) => unameChange(e))

            // ctx.button.lazy.addEventListener('click', (e) => handleButtonLazy(e))

            // Listeners for input events on range sliders
            observeCanvas = new ResizeObserver((entries, observer) => handleCanvasResize(entries, observer))
            observeCanvas.observe(ctx.canvasContainer)

            // var options = {
            //     rootMargin: '0px',
            //     threshold: 1.0
            //   }
            // var observer = new IntersectionObserver(scrollhandler, options);
            // observer.observe(ctx.canvasContainer)
            document.querySelector('#canvas_container').addEventListener('scroll', scrollhandler);
            // observeSidebar = new ResizeObserver((entries, observer) => handleSidebarResize(entries, observer))
            // observeSidebar.observe(ctx.sidebar)

            loop()

            window.setTimeout(() => {
                const initX = window.innerWidth / 2
                const initY = window.innerHeight / 2
                ctx.lazy.update({ x: initX - (ctx.chainLength / 4), y: initY }, { both: true })
                ctx.lazy.update({ x: initX + (ctx.chainLength / 4), y: initY }, { both: false })
                ctx.mouseHasMoved = true
                ctx.valuesChanged = true
                clearCanvas()
            }, 100)
        }
        if (ctx) init()
    }, [ctx])


    function scrollhandler(e) {
        // const t = e.target
        // console.log("scroll", t.scrollTop, t.scrollHeight, t.scrollLeft, t.scrollWidth)
        sendPositionUpdate()
        // console.log("Height is ", ctx.canvasContainer.offsetHeight)
        // var ht = ctx.canvasContainer.offsetHeight + t.scrollTop
        // if (ht > t.scrollHeight * 0.9) {
        //     // console.log("hit 90", t.scrollHeight * 0.9)
        //     var height = 1.2 * t.scrollHeight
        //     setCanvasReSize(height, maxWidth)
        //     t.height = height
        //     t.style.height = height
        // }
    }
    async function sendPeers(o) {
        try {
            var msg = { msg: o, myID: myPeerID, sess: gSessionID }
            var ff = JSON.stringify(msg)
            for (var k in peers) {
                var p = peers[k]
                if (p.connection) {
                    p.connection.send(ff)
                }
            }
        } catch {
            return
        }
    }
    const col = [
        "#3da3db",
        "#707275",
        "#e8543f",
        "#3ddb88",
        "#ebb531",
    ]
    function delObject(dat) {
        if (dat.id in paperObj) {
            delete objDict[dat.id]

            if (paperObj[dat.id].obj) {

                if ((paperObj[dat.id].obj.data && paperObj[dat.id].obj.data.rectPainter))
                    delete gridPainter[paperObj[dat.id].obj.data.coord]

                if (paperObj[dat.id].obj.data.button) paperObj[dat.id].obj.data.button.remove()
                paperObj[dat.id].obj.remove()

            }
            delete paperObj[dat.id]
        }

    }

    function getPgNum(s) {
        var pgNum = null
        try {
            if (s) {
                var pgSplit = s.split("-pgNum-")
                if (pgSplit.length > 1) pgNum = pgSplit[1]
            }
        } catch {
            return null
        }
        return pgNum
    }
    function gotData(dat) {
        // console.log("gotData:", dat);
        if (ggridView.open || gShowcase) return
        paper.activate()
        const pgNum = getPgNum(gSessionID)
        for (let i = 0; i < dat.length; i++) {
            const vv = getPgNum(dat[i].SessionID)
            if (pgNum !== vv) {
                continue
            }
            ib.gotObjMonitor(dat[i])
            if (dat[i].SessionID !== gSessionID &&
                dat[i].DisableSync &&
                dat[i].DisableSync === "disabled") {
                //teacher object not meant for me
                continue;
            }
            //dispatch(Actions.addActive({ id: dat[i].CreatedBy, obj: dat[i] }))

            var idx = 0
            if (dat[i].id === myObj.id) {
                // console.log("gotData: id matches continue");
                //my object ignore
                myObj.updatedAt = dat[i].updatedAt
                continue
            }

            if (dat[i].id in objDict) {
                if (objDict[dat[i].id].mine && dat[i].objType !== "text") {
                    // console.log("gotData: id matches in map, continue");
                    //another my update
                    continue
                }
                idx = objDict[dat[i].id].lastIndex

            } else {
                objDict[dat[i].id] = { lastIndex: 0, mine: false, peerObj: [] }
            }
            if (dat[i].objType === "text") {
                drawTextRemote(dat[i])
            }
            if (dat[i].objType === "drawFree") {
                objDict[dat[i].id].obj = dat[i]
                var drawn = drawObj(dat[i], idx, col[i % col.length])
                if (drawn > 0) objDict[dat[i].id].lastIndex = drawn - 1
            }
            if (dat[i].objType === "image") {
                objDict[dat[i].id].obj = dat[i]
                drawImage(dat[i])
            }
            if (dat[i].objType === "drawPaper") {
                objDict[dat[i].id].obj = dat[i]
                drawPaper(dat[i], null)
            }
        }

    }

    function clearPaperObj() {
        paper.project.activeLayer.removeChildren();

        moveObjs = {}
        paperObj = {}

        paper.project._needsUpdate = true;
        paper.project.view.update();
    }

    function getOBJsAgain() {
        if (gSessionID) {
            objDict = {}
            setMyObj()
            clearPaperObj()
            clearWidgetCount()
            if (gParentSession && gParentSession !== gSessionID) {
                ib.listObject(gParentSession, null, gotData)
            }
            ib.listObject(gSessionID, null, gotData)
        }
    }

    function allowChange(tool) {
        var bt = boardTools && Object.keys(boardTools).length > 0 ? boardTools : gBoardTools
        var allow = true
        if (gTeacher) return allow
        if (!bt) return allow

        if (tool in bt) {
            if (bt[tool].value && bt[tool].value.length > 0)
                return false
        }
        return allow
    }

    function setStudentDraw(dat) {
        if (!dat.boardConfig) return
        const j = JSON.parse(dat.boardConfig)
        var cfgChange = false
        var newCfg = {}
        gBoardTools = {}
        if (j) {
            if (j.boardTools) {
                if (dat.id !== dat.parentBoardID) {

                }
                gBoardTools = j.boardTools
                dispatch(Actions.setBoardTools(j.boardTools))
            } else {
                dispatch(Actions.setBoardTools({}))
            }
        }
        if (j && j.studentsCannotDrawOthers) {
            if (boardConfig &&
                boardConfig.studentsCannotDrawOthers !== j.studentsCannotDrawOthers) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    studentsCannotDrawOthers: j.studentsCannotDrawOthers,
                }
                cfgChange = true
            }
        }
        if (j && j.simpleDrawingTools) {
            if (boardConfig &&
                boardConfig.simpleDrawingTools !== j.simpleDrawingTools) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    simpleDrawingTools: j.simpleDrawingTools,
                }
                cfgChange = true
            }
        }
        if (j && j.fourToolsStudent) {
            if (boardConfig &&
                boardConfig.fourToolsStudent !== j.fourToolsStudent) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    fourToolsStudent: j.fourToolsStudent,
                }
                cfgChange = true
            }
        }
        if (j && j.StudentWebcamVideo) {
            if (boardConfig &&
                boardConfig.StudentWebcamVideo !== j.StudentWebcamVideo) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    StudentWebcamVideo: j.StudentWebcamVideo,
                }
                cfgChange = true
            }
        }
        if (j && j.MultiLineText) {
            if (boardConfig &&
                boardConfig.MultiLineText !== j.MultiLineText) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    MultiLineText: j.MultiLineText,
                }
                cfgChange = true
                gEnter = j.MultiLineText
            }
        }
        if (j && j.SpeechText) {
            if (boardConfig &&
                boardConfig.SpeechText !== j.SpeechText) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    SpeechText: j.SpeechText,
                }
                cfgChange = true
                gSpeechText = j.SpeechText
            }
        }
        if (j && j.DisableImmersive) {
            if (boardConfig &&
                boardConfig.DisableImmersive !== j.DisableImmersive) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    DisableImmersive: j.DisableImmersive,
                }
                cfgChange = true
            }
        }
        if (j && j.boardAuthUsersOnly) {
            if (boardConfig &&
                boardConfig.boardAuthUsersOnly !== j.boardAuthUsersOnly) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    boardAuthUsersOnly: j.boardAuthUsersOnly,
                }
                cfgChange = true
            }
        }
        if (j && j.defaultTool) {
            if (boardConfig &&
                boardConfig.defaultTool !== j.defaultTool) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    defaultTool: j.defaultTool,
                }
                cfgChange = true
            }
        }
        if (j && j.boardResolution) {
            if (boardConfig &&
                boardConfig.boardResolution !== j.boardResolution) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    boardResolution: j.boardResolution,
                }
                cfgChange = true
                setResolution(j.boardResolution)
            }
        }
        if (cfgChange) {
            dispatch(Actions.setBoardConfig(newCfg))
        }
    }

    function setResolution(res) {
        if (gResolution === res) {
            return
        }
        if (!ctx) return
        gResolution = res
        setCanvasClass("canvas_cb_" + res + " outlineNone")
        var r = ib.boardResoltionType[res]
        ctx.canvas.drawing.width = r.w
        ctx.canvas.drawing.height = r.h
        maxHeight = r.h
        maxWidth = r.w
        var col = inkColor ? inkColor : "#3174F5"
        ctx.canvas.drawing.style.border = "1px dashed  " + col
    }

    React.useEffect(() => {
        var peer = null
        var needToUpdateSessionUser = false;
        var s1, s2, s3, s4, s5, s12, s42, s44, s45, s54, s55;

        function getSession(dat) {
            setSession(() => { gSession = dat; return dat; });
            if (dat.boardConfig) {
                var refresh = false
                const j = JSON.parse(dat.boardConfig)
                if (j && j.boardAuthUsersOnly) {
                    if (!authUser) refresh = true
                }
                setStudentDraw(dat)
                if (refresh) window.location.reload();
            }
        }

        function disconnectPeer(peer) {
            var p = peers[peer]
            if (!p) return

            if (p.oldmouse) {
                p.oldmouse.remove()
            }
            delete peers[peer]
        }
        function drawMouse(mm) {
            if (!defaultTimer) {
                defaultTimer = window.setInterval(clearRectAll, 5000);
            }
            const m = mm.msg
            try {
                m.x = parseInt(m.x)
                m.y = parseInt(m.y)
                m.brush = parseInt(m.brush)
            } catch {
                return
            }
            if (mm.myID === myPeerID) return
            var p = peers[mm.myID]
            if (!p) {
                return
            }

            if (p && p.oldmouse) {
                // var mult = p.oldmouse.brush
                // if (mult < 4) mult = 4
                // const oldR = mult * 2
                // ct.beginPath();
                // if ('name' in p.oldmouse) {
                //     var text = ct.measureText(p.oldmouse.name);
                //     var w = text.width
                // } else {
                //     w = 100
                // }
                // ct.clearRect(p.oldmouse.x - oldR, p.oldmouse.y - oldR, (2 * oldR) + w + 10,
                //     (2 * oldR));
                // ct.closePath();
                var ff = p.oldmouse.bounds

                p.oldmouse.position.x = m.x + (ff.width / 2)
                p.oldmouse.position.y = m.y
                p.oldmouse.fillColor = m.color
            } else {
                if (ctx.tempLayer) {
                    const RADIUS = 5
                    ctx.tempLayer.activate()
                    var pt = new paper.Point(m.x, m.y)
                    var myCircle = new Path.Circle(pt, RADIUS);
                    myCircle.fillColor = m.color

                    var text = new paper.PointText(new paper.Point(m.x + RADIUS + 5, m.y + RADIUS));
                    text.content = " ";
                    text.style = {
                        fontFamily: 'roboto',
                        fontSize: 12,
                        fillColor: m.color,
                        justification: 'left'
                    };
                    var group = new paper.Group([myCircle, text])
                    ctx.drawLayer.activate()

                    p.oldmouse = group
                }
            }

            // ct.beginPath()
            // ct.fillStyle = m.color
            // ct.arc(m.x, m.y, m.brush * 2, 0, Math.PI * 2, true)
            // ct.fill()
            // if ('name' in m) {
            //     ct.font = '10px roboto';
            //     ct.fillText(m.name, m.x + (m.brush * 2) + 5, m.y + (m.brush));
            // }
            // p.oldmouse = m
        }
        function drawPObj(mm) {
            const m = mm.msg
            if (m.id in objDict) {

            } else {
                objDict[m.id] = { lastIndex: 0, mine: false, peerObj: [] }
            }
            var ct = ctx.context.drawing
            ct.lineWidth = m.brush * 2
            ct.lineJoin = 'round'
            ct.lineCap = 'round'
            ct.strokeStyle = m.color
            ct.dashArray = m.dashArray
            const p2 = m.p2
            const p1 = m.p1
            if (!objDict[m.id].peerObj) return
            objDict[m.id].peerObj.push({ x: p2.x, y: p2.y })
            objDict[m.id].peerdat = m
            ct.moveTo(p2.x, p2.y)
            ct.beginPath()
            var midPoint = midPointBtw(p1, p2)
            //     console.log("Doing curve", p1.x, p1.y, midPoint.x, midPoint.y)
            ct.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
            ct.stroke()
            drawMouse(mm)
        }

        function gotDataCommon(d) {
            var msg = JSON.parse(d)
            if (msg && msg.sess) {
                var forMe = false
                if (sess.parentBoardID !== sess.id && msg.sess === sess.parentBoardID) forMe = true
                if (msg.sess === gSessionID) forMe = true
                if (!forMe) {
                    return
                }
            }
            if (msg && 'msg' in msg && msg.msg.type === "mouseMove") drawMouse(msg)
            if (msg && 'msg' in msg && msg.msg.type === "scrollMove") drawScrollMove(msg)
            if (msg && 'msg' in msg && msg.msg.type === "snakeMove") {
                drawMouse(msg)
            }
            if (msg && 'msg' in msg && msg.msg.type === "mouseDraw") drawPObj(msg)
            if (msg && 'msg' in msg && msg.msg.type === "logMessage") console.log(msg.msg.msg)

        }
        function gotData22(data) {
            gotDataCommon(data)
        }

        function newPeer2(d) {
            d.on('data', (data) => {
                gotDataCommon(data)
            });
            d.on('call', answerCall)
            d.on('disconnected', function () { disconnectPeer(d.peer) })
            d.on('close', function () {
                disconnectPeer(d.peer)
            })

            var xx = JSON.parse(JSON.stringify(defaultPeer))
            xx.id = d.peer
            xx.connection = d
            peers[d.peer] = xx
        }

        function gotUser(dat) {

            async function processPeer(p) {
                if (!peer) {
                    return
                }
                try {
                    var d = JSON.parse(JSON.stringify(defaultPeer))
                    d.id = p
                    d.connection = peer.connect(p)
                    if (!d.connection) return
                    d.connection.serialization = 'json';
                    d.connection.on('open', function () {
                        d.connection.on('data', gotData22)
                        d.connection.on('disconnected', function () { disconnectPeer(d.connection.peer) })
                        d.connection.on('close', function () { disconnectPeer(d.connection.peer) })
                    })
                    peers[p] = d
                } catch (err) {
                    console.log("error in connect", err)
                }
            }
            for (var u in dat) {
                if (!dat[u]) continue
                dispatch(Actions.addParticipant(dat[u]))
                const findID = dat[u].id
                let index = gUsers.findIndex(x => x.id === findID);
                if (index === -1) gUsers.push(dat[u])
                else {
                    gUsers.splice(index, 1)
                    gUsers.push(dat[u])
                }
                try {
                    var x = JSON.parse(dat[u].content)
                } catch {
                    continue
                }
                if ('peerID' in x) {
                    if (myPeerID === x['peerID']) continue
                    if (!(x['peerID'] in peers))
                        processPeer(x['peerID'])
                }
            }
        }
        function gotLocalUser(dat) {
            for (var i = 0; i < dat.length; i++) {
                var d = dat[i]
                if (d.id in savedLocalUsers) {
                    if (d.CurrentUser && d.CurrentUser.content) {
                        gotUser([d.CurrentUser])
                    }
                } else {
                    //new User 
                    gotUser([d.CurrentUser])
                }
                savedLocalUsers[d.id] = JSON.parse(JSON.stringify(d))
            }
        }
        if (sess && ctx) {
            if (!noUser) {
                if ((user && user.SessionID !== sess.id) || !user) {
                    var peerId = {};
                    var name = mylocalStorage.getItem('myname');
                    var generatedName = false;
                    if (name && name !== "") {
                        let spName = name.split(" ");

                        if (spName.length === 2) {
                            if (verbsMap[spName[0]] && profilesMap[spName[1]]) {
                                generatedName = true;
                            }
                        }

                        myName = name
                    } else {
                        let ra1 = Math.floor(Math.random() * profiles.length)
                        let ra2 = Math.floor(Math.random() * verbs.length)
                        myName = verbs[ra2] + " " + profiles[ra1]
                        generatedName = true;
                    }
                    var uid = null
                    var avatar = null;
                    if (authUser && authUser.username) {
                        uid = authUser.username
                        avatar = authUser.avatar;
                        peerId['email'] = authUser.attributes.email

                        if ((generatedName && authUser.attributes.name) || (name === "undefined")) {
                            myName = ((!authUser.attributes.name) || (authUser.attributes.name === "undefined")) ? authUser.attributes.email : authUser.attributes.name;
                            mylocalStorage.setItem('myname', myName);
                        }
                    }
                    var redir = mylocalStorage.getItem('redir');
                    var isStu = mylocalStorage.getItem('IsStudent');
                    if (!isStu && isApiMode) {
                        mylocalStorage.setItem('IsStudent', true);
                        isStu = true
                    }
                    if (((isStu === undefined) || (isStu === null)) &&
                        !(redir && redir === sess.Classroom) &&
                        (window.location.href.indexOf('/board') > 0)) {
                        aniMess("Redirect to join board")
                        const JOINURL = window.location.href.replace("/board", "/join")
                        mylocalStorage.setItem('redir', sess.Classroom)
                        window.location.href = JOINURL
                        setTimeout(function () {
                            window.location.reload()
                        }, 300);
                        return;
                    }
                    var luid = findcreatelocalUser()
                    peerId['localID'] = luid

                    // ctx.uname.value = myName

                    const PeerServer = ["terminal.epiphani.ai", "peer2.epiphani.ai"]
                    function findUpdateSessionUser() {
                        return new Promise((resolve, reject) => {
                            function createPeer(id, content) {
                                try {
                                    var UU = sess.Classroom ? sess.Classroom : sess.parentID
                                    var ff = Number.parseInt(UU[UU.length - 1], 16) % 2
                                    if (ff > PeerServer.length) {
                                        ff = 0
                                    }
                                    var server = PeerServer[ff]
                                    myPeerID = id;
                                    peer = new Peer(id, {
                                        port: 9000,
                                        path: '/colab-xxx-1121',
                                        debug: 2,
                                        host: server,
                                        secure: 'true'
                                    });
                                    peer.on('connection', newPeer2)
                                    peer.on('error', function (error) {
                                        console.log("Connection error", id, error);
                                        // peer = null
                                    });
                                    peer.on('call', answerCall)
                                    content['peerID'] = id;
                                    content['color'] = ctx.color;
                                    gPeer = peer
                                    var ss2 = mylocalStorage.getItem('myid');
                                    if (ss2) {
                                        content['useridsaved'] = ss2
                                    }
                                } catch (e) {
                                    console.error("ERROR PEER", id, e)
                                    peer = null
                                }
                            }

                            function findUserHandler(userList, content) {
                                var readContent;

                                if (!userList || userList.length === 0) {
                                    // Did not find any user
                                    var newId = uuid();
                                    createPeer(newId, content);
                                    ib.createUser(myName, sess.id, JSON.stringify(content), uid, avatar, sess.ttl).then(res => {
                                        setUser(res.data.createUser)
                                        gUser = res.data.createUser
                                        findUpdateLocalUserIfNeeded()
                                        resolve();
                                    })
                                } else if (userList.length === 1) {
                                    // Found a match
                                    readContent = JSON.parse(userList[0].content);
                                    updateColor(readContent.color, false, false);
                                    createPeer(readContent.peerID, readContent);
                                    setUser(userList[0]);
                                    gUser = userList[0];
                                    if (generatedName) {
                                        // ctx.uname.value = userList[0].name;
                                        myName = userList[0].name;
                                    }
                                    updUser(userList[0]);
                                    findUpdateLocalUserIfNeeded();
                                    resolve();
                                } else {
                                    // Found more than 1 match, pick the first one for now.
                                    readContent = JSON.parse(userList[0].content);
                                    updateColor(readContent.color, false, false);
                                    createPeer(readContent.peerID, readContent);
                                    setUser(userList[0]);
                                    gUser = userList[0];
                                    if (generatedName) {
                                        // ctx.uname.value = userList[0].name;
                                        myName = userList[0].name;
                                    }
                                    updUser(userList[0]);
                                    findUpdateLocalUserIfNeeded();
                                    resolve();
                                }
                            }
                            if (sess && sess.Users && sess.Users.items) {
                                var found = null
                                if (sess.CreatorLocalID === uid && sess.parentID === sess.Classroom) {
                                    dispatch(Actions.setTeacher(1))
                                }
                                for (let i = 0; i < sess.Users.items.length; i++) {
                                    var uuu = sess.Users.items[i]
                                    if (uuu.userProfileID === uid) {
                                        found = uuu; break;
                                    }
                                    if (peerId['email'] && uuu.content.includes(`"email":"${peerId['email']}"`)) {
                                        found = uuu; break;
                                    }
                                    if (peerId['localID'] && uuu.content.includes(`"localID":"${peerId['localID']}"`)) {
                                        found = uuu; break;
                                    }
                                }
                                if (found) {
                                    //got users already no need to search 
                                    findUserHandler([uuu], peerId)
                                    return
                                }
                            }
                            ib.findUser(sess.id, peerId, uid, findUserHandler)
                        })
                    }
                    needToUpdateSessionUser = true;
                    findUpdateSessionUser().then((result) => {
                        doAPILoads()
                        doSubscriptions();
                    })
                }
            }

            function doSubscriptions() {
                function delSession(ses) {
                    // props.history.push("/")
                }

                function listUserCB(dat) {
                    for (var u in dat) {
                        dispatch(Actions.addParticipant(dat[u]))
                        const findID = dat[u].id
                        let index = gUsers.findIndex(x => x.id === findID);
                        if (index === -1) gUsers.push(dat[u])
                    }
                }
                dispatch(Actions.flushParticipants())
                ib.listUser(sess.id, null, listUserCB)
                var teacher = 1
                if (sess.Classroom) {
                    ib.getClassroom(sess.Classroom, function (r) {
                        if (r) {
                            var luid = mylocalStorage.getItem('mystoreID');
                            if (luid !== r.TeacherID) {
                                if (r.TeacherList &&
                                    r.TeacherList.indexOf(luid) !== -1) {
                                    // if this is another teacher
                                    setUserRole('coTeacher')
                                    dispatch(Actions.setTeacher(teacher))
                                    doClassSubs(true)
                                } else {
                                    dispatch(Actions.setTeacher(0))
                                    doClassSubs(false)
                                }
                                return
                            } else {
                                setUserRole('teacher')
                            }
                        }
                        dispatch(Actions.setTeacher(teacher))
                        doClassSubs(true)
                    })
                } else {
                    dispatch(Actions.setBoardType({ name: 'Single Board' }))
                    dispatch(Actions.setTeacher(teacher))
                    setUserRole('collaborate')
                }
                function doClassSubs(teacher) {
                    function classObjSubCB(objSubs) {
                        [s12, s42] = objSubs
                    }

                    function localUsersSubCB(localUsersSubs) {
                        [s44, s45] = localUsersSubs;
                    }

                    if (sess.parentBoardID !== sess.id) {
                        //subscribe to the parents objects too
                        ib.AddSubMonitor(sess.parentBoardID, disconCb);
                        ib.ObjectSubscribe({
                            "sessionID": sess.parentBoardID,
                            "cb": gotData,
                            "delCB": delObject,
                            "subCB": classObjSubCB,
                            "doList": true
                        });
                        dispatch(Actions.setBoardType({ name: 'Student Board' }))
                    } else {
                        // this is the main board
                        if (sess.CreatorLocalID === findcreatelocalUser() && sess.Classroom) {
                            dispatch(Actions.setBoardType({ name: 'Instructor Board' }))
                        } else {
                            if (sess.Classroom) {
                                if (teacher) {
                                    // secondary teacher on teacher board
                                    savedLocalUsers = {};
                                    ib.SubscribeLocalUsersByClass({
                                        "ClassroomID": sess.Classroom,
                                        "cb": gotLocalUser,
                                        "delCB": delLocalUser,
                                        "doList": true,
                                        "subCB": localUsersSubCB
                                    })
                                    dispatch(Actions.setBoardType({ name: 'Instructor Board [T]' }))
                                } else {
                                    var redir = mylocalStorage.getItem('redir');
                                    if (redir && redir === sess.Classroom) {
                                        aniMess("Joining Teacher Board, click invite link to go to student board")
                                        dispatch(Actions.setBoardType({ name: 'Instructor Board [s]' }))
                                    } else {
                                        aniMess("Redirect to student board")
                                        const JOINURL = window.location.href.replace("/board", "/join")
                                        mylocalStorage.setItem('redir', sess.Classroom)
                                        window.location.href = JOINURL
                                        setUserRole('student')
                                    }
                                }
                            }
                        }
                    }
                }
                function objSubCB(objSubs) {
                    [s1, s4] = objSubs;
                }
                function userSubCB(userSub) {
                    s3 = userSub;
                }
                function sessSubCB(sessSubs) {
                    [s2, s5] = sessSubs;
                }
                gUsers = []
                gTextBox = {}
                // removeAllButtons()
                ib.AddSubMonitor(sess.id, disconCb);
                // lat.getLatencyData()
                // ib.getService() 
                // console.log("SESS IS", sess) 
                ib.ObjectSubscribe({
                    "sessionID": sess.id,
                    "cb": gotData,
                    "delCB": delObject,
                    "subCB": objSubCB,
                    "doList": true
                });
                ib.SessionSubscribe({
                    "sessionID": sess.id,
                    "cb": getSession,
                    "delcb": delSession,
                    "subCB": sessSubCB
                });
                // UserSubscribe call here already had `true` for noList,
                // it was inverted for doList
                ib.UserSubscribe({
                    "sessionID": sess.id,
                    "cb": gotUser,
                    "doList": false,
                    "subCB": userSubCB
                });
            }
            function disconCb() {
                aniMess("Network Error. Please refresh your browser to reconnect")
                // window.location.reload()
            }
            function delLocalUser(u) {
                window.location.reload()
            }
            if (!needToUpdateSessionUser) {
                doSubscriptions();
            }
        }
        return () => {
            ib.ResetSubsMonitor()
            if (s1) s1.unsubscribe()
            if (s2) s2.unsubscribe()
            if (s3) s3.unsubscribe()
            if (s4) s4.unsubscribe()
            if (s5) s5.unsubscribe()
            if (s12) s12.unsubscribe()
            if (s42) s42.unsubscribe()
            if (s44) s44.unsubscribe()
            if (s45) s45.unsubscribe()
            if (s54) s54.unsubscribe()
            if (s55) s55.unsubscribe()
        }
    }, [sess, ctx])
    const getQueryStringParams = query => {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
                )
            : {}
    };
    const isApiMode = window.location.href.includes("/boards/") || window.location.href.includes("/export/");
    async function doAPILoads() {
        if (isApiMode) {
            mylocalStorage.setItem("dblClickDisable", "yes")
            paper.view.viewSize = [maxWidth, maxHeight]

            let q = getQueryStringParams(props.location.search)
            if (q.scrollFollow) {
                gScrollFollow = { x: 0, y: 0, zoom: 0 }
            }
            if (q.bg) {
                if (q.bg in gLoadMaps) {
                    return
                }
                if (q.bgh) {
                    ctx.canvas.drawing.style.height = q.bgh + "px"
                    ctx.canvas.drawing.height = q.bgh
                    maxHeight = parseInt(q.bgh)
                    paper.view.viewSize = [maxWidth, maxHeight]
                }
                gLoadMaps[q.bg] = "loading"
                let ii = await ib.getImage(q.bg)
                let args = { source: ii.body, crossOrigin: 'anonymous' }
                var raster = new paper.Raster(args);
                let x = parseInt(q.xAxis) + (q.bgw / 2)
                let y = parseInt(q.yAxis) + (q.bgh / 2)
                raster.position = new paper.Point(x, y)

                paper.project.activeLayer.insertChild(0, raster);
                raster.onLoad = function () {
                    if (!q.xAxis) {
                        raster.position = new paper.Point(raster.size._width / 2 + 100,
                            raster.size._height / 2 + 100)
                    }
                    if (q.bgw && q.bgh) {
                        let w = q.bgw / raster.width
                        let h = q.bgh / raster.height
                        raster.scale(w, h)
                        if (w !== 1 || h !== 1) {
                            let x = parseInt(q.xAxis) + (q.bgw / 2)
                            let y = parseInt(q.yAxis) + (q.bgh / 2)
                            raster.position = new paper.Point(x, y)

                        }
                    }
                    doneLoading()
                    if (apiBoardCreated) {
                        // let svg = raster.exportSVG({ asString: true })
                        // let file = new File([svg], "svg.svg", { type: "text/plain"})
                        // let bid = window.location.href.split("/boards/")[1]
                        // bid = bid.split("?")[0]
                        // iu.wroteFile(file, bid, wroteFILE) 
                        // function wroteFILE(url) {
                        //     console.log("URL IS ", url) 
                        // }
                    }
                }
            } else {
                doneLoading()
            }


            async function doneLoading() {
                if (!window.location.href.includes("/export/")) return
                await new Promise(r => setTimeout(r, 800));
                let svg = paper.project.exportSVG({ asString: true })
                // document.documentElement.dangerouslySetInnerHTML = svg;
                document.querySelector('html').innerHTML = svg;
                // drawInlineSVG(svg)
                // document.open();
                // document.write(svg);  // htmlCode is the variable you called newDocument
                // document.close();

                // document.body.parentElement.innerHTML = 
                // var fileName = "custom.svg"
                // var url = "data:image/svg+xml;utf8," + encodeURIComponent(paper.project.exportSVG({ asString: true }));
                // var link = document.createElement("a");
                // link.download = fileName;
                // link.href = url;
                // link.click();
            }
        }
    }
    React.useEffect(() => {
        if ('params' in props.match && 'boardid' in props.match.params) {
            if (ctx && ctx.context) clearCanvas()
            ib.getSession(props.match.params.boardid).then((res) => {
                session = res.data.getSession;
                if (!session) {
                    //node deleted
                    if (isApiMode) {
                        let bid = props.match.params.boardid.split("?")[0]
                        apiBoardCreated = true
                        ib.createSession(bid, "unsaved", "somecontent", bid, 1, null, null, null).then((res2) => {
                            session = res2.data.createSession
                            gSessionID = session.id
                            gParentSession = session.parentBoardID
                            setMyObj()
                            setSession(() => { gSession = session; return session; });
                        })
                    } else {
                        ib.createSessionNotFound(props.match.params.boardid).then((res2) => {
                            session = res2.data.createSession
                            gSessionID = session.id
                            gParentSession = session.parentBoardID
                            setMyObj()
                            setSession(() => { gSession = session; return session; });
                            //props.history.push("/board/"+props.match.params.boardid)
                        }).catch((e) => {

                        })
                    }
                    return
                }
                // Confirm there is a joinCode, if not create one it may be
                // an old board being reused.
                if (!session.joinCode || session.joinCode === "") {
                    session.joinCode = ib.genJoinCode();
                    ib.updateSession({ 'id': session.id, 'joinCode': session.joinCode });
                }
                setSession(() => { gSession = session; return session; });
                gSessionID = session.id
                gParentSession = session.parentBoardID
                setStudentDraw(session)
                setMyObj()
            })
        } else {

            //create new board
            ib.createSession("unsaved", "somecontent").then((res) => {
                console.log("created", res)
            })
        }
    }, [props.match])
    const [tabValue, setTabValue] = React.useState({
        shown: 0,
        display0: "block",
        display1: "none",
    });
    React.useEffect(() => {
        function handleChangeTab(newtab) {
            setTabValue({
                shown: newtab,
                display0: newtab === 0 ? "block" : "none",
                display1: newtab === 1 ? "block" : "none",
            });
        }
        handleChangeTab(tab.selected)
    }, [tab])


    function getTouchPos(e) {
        var bcr = e.target.getBoundingClientRect();
        if (!e || !e.changedTouches) return [0, 0]
        var x = e.changedTouches[0].clientX - bcr.x;
        var y = e.changedTouches[0].clientY - bcr.y;
        var b = changeXYZoom(x, y)
        x = b.x
        y = b.y
        return [x, y]
    }

    function getTouchPosAct(e) {
        var bcr = e.target.getBoundingClientRect();
        if (!e || !e.changedTouches) return [0, 0]
        var x = e.changedTouches[0].clientX - bcr.x;
        var y = e.changedTouches[0].clientY - bcr.y;
        return [x, y]
    }

    const state = {
        mapbox: null,
        panStart: { x: 0, y: 0 }
    }

    function mtStart(event) {
        event.stopImmediatePropagation()
        event.preventDefault()

        let x = 0
        let y = 0

        for (let touch of Array.from(event.touches)) {
            x += touch.screenX
            y += touch.screenY
        }

        state.panStart.x = x / event.touches.length
        state.panStart.y = y / event.touches.length
    }

    function mtMove(event) {
        event.stopImmediatePropagation()
        event.preventDefault()

        let x = 0
        let y = 0
        if (event.touches.length === 2) {
            // Calculate the distance between the two pointers
            var t1 = event.touches[0]
            var t2 = event.touches[1]

            var vx = Math.abs(t1.screenX - t2.screenX)
            var vy = Math.abs(t1.screenY - t2.screenY)
            var lx = t1.screenX > t2.screenX ? t2.screenX : t1.screenX;
            var ly = t1.screenY > t2.screenY ? t2.screenY : t1.screenY;

            let area = vx * vy
            var change = false
            if (prevDiff > 0) {
                var d = Math.abs(prevDiff - area)
                if (d > 200 && gZoomEnabled) {
                    if (area > prevDiff) {
                        // The distance between the two pointers has increased
                        // console.log("Pinch moving OUT -> Zoom in", event);
                        // event.target.style.background = "pink";

                        handleTouchZoom(lx + vx / 2, ly + vy / 2, -1, area / prevDiff)
                        change = true
                    }
                    if (area < prevDiff) {
                        // The distance between the two pointers has decreased
                        // console.log("Pinch moving IN -> Zoom out", event);
                        // event.target.style.background = "lightblue";
                        handleTouchZoom(lx + vx / 2, ly + vy / 2, 1, area / prevDiff)
                        change = true
                    }
                }
            }

            // Cache the distance for the next move event
            prevDiff = area;
        }
        if (change) return
        for (let touch of Array.from(event.touches)) {
            x += touch.screenX
            y += touch.screenY
        }

        const movex = (x / event.touches.length) - state.panStart.x
        const movey = (y / event.touches.length) - state.panStart.y

        state.panStart.x = x / event.touches.length
        state.panStart.y = y / event.touches.length

        ctx.canvasContainer.scroll(ctx.canvasContainer.scrollLeft - movex,
            ctx.canvasContainer.scrollTop - movey)

        // console.log("MOVE SCROLL", state.panStart.x, state.panStart.y)
        // state.mapbox.panBy(
        //   [
        //     (movex * 1) / -1,
        //     (movey * 1) / -1
        //   ],
        //   { animate: false }
        // )
    }
    function handleTouchStart(e) {
        handleBlur(e)
        evCache.push(e);
        if (e.touches && e.touches.length === 2) {
            return mtStart(e)
        }
        // ctx.message.innerHTML = "touches is "+touches.length
        if (evCache.length >= 2) {
            // sendMobile(["Multitouch ignore 2  ", JSON.stringify(evCache)])
            return
        }
        if (ctx.scrollLock === SCROLLMODE_DRAW) {
            // e.preventDefault() remove this cause the link window.open doesnt work
            //single touch
            const [x, y] = getTouchPos(e)
            // sendMobile(["Mobile Event", JSON.stringify([x,y])])
            ctx.lazy.update({ x: x, y: y }, { both: true })
            handlePointerDown(e)

            ctx.mouseHasMoved = true
        }
    }


    function handleTouchMove(e) {
        // var touches = e.changedTouches;

        // Find this event in the cache and update its record with this event
        for (var i = 0; i < evCache.length; i++) {
            if (e.pointerId === evCache[i].pointerId) {
                evCache[i] = e;
                break;
            }
        }
        if (e.touches && e.touches.length === 2) {
            return mtMove(e)
        }
        //https://github.com/mapbox/mapbox-gl-js/issues/2618
        if (evCache.length >= 2) {
            // sendMobile(["Multitouch ignore  ", JSON.stringify(evCache)])
            return
        }
        if (ctx.scrollLock === SCROLLMODE_DRAW) {

            e.preventDefault()
            const [x, y] = getTouchPos(e)

            // const tract = {
            //     down: ctx.isPressing,
            //     drawing: ctx.isDrawing,
            //     x: x,
            //     y: y
            // }
            // sendMobile(["Mobile Move ", JSON.stringify(tract)])

            // ctx.message.innerHTML = "touches is "+touches.length
            handlePointerMove(x, y, e)
        }
    }
    function checkDoubleTap(event) {
        var currentTime = new Date().getTime();
        var tapLength = currentTime - lastTap;
        clearTimeout(dtTimeout);
        if (tapLength < 500 && tapLength > 0) {
            lastTap = 0
            event.preventDefault();
        } else {
            dtTimeout = setTimeout(function () {
                clearTimeout(dtTimeout);
            }, 500);
        }
        lastTap = currentTime;
    }
    function handleTouchEnd(e) {
        evCache = []
        prevDiff = -1;
        if (ctx.mode === 'draw') {
        }
        if (ctx.mode === "text") {
            return handleClick(e)
        }
        if (ctx.mode === "math") {
            return handleClick(e);
        }
        if (ctx.mode === "richText") {
            return handleClick(e)
        }

        if (ctx.mode === "line") {
            startLine(e)
            return
        }
        if (ctx.mode === "rect") return startRect(e)
        if (ctx.mode === "circle") return startCircle(e)
        if (ctx.mode === "youtube") return handleClick(e)
        if (ctx.mode === "website") return handleClick(e)
        if (ctx.mode === "breakApart") return handleClick(e)

        if (hasInput) {
            return handleBlur(e)
        }
        checkDoubleTap(e)

        handlePointerUp(e)
        const brush = getBrushfromLazy()
        ctx.lazy.update({ x: brush.x, y: brush.y }, { both: true })
        ctx.mouseHasMoved = true
    }
    function handleContextMenu(e) {
        e.preventDefault()
    }

    function updateColor(newColor, userset, force) {
        var ctxUse = ctx ? ctx : gCtx
        if (!ctxUse) return
        if (!force && !allowChange("brushColor")) return

        var luColor = mylocalStorage.getItem('mycolor');
        if (userset) {
            mylocalStorage.setItem('mycolor', newColor)
            luColor = newColor
        }
        if (!userset && newColor === "#ffffff") return
        if (luColor) newColor = luColor
        var ll = mylocalStorage.getItem("inkCopy")
        if (!ll) setInkColorDebounced(newColor);
        ctxUse.color = newColor
        ctxUse.SavedColor = newColor
        // ctxUse.colorSelect.value = newColor
        // ctxUse.uname.style.color = newColor
    }


    function handleSimpleColor(col) {
        updateColor(col, true, false);
    }


    function ZoomInApiMode() {
        ctx.mode = 'zoomIn'
        ctx.canvas.interface.style.cursor = "zoom-in"
    }

    function PanMode() {
        ctx.mode = 'panMode'
        ctx.canvas.interface.style.cursor = "move"
    }
    function handleButtonScroll(e) {
        e.preventDefault()
        ctx.scrollLock = !ctx.scrollLock
    }
    function handleLine(e) {
        if (e === 'Line') {
            mylocalStorage.setItem('lineMode', e)
        } else {
            mylocalStorage.setItem('lineMode', 'normal')
        }
        aniMess('Click to start drawing a line, select brush to get out of line mode')
        myLineStart = { obj: null, start: null }
        ctx.mode = "line"
        ctx.color = ctx.SavedColor
        ctx.brushRadius = ctx.drawBrushRadius
        if (ctx && ctx.slider && ctx.slider.brush && ctx.slider.brush.value) ctx.slider.brush.value = ctx.brushRadius
        seteraseMode(false)
        setdrawMode({
            ...drawMode,
            name: ctx.mode
        })
        ctx.canvas.interface.style.cursor = "crosshair"
    }


    function handleText(e) {
        handleEscape()
        typeHelp()

        ctx.canvas.interface.style.cursor = "text"
        ctx.mode = "text"
    }

    function handleRect(e) {
        if (e === 'Square') {
            mylocalStorage.setItem('rectMode', 'Square')
        } else {
            mylocalStorage.setItem('rectMode', 'normal')
        }
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "rect"
        ctx.subMode = "none"
    }

    function handleCircle(e) {
        if (e === 'Circle') {
            mylocalStorage.setItem('circleMode', 'Circle')
        } else {
            mylocalStorage.setItem('circleMode', 'normal')
        }
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "circle"
    }

    function handleButtonDraw(e, f) {
        if (e === 'Draw') {
            ctx.mode = "draw"
            ctx.canvas.interface.style.cursor = "auto"
            setdrawMode({ name: 'draw' })
            if (f === undefined) {
                mylocalStorage.setItem('SavedColor', ctx.SavedColor)
                ctx.color = ctx.SavedColor
            } else {
                let SavedColor = mylocalStorage.getItem('SavedColor')
                let clr = SavedColor ? SavedColor : ctx.SavedColor
                ctx.color = f ? f : clr
            }
            ctx.brushRadius = ctx.drawBrushRadius
            if (ctx.slider && ctx.slider.brush && ctx.slider.brush.value) ctx.slider.brush.value = ctx.brushRadius
            seteraseMode(false)
        }
        if (e === 'Erase') {
            //currently drawing. button says erase
            // ctx.color = styleVariables.bgColor
            // ctx.brushRadius = ctx.eraseBrushRadius
            if (ctx.slider && ctx.slider.brush && ctx.slider.brush.value) ctx.slider.brush.value = ctx.brushRadius
            ctx.canvas.interface.style.cursor = "cell"

            ctx.mode = "erase"
            setdrawMode({ name: 'draw' })
            seteraseMode(true)
        }
    }
    function aniMess(mess) {
        if (aniMessage) return
        aniMessage = true
    }


    function handleCanvasResize(entries, observer) {
        ctx.dpi = 1//window.devicePixelRatio
        for (const entry of entries) {
            var { width, height } = entry.contentRect
            width = Math.max(width, maxWidth)
            height = Math.max(height, maxHeight)
            setCanvasSize(ctx.canvas.drawing, width, height, 1)
            // setCanvasSize(ctx.canvas.others, width, height, 1)

            // if (!mobile) setCanvasSize(ctx.canvas.grid, width, height, 2)
            // if (!mobile && drawGridCfg && drawGridCfg === "grid") drawGrid(ctx.context.grid)
            // if (!mobile && drawGridCfg && drawGridCfg === "music") drawMusicGrid(ctx.context.grid)

            loop({ once: true })
        }
        gLoadMaps = {}
        doAPILoads()
        getOBJsAgain()
    }
 
    const dashArray = [10, 6]
    function setCanvasSize(canvas, width, height, maxDpi = 4) {
        if (maxWidth !== 2000) return
        let dpi = ctx.dpi
        // reduce canvas size for hidpi desktop screens
        if (window.innerWidth > 1024) {
            dpi = Math.min(ctx.dpi, maxDpi)
        }
        canvas.width = width * dpi

        canvas.height = height * dpi
        canvas.style.width = width
        canvas.style.height = height
        canvas.getContext('2d').scale(dpi, dpi)
    }
    function clearSelect() {
        if (selectedObj) {
            document.removeEventListener('contextmenu', handleContextMenu)

            clearAllSelected()
            selectedObj = null
        }
    }

    function findSelectedKey() {
        var kobjs = Object.keys(paperObj)
        var foundKey = null
        kobjs.forEach((key) => {
            var obj = paperObj[key]
            if (selectedObj.id === obj.obj.id) {
                foundKey = key
            }
        })
        return foundKey
    }

    function findSelectedGQL(s = null) {
        if (!s) s = selectedObj
        var kobjs = Object.keys(paperObj)
        var foundKey = null
        kobjs.forEach((key) => {
            var obj = paperObj[key]
            if (s.id === obj.obj.id) {
                foundKey = obj.gqlObj
            }
        })
        return foundKey
    }

    function getPaperPoint(e) {
        var pt
        if (mobile) {
            const [x, y] = getTouchPos(e)
            pt = new paper.Point(x, y)
        } else {
            let b = getOffsetZoom(e)
            pt = new paper.Point(b.x, b.y)
        }
        return pt
    }
    const [colorPicker, setColorPicker] = React.useState({ pickerOpen: false, cb: null })

    function handleColorPicker() {
        setColorPicker({ pickerOpen: true, cb: done })
        function done(e) {
            if (e.color) {
                updateColor(e.color, true, false);
                setPenBtnColor(e.color)
            }
        }
    }


    function handleEditText(e, foundKey) {
        inValue = { text: selectedObj._content, size: selectedObj.data.textBoxSize }
        if (selectedObj.data.fonts) {
            const f = selectedObj.data.fonts
            inValue['font'] = f.font
            inValue['color'] = f.color
            inValue['brushSize'] = f.sz
        } else {
            if (objDict && objDict[foundKey] && objDict[foundKey].obj) {
                try {
                    let c2 = JSON.parse(objDict[foundKey].obj.content)
                    if ('font' in c2) {
                        inValue['font'] = c2.font
                        inValue['color'] = c2.color
                        inValue['brushSize'] = c2.brushRadius
                    }

                } catch (e) {
                    console.error("ERROR ", e)
                }
            }
        }
        selectedObj.remove()
        clearSelect()

        ib.delObject(foundKey)
    }

    function objMove(x, y) {
        //sendMobile(["OBJ MOVE", x])
        if (selectedObj) {
            selectedObj.position.x = x
            selectedObj.position.y = y
        }
    }

    function drawPaper(obj, cb = null) {
        if (!obj.content) return
        try {
            if ('type' in obj) {
                if (obj['type'].length <= 1) {
                    console.error("OBJ TOO SHORT", obj)
                } else {
                    obj['type'] = JSON.parse(obj['type'])
                    if (obj.type && obj.type.compressed) {
                        obj.content = pako.inflate(obj.content, { to: 'string' });
                        delete obj['type']
                    }
                }
            }
        } catch (e) {
            console.error("cannot decode", e, obj)
            return
        }

        if (obj.id in paperObj) {
            if (paperObj[obj.id].gqlObj && paperObj[obj.id].gqlObj.updatedAt ===
                obj.updatedAt) {
                if (cb) cb(paperObj[obj.id].obj)
                return
            }
            paperObj[obj.id].obj.remove()
            delete paperObj[obj.id]
            delete gStopLight[obj.id]
        }
        var rr
        try {
            rr = JSON.parse(obj.content)
        } catch {
            return
        }
        if (!rr.length || rr.length === 0) return
        //        if (selectedObj instanceof paper.Raster) {
        try {
            var pobj = new paper[rr[0]]()
        } catch {
            // Objects that contain a gradient component, cause the
            // json representation to be a dictionary, which needs
            // to be created as a paper group. new paper["dictionary"]
            // does not work.
            if (rr[0][0] === "dictionary") {
                pobj = new paper["Group"]()
            } else {
                console.warn("Object of unknown type rr[0] = ", rr[0])
                return;
            }
        }
        if (rr[0] === "Raster") {
            try {
                const ff = JSON.parse(obj.content)
                const rrt = ff[1].source.split("?X-Amz")[0]
                // rrt = rrt.replace("https://www.whiteboard.chat/", "http://localhost:3001/")

                ff[1].source = rrt
                obj.content = JSON.stringify(ff)
            } catch (e) {
                console.error("ERROR IS ", e)
            }
        }
        if (rr[0] === "Group") {
            var found = false
            try {
                if (rr[1] && rr[1].children) {
                    for (let i = 0; i < rr[1].children.length; i++) {
                        let c = rr[1].children[i]
                        if (c[0] === 'Raster') {
                            if (c[1] && c[1].source && c[1].source.includes("X-Amz")) {
                                const rrt = c[1].source.split("?X-Amz")[0]
                                c[1].source = rrt
                                found = true
                            }
                        }
                    }
                    if (found) {
                        obj.content = JSON.stringify(rr)
                    }
                }
            } catch (e) {
                console.error("ERROR IS ", e)
            }
        }

        pobj.data.id = obj.id
        // console.error("drawPaper:", obj);
        pobj.importJSON(obj.content)
        if (rr[0] === "Raster") {
            pobj.onError = function (f) {
                // cross domain failures for images?
                const ff = JSON.parse(obj.content)
                console.log("pobj.onError:", ff);
                iu.GetImage(ff[1].source).then((rr) => {
                    ff[1].source = rr.img
                    obj.content = JSON.stringify(ff)
                    pobj.importJSON(obj.content)
                })
            }
        }
        pobj.data.id = obj.id
        if (rr[0] === "Raster" || pobj.data.stackwithtime) {
            pobj.data.createdAt = new Date(obj.createdAt).getTime() / 1000

            let idx = getInsertIndex(pobj)
            paper.project.activeLayer.insertChild(idx, pobj);
        }
        if (rr[0] === "Group" && 'data' in pobj) {
            if (pobj.data.grid) {
                paper.project.activeLayer.insertChild(0, pobj);
                gGrid = pobj
                if (gBackGround) gBackGround.sendToBack()
            }
            if (pobj.data.lasso) {
                var children = pobj.children;
                var rasterChild = false
                // Iterate through the items contained within the array:
                for (var i = 0; i < children.length; i++) {
                    var child = children[i];
                    if (child instanceof paper.Raster) {
                        rasterChild = true
                    }
                }
                if (rasterChild) {
                    let idx = getInsertIndex(pobj)
                    paper.project.activeLayer.insertChild(idx, pobj);
                }
            }
        }
        if (pobj.data.table) {
            gTables++
        }


        if (pobj.data.stoplight) {
            const sl = pobj.data.stoplight
            if (sl.sess === gSessionID) {
                if (gStopLight[sl.stoplightID] && gStopLight[sl.stoplightID].data.id !== obj.id) {
                    ib.delObject(gStopLight[sl.stoplightID].data.id)
                }
                gStopLight[sl.stoplightID] = pobj
                if (gStopLightParent[sl.stoplightID]) pobj.insertAbove(gStopLightParent[sl.stoplightID])
            }
        }
        if (pobj.data.rectPainter) {
            gridPainter[pobj.data.coord] = pobj
        }
        if (pobj.data.stoplightGrp) {
            const sl = pobj.data.stoplightGrp
            gStopLightParent[sl] = pobj
            if (gStopLight[sl]) {
                pobj.insertBelow(gStopLight[sl])
            }
        }
        //condition need to add here


        if (pobj.data && pobj.data.background) {
            paper.project.activeLayer.insertChild(0, pobj);
            gBackGround = pobj

        }
        if (pobj && pobj.data.bingo) {
            if (obj.SessionID !== gSessionID) {
                //teachers' object?
                pobj.remove()
            }
        }
        if (pobj.data.studentMove) ib.checkStudentMove(pobj, gSessionID)
        pobj.selected = false
        paperObj[obj.id] = { obj: pobj, type: "drawPaper", gqlObj: JSON.parse(JSON.stringify(obj)) }
        attachTools(pobj)
        if (rr[0] !== "Raster") {
            if (cb) cb(pobj)
        } else {
            pobj.onLoad = function () {
                if (cb) {
                    cb(pobj)
                }
            }
        }
        // Used to adjust how a widget appears based on owner and any other config items
    }

    function clearBound(timer = false) {

        clearTimeout(debouceRect);
        if (boundRect && boundRect.data.boundClicked && timer) {
            return
        }
        if (boundRect) {
            if (boundRect.data.c1) {
                boundRect.data.c1.remove()
                boundRect.data.c2.remove()
                boundRect.data.c3.remove()
                boundRect.data.c4.remove()
                boundRect.data.c5.remove()
            }
            boundRect.remove()
            boundRect.data.boundClicked = false
            boundRect = null
        }
    }

    function brCircle(x, y, pobj, cir) {
        var rad = 6
        if (cir) rad = 12
        var myCircle = new Path.Circle(new Point(x, y), rad);
        if (cir) {
            myCircle.strokeWidth = 2
            myCircle.strokeColor = "#3174F5"; //mkColor(ctx.SavedColor, 3)
            myCircle.dashArray = [2, 2]
            myCircle.fillColor = mkColor(ctx.SavedColor, 3);
            myCircle.data.boundingRotate = true

        } else {
            myCircle.fillColor = ctx.SavedColor;
            myCircle.strokeWidth = 3
            myCircle.strokeColor = ctx.SavedColor;;
            myCircle.data.boundingResize = true
        }
        myCircle.data.boundingObj = pobj
        //   myCircle.selected = true
        return myCircle
    }
    function createBound(pobj, enter, clicked) {
        var allowed = {
            'moveResize': true,
        }
        if (enter) {
            allowed['edit'] = true
        }
        if (!clicked) {
            if (boundRect && boundRect.data.boundClicked) {
                return; //something selected by a click
            }
        }
        clearBound()
        if (!(ctx.mode in allowed)) {
            return
        }

        selectedObj = pobj
        var gg = findSelectedGQL()
        var skip = false
        var resizeAllowed = true
        if (ctx.subMode === "copy") resizeAllowed = false
        if (!gg) skip = true
        if (selectedObj.data.fixSize) resizeAllowed = false
        if (gg && gg.SessionID !== gSessionID) {
            skip = true
            if (selectedObj.data.studentClone || selectedObj.data.studentMove) {
                resizeAllowed = false
                skip = false
            }
        }
        if (skip === true) {
            clearSelect()
            return
        }
        debouceRect = setTimeout(function () {
            clearBound(true)
            return
        }, 2000)
        var ff = pobj.bounds
        var rr = {}
        rr.x = ff.x - 10
        rr.y = ff.y - 10
        rr.height = ff.height + 20
        rr.width = ff.width + 20
        let rectanglePath = new Path.Rectangle(rr);
        rectanglePath.strokeColor = ctx.SavedColor;
        rectanglePath.strokeWidth = 3
        rectanglePath.data.boundClicked = clicked
        rectanglePath.data.boundingObj = pobj
        rectanglePath.data.boundingResize = false

        boundRect = rectanglePath
        // boundRect.selected = true
        if (resizeAllowed) {
            rectanglePath.data.c1 = brCircle(rr.x, rr.y, pobj, false)
            rectanglePath.data.c5 = brCircle(rr.x, rr.y, pobj, true)
            rectanglePath.data.c2 = brCircle(rr.x + rr.width, rr.y, pobj, false)
            rectanglePath.data.c3 = brCircle(rr.x + rr.width, rr.y + rr.height, pobj, false)
            rectanglePath.data.c4 = brCircle(rr.x, rr.y + rr.height, pobj, false)
        }
    }
    function attachTools(pobj) {

        if (pobj.data.linkData && pobj.data.linkData.type === "DropZone") {
            gDropZone[pobj.data.linkData.id] = pobj
        }
        pobj.onMouseEnter = function (event) {
            if (this.data && this.data.lock) {
                return
            }
            if (this.data && this.data.grid) {
                return
            }
            if (this.data && this.data.backGroundCol) {
                return
            }
            createBound(this, false, false)
            if (this && this.data.linkData) {

                createButton(this)
            }
        }
        pobj.onMouseLeave = function (event) {
            // if (boundRect) boundRect.remove()
            // boundRect = null 
        }
        // // pobj.onMouseDrag = function (event) {
        // //     console.log("EVENT IS ",event)
        // // }
        // // pobj.onMouseMove = function (event) {
        // //     console.log("EVENT IS ",event)
        // // }
        // pobj.onMouseClick = function (event) {
        //     this.fillColor = 'yellow';
        //     console.log("EVENT IS ", event)
        // }
    }

    function getCompressedSvg(o) {
        if (!isApiMode) return null
        let svg = o.exportSVG({ asString: true })
        let def = pako.deflate(svg, { to: 'string' });
        return def
    }

    function updateDrawPaperGqlObj(gqlObj, objIn, cb = null) {
        const id = gqlObj.id
        var ff = objIn.exportJSON()
        if (!session) return
        var copy = compress(ff, true)
        gqlObj.content = copy.content
        gqlObj.type = copy.type
        var svg = getCompressedSvg(objIn)
        if (svg) {
            gqlObj.SVGObj = svg
        }
        delete gqlObj['updatedAt'] // Ensures we get an updated timestamp so recipients act on the fresh obj
        ib.updateObject(gqlObj).then(res => {
            var obj = res.data.updateObject
            delete obj['Session']
            if (obj) {
                objDict[id] = { lastIndex: 0, mine: true, obj: obj }
            }
            drawPaper(obj, cb)
        })
    }
    function updateDrawPaperObj(objIn, cb = null) {
        const id = uuid()
        delete objIn.data['blink']
        objIn.data.drawPaper = true
        var ff = objIn.exportJSON()
        if (!session) return
        const userid = gUser ? gUser.id : null
        var copy = compress(ff, true)
        var svg = getCompressedSvg(objIn)

        ib.createObject(id, "name", session.id, copy.content, "drawPaper", userid, copy.type, session.ttl, svg).then(res => {
            var obj = res.data.createObject
            delete obj['Session']
            if (obj) {
                objDict[id] = { lastIndex: 0, mine: true, obj: obj }
            }
            drawPaper(obj, cb)
        })
    }

    function doneMove(e) {
        if (ctx && ctx.lastMode && ctx.lastMode === "moveResize") {
            delete ctx['lastMode']
            ctx.mode = "moveResize"
            ctx.canvas.interface.style.cursor = ctx.subMode
            if (ctx.AutoMove) {
                ctx.mode = ctx.AutoMove
                if (ctx.mode in DEFTOOLCURSOR) {
                    ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[ctx.mode]
                }
                delete ctx['AutoMove']
            }
        } else if (ctx && ctx.lastMode && ctx.lastMode === "rect") {
            ctx.mode = ctx.lastMode
            ctx.canvas.interface.style.cursor = "crosshair"
            delete ctx['lastMode']
        } else {
            ctx.mode = defaultTool
            ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
        }

        if (selectedObj) {
            //del obj 

            if (selectedObj.data.id && selectedObj.data.drawPaper) {
                let pid = selectedObj.data.id
                let po = paperObj[pid] && paperObj[pid].gqlObj ? paperObj[pid].gqlObj : null
                if (po) {
                    var ggcopy = { ...paperObj[pid].gqlObj }
                    ggcopy.type = JSON.stringify(ggcopy.type)

                    updateDrawPaperGqlObj(po, selectedObj, newObj)
                    selectedObj.remove()
                    selectedObj = null
                    clearBound()
                }
                return
            }
            var gg = findSelectedGQL()
            if (gg) {
                // Need to create a copy to make type a string again.
                ggcopy = { ...gg }
                ggcopy.type = JSON.stringify(ggcopy.type)
                ib.delObject(ggcopy.id)
            }
            selectedObj.remove()
            removeSelected(selectedObj)
            //objDict[id] = { lastIndex: 0, mine: true, obj: null }

            updateDrawPaperObj(selectedObj, newObj)
            selectedObj = null
            clearBound()
            function newObj(o) {
                if (ctx.mode === "moveResize") {
                    createBound(o, true, true)
                }
            }
            return

        }

    }

    function getHitObj(e) {
        clearSelect()
        var hitOptions = { segments: true, stroke: true, fill: true, tolerance: 5 };
        let point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        point = paper.view.viewToProject(point)
        var fooo = paper.project.hitTest(point, hitOptions)
        if (fooo) {
            fooo.item.selected = true
            selectedObj = fooo.item
            if (selectedObj.data.backGroundCol) {
                selectedObj = null
                fooo.item.selected = false
                return null
            }
            if (selectedObj.data.unselectable) {
                selectedObj = null
                fooo.item.selected = false
                return null
            }
            if (fooo && fooo.item.parent) {
                var parent = fooo.item.parent
                var count = 7
                while (parent) {
                    count--
                    if (count === 0) return null
                    if (parent instanceof paper.Group && parent.data.id) {
                        if (parent.data.grid || parent.data.backGroundCol) {
                            selectedObj = null
                            fooo.item.selected = false
                            return null
                        }
                        selectedObj = parent
                        fooo.item.selected = false
                        parent.children.map((c) => {
                            if (!(c instanceof paper.Group))
                                c.selected = true
                            return null
                        })
                        break
                    }
                    if (parent && parent.item && parent.item.parent instanceof paper.Group) {
                        parent = parent.item.parent
                    } else {
                        if (parent && parent.parent && parent.parent instanceof paper.Group) {
                            parent = parent.parent
                        } else {
                            parent = null
                        }
                    }
                }
            }
        }
        return selectedObj
    }
    function clearAllSelected() {
        if (!selectedObj) return
        var parent = selectedObj
        if (parent.children) {
            parent.children.map((c) => {
                c.selected = false
                return null
            })
        }
        selectedObj.selected = false
    }

    function getHitObjZap(e) {
        clearSelect()
        var hitOptions = { segments: true, stroke: true, fill: true, tolerance: 10 };
        let point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        point = paper.view.viewToProject(point)
        var foooA = paper.project.hitTestAll(point, hitOptions)
        var objs = []
        for (let i = 0; i < foooA.length; i++) {
            let fooo = foooA[i]

            if (fooo) {
                fooo.item.selected = true
                selectedObj = fooo.item
                if (selectedObj.data.backGroundCol) {
                    selectedObj = null
                    fooo.item.selected = false
                    continue
                }
                if (fooo && fooo.item.parent) {
                    var parent = fooo.item.parent
                    var count = 7
                    while (parent) {
                        count--
                        if (count === 0) {
                            selectedObj = null
                            break
                        }
                        if (parent instanceof paper.Group && parent.data.id) {
                            if (parent.data.grid || parent.data.backGroundCol) {
                                fooo.item.selected = false
                                selectedObj = null
                                continue
                            }
                            selectedObj = parent
                            parent.children.map((c) => {
                                c.selected = true
                                return null
                            })
                            break
                        }
                        if (parent && parent.item && parent.item.parent instanceof paper.Group) {
                            parent = parent.item.parent
                        } else {
                            if (parent && parent.parent && parent.parent instanceof paper.Group) {
                                parent = parent.parent
                            } else {
                                parent = null
                            }
                        }
                    }
                }
            }
            if (selectedObj) objs.push(selectedObj)
        }
        return objs
    }
    function handleZap(e) {
        var selectedObjs = getHitObjZap(e)
        for (let i = 0; i < selectedObjs.length; i++) {
            selectedObj = selectedObjs[i]

            if (selectedObj && selectedObj.data && selectedObj.data.cursor) {
                clearSelect()
                continue
            }
            if (selectedObj) {
                if (selectedObj instanceof paper.Raster) {
                    if (!selectedObj.data.palette) {
                        clearSelect()
                        continue
                    }
                }
                if (selectedObj && selectedObj.data && selectedObj.data.lock) {
                    clearSelect()
                    continue
                }
                var gg = findSelectedGQL()
                if (gg && gg.SessionID !== gSessionID) {
                    clearSelect()
                    continue
                }
                if (!Boolean(gTeacher) && gSession && gSession.isGroup) {
                    if (gg && gg.CreatedBy && gUser && gUser.id !== gg.CreatedBy) {
                        clearSelect()
                        continue
                    }
                }
                if (!gg) {
                    //should delete orphans?
                    selectedObj.remove()
                    removeSelected(selectedObj)
                    clearSelect()
                    continue
                }
                delSelectedObj(gg)
            }
        }
    }
    function delSelectedObj(gg) {
        if (selectedObj && selectedObj.data && selectedObj.data.cannotdelete) {
            return
        }
        if (!teacherR && selectedObj.data.notSession) return
        selectedObj.remove()
        removeSelected(selectedObj)
        var ggcopy = { ...gg }
        ggcopy.type = JSON.stringify(ggcopy.type)
        ib.delObject(gg.id)
    }
    function removeSelected(o) {
        if (o.data && o.data.htmlObj) {
            try {
                document.body.removeChild(o.data.htmlObj);
            } catch (e) {
                console.error("Cannot remove", e)
            }
        }
    }

    var myLineStart = { obj: null, start: null, end: null }
    function getPoint(e, scrollAdjust = false) {
        var x, y
        var offset = [0, 0]
        if (scrollAdjust) {
            const canvasEl = document.getElementById("cs_container")
            if (canvasEl) { offset = [canvasEl.scrollLeft - 80, canvasEl.scrollTop - 130] }
            // add these numbers to set the wdigetwindow at cursor point
        }
        if (mobile) {
            [x, y] = getTouchPos(e)
        } else {
            let b = getOffsetZoom(e)
            x = b.x
            y = b.y
        }
        if (!x && !y) {
            [x, y] = getTouchPos(e); // for stylus, cant get offset
        }
        x -= offset[0]
        y -= offset[1]
        return [x, y]
    }

    function regularSnapPointLine(curLine, e) {
        let x = e.x
        let y = e.y
        var alpha = Math.atan2(y - curLine.y, x - curLine.x);
        var d = Math.hypot(y - curLine.y, x - curLine.x);
        var increment = 2 * Math.PI / 16;
        alpha = Math.round(alpha / increment) * increment;
        x = curLine.x + d * Math.cos(alpha);
        y = curLine.y + d * Math.sin(alpha);
        return { x: x, y: y }
    }
    function regularSnapLength(s, e) {
        var p
        var delX = Math.abs(s.x - e.x)
        var delY = Math.abs(s.y - e.y)
        var del = delX < delY ? delX : delY
        var signX = s.x - e.x > 0 ? -1 : 1
        var signY = s.y - e.y > 0 ? -1 : 1
        p = { x: s.x + del * signX, y: s.y + del * signY }
        return p
    }
    function startLine(e) {
        if (myLineStart.start) {
            lineEnd()
            return
        }
        myLineStart = { obj: null, start: null }
        const [x, y] = getPoint(e)
        myLineStart.start = new paper.Point(x, y)
    }
    function lineEnd() {
        if (myLineStart.obj) {
            updateDrawPaperObj(myLineStart.obj, null)
            myLineStart.obj.remove()
            lastObjDraw = true;
        }
        myLineStart = { obj: null, start: null }
    }
    function lineMove(x, y, e, isLine) {
        if (!myLineStart.start) return
        if (e.shiftKey || isLine) {
            var end = regularSnapPointLine(myLineStart.start, { x: x, y: y })
            myLineStart.end = new Point(end.x, end.y)
        } else {
            myLineStart.end = new Point(x, y)
        }
        if (myLineStart.obj) {
            myLineStart.obj.remove()
        }
        myLineStart.obj = new paper.Path.Line(myLineStart.start, myLineStart.end)

        myLineStart.obj.strokeColor = mkColor(ctx.color, ctx.opacity);
        myLineStart.obj.strokeWidth = ctx.brushRadius * 2
        myLineStart.obj.dashArray = ctx.lineStyle
    }

    function clearRect() {
        myRect = { obj: null, start: null }
    }
    function startRect(e) {
        if (myRect.start) {
            rectEnd(e)
            return
        }
        myRect = { obj: null, start: null }
        const [x, y] = getPoint(e)
        myRect.start = new paper.Point(x, y)

    }
    function rectEnd(e) {
        if (myRect.obj) {
            myRect.obj.data.shape = true
            myRect.obj.data.stackwithtime = true

            updateDrawPaperObj(myRect.obj, null)
            myRect.obj.remove()
            lastObjDraw = true;
        }
        myRect = { obj: null, start: null }
    }


    function rectMove(x, y, e, isRect) {
        if (!myRect.start) return
        if (e.shiftKey || isRect) {
            var end = regularSnapLength(myRect.start, { x: x, y: y })
            myRect.end = new Point(end.x, end.y)
        } else {
            myRect.end = new Point(x, y)
        }
        if (myRect.obj) {
            myRect.obj.remove()
        }
        myRect.obj = new paper.Path.Rectangle(myRect.start, myRect.end)
        if (ctx.subMode === "select") {
            myRect.obj.strokeColor = mkColor(ctx.color, ctx.opacity);
            myRect.obj.strokeWidth = 1
            myRect.obj.dashArray = [2, 2];
        } else {
            myRect.obj.strokeColor = mkColor(ctx.color, ctx.opacity);
            myRect.obj.strokeWidth = ctx.brushRadius * 2
            myRect.obj.dashArray = ctx.lineStyle;
        }
    }
    var myCircle = { obj: null, start: null, end: null }
    function startCircle(e) {
        if (myCircle.start) {
            circleEnd()
            return
        }
        myCircle = { obj: null, start: null }
        const [x, y] = getPoint(e)
        myCircle.start = new paper.Point(x, y)

    }
    function circleEnd() {
        if (myCircle.obj) {
            myCircle.obj.data.shape = true
            myCircle.obj.data.stackwithtime = true

            updateDrawPaperObj(myCircle.obj, null)
            myCircle.obj.remove()
            lastObjDraw = true;
        }
        myCircle = { obj: null, start: null }
    }
    function circleMove(x, y, e, isCircle) {
        if (!myCircle.start) return
        if (e.shiftKey || isCircle) {
            var end = regularSnapLength(myCircle.start, { x: x, y: y })
            myCircle.end = new paper.Point(end.x, end.y)
        } else {
            myCircle.end = new paper.Point(x, y)
        }
        if (myCircle.obj) {
            myCircle.obj.remove()
        }
        var vect = myCircle.end.subtract(myCircle.start)
        myCircle.obj = new paper.Path.Ellipse({
            point: myCircle.start,
            size: vect
        })

        myCircle.obj.strokeColor = mkColor(ctx.color, ctx.opacity);
        myCircle.obj.strokeWidth = ctx.brushRadius * 2
        myCircle.obj.dashArray = ctx.lineStyle
    }

    function handleDot(e) {
        if (ctx.pointertype && ctx.mode === "draw") {
            return DrawDotHere(e)
        }
        if (ctrlDown) {
            return
        }
        if (boundBRect) return (handleBClicked(e))
        var currentTime = new Date().getTime();
        var lastTextTime = currentTime - lastText;

        if (lastTextTime < 300) return
        var tapLength = currentTime - lastTap;
        clearTimeout(dtTimeout);
        if (tapLength < 500 && tapLength > 0) {
            lastTap = 0
            lastObjDraw = false;
            //double click 
        } else {
            dtTimeout = setTimeout(function () {
                if (!lastObjDraw) {
                    lastObjDraw = true;
                    return
                }
                clearTimeout(dtTimeout);
                ctx.AutoMove = ctx.mode
                startMoveResize(e)
                //single click
                return // no more dots 

            }, 500);
        }
        lastTap = currentTime;
    }
    function DrawDotHere(e) {
        const [x, y] = getPoint(e)
        var myCircle = new Path.Circle(new Point(x, y), ctx.brushRadius);
        var col = mkColor(ctx.color, ctx.opacity)
        myCircle.strokeColor = col;
        myCircle.fillColor = col;
        myCircle.strokeWidth = ctx.brushRadius
        myCircle.dashArray = ctx.lineStyle
        updateDrawPaperObj(myCircle, null)
        myCircle.remove()
    }
    function handleClick(e) {
        e.preventDefault()
        if (gLocked) return
        if (ctx.mode === "text") {
            selectedObj = getHitObj(e)
            if (selectedObj) {
                var skip = false
                var gg = findSelectedGQL()
                if (!gg) skip = true
                if (gg && gg.SessionID !== gSessionID) {
                    skip = true;
                }
                if (!skip && selectedObj instanceof paper.PointText) {
                    var foundKey = findSelectedKey()
                    return handleEditText(e, foundKey)
                }
            }
            handleKeyDown(e, null)

            // ctx.mode = defaultTool
            // ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
        }

    }
    function handleKeyDown(e, obj) {
        var drawCanvasPos = getElPosition(document.getElementById("canvas_drawing"))
        if (hasInput && !mobile) return;
        var pageX, pageY;
        if (e) {
            if (mobile) {
                var [x, y] = getTouchPosAct(e)
                pageX = x
                pageY = y
                var b = changeXYZoom(x, y)
                x = b.x
                y = b.y

                savePos = { x: b.x, y: b.y }
                pageX += drawCanvasPos.x
                pageY += drawCanvasPos.y
            } else {
                let b = getOffsetZoom(e)
                savePos = { x: b.x, y: b.y }
                pageX = e.pageX
                pageY = e.pageY
                if (!pageX || !pageY) {
                    let [x, y] = getTouchPosAct(e)
                    pageX = x + drawCanvasPos.x;
                    pageY = y + drawCanvasPos.y;
                }
            }

        }
        if (obj && obj.point) {
            var sy = ctx.canvasContainer.scrollTop
            var sx = ctx.canvasContainer.scrollLeft
            savePos = { x: obj.point.x - 2, y: obj.point.y - 10 }
            pageX = savePos.x - sx + drawCanvasPos.x
            pageY = savePos.y - sy + drawCanvasPos.y

            var mousePosition = new paper.Point(pageX, pageY);
            var viewPosition = paper.view.projectToView(mousePosition);

            pageX = viewPosition.x
            pageY = viewPosition.y
            //fix for offset due to newdesign for text edit
        }

        addInput(pageX, pageY);

    }
  
    function clearRectAll() {
        if (ctx.isDrawing) return

        // const width = ctx.canvas.temp.width
        // const height = ctx.canvas.temp.height
        // ctx.context.temp.clearRect(0, 0, width, height)
    }

    function drawPaperPath(obj, segments, color, radius, lineStyle) {
        const id = obj.id
        if (id in paperObj) {
            if (paperObj[id].gqlObj && obj.ended && paperObj[id].gqlObj.ended) {
                //already drawn
                paperObj[id].gqlObj = obj
                paperObj[id].gqlObj.id = id
                // paperObj[id].gqlObj.CreatedBy = obj.CreatedBy
                // paperObj[id].gqlObj.updatedAt = obj.updatedAt
                return
            }
            var path = paperObj[id].obj
            if (!path || !path.add) return
            paperObj[id].gqlObj = JSON.parse(JSON.stringify(obj))
            for (var i = 0; i < segments.length; i++)
                path.add(segments[i])
            path.smooth({ type: 'continuous' })
            return
        }
        path = new paper.Path({
            segments: segments,
            strokeColor: color,
            strokeWidth: radius * 2
        });
        path.dashArray = lineStyle
        // path.simplify(2);
        paperObj[id] = { obj: path, type: "draw", gqlObj: JSON.parse(JSON.stringify(obj)) }
        attachTools(path)
        paperObj[id].gqlObj.id = id
        path.data.id = id
        path.smooth({ type: 'continuous' })
        //path.simplify();
        return path
    }
    function drawPText(txt, x, y, color, size, obj, font) {
        if (obj.id in paperObj) {
            if (paperObj[obj.id].gqlObj && paperObj[obj.id].gqlObj.updatedAt ===
                obj.updatedAt) {
                return paperObj[obj.id].obj
            }
            paperObj[obj.id].obj.remove()
            delete paperObj[obj.id]
            delete gStopLight[obj.id]
        }
        var pointTextLocation = new paper.Point(x + 2, y + 10);
        var text = new paper.PointText(pointTextLocation);
        text.data.id = obj.id
        text.content = txt;
        text.style = {
            fontFamily: font,
            fontSize: 36,
            fillColor: color,
            justification: 'left'
        };
        paperObj[obj.id] = { obj: text, type: "text", gqlObj: JSON.parse(JSON.stringify(obj)) }
        attachTools(text)
        return text
    }

    function donePath(e) {
        var pobj = drawPaperPath(myObj, ctx.points, mkColor(ctx.color, ctx.opacity),
            ctx.brushRadius, ctx.lineStyle)
        if (!pobj) return false
        return true
    }
    function pathMove(x, y) {
        if (!ctx.isPressing) return
        if (!animatePath) {
            animatePath = new paper.Path({
                segments: new Point(x, y),
                strokeColor: "black",
                strokeWidth: 2
            });
            animatePath.dashArray = [10, 12];
            return
        }
        animatePath.add(new Point(x, y))
    }

    function pathUp(e) {
        ctx.mode = "draw"
        ctx.canvas.interface.style.cursor = "auto"
        if (animatePath) {
            animatePath.simplify(2)
            if (selectedObj) {
                var foundKey = findSelectedKey()
                var pathSave = []
                animatePath.segments.forEach((f) => {
                    pathSave.push({ x: f.point.x, y: f.point.y })
                })
                if (!paperObj[foundKey] || !paperObj[foundKey].obj) {
                    clearSelect()
                    animatePath.remove()
                    return
                }
                moveObjs[foundKey] = { obj: paperObj[foundKey].obj, path: pathSave }
                var animate = {}
                if (paperObj[foundKey].gqlObj && paperObj[foundKey].gqlObj.animate) {
                    animate = JSON.parse(paperObj[foundKey].gqlObj.animate)
                }
                animate['path'] = pathSave
                if (paperObj[foundKey].gqlObj) {
                    paperObj[foundKey].gqlObj.animate = JSON.stringify(animate)
                }
            }
            animatePath.remove()
        }
        animatePath = null
        clearSelect()
    }

    function handleMouseOut(e) {
        if (ctx.isDrawing) {
            handlePointerUp(e)
        }
    }
    function handlePointerUp(e) {
        ctx.isPressing = false
        if (ctx.mode === "rect") {
            rectEnd(e)
            return
        }
        if (ctx.mode === "circle") {
            circleEnd(e)
            return
        }
        if (ctx.mode === "line") {
            lineEnd()
            return
        }

        if (buttonClicked) {
            buttonClicked = null
            return
        }

        if (gLocked) return
        e.preventDefault()
        if (ctx.mode === "moveResize" && e.button === 0) {
            return
        }
        //  e.preventDefault()
        if (ctx.mode === "selectAnimate") {
            //handleDblClick(e)
            return
        }

        myObj.ended = true

        if (ctx.isDrawing) {
            lastObjDraw = true;
            let ud = donePath(e)
            if (ud) updateDraw(false, true)

        } else {
            if (ctx.mode === "draw") {
                handleDot(e)
            }
            if (ctx.mode === "erase" && e.button === 2) {
                //right click
                var dbl = mylocalStorage.getItem("dblClickDisable")
            }

        }
        ctx.isDrawing = false

        if (ctx.drawPath) ctx.drawPath.remove();
        if (ctx.mode === "move") return pathUp(e)
        ctx.points.length = 0

        if (ctx.mode === "edit") {
            lastTap = 0
            doneMove(e)
            return
        }
        if (ctx.mode === "resize" || ctx.mode === "rotate") {
            lastTap = 0
            doneMove(e)
            return
        }
        if (e.button === 2) {
            //clearCanvas()
            dbl = mylocalStorage.getItem("dblClickDisable")
            if (!dbl) handleButtonDraw('Draw')
            return
        }
    }

    function midPointBtw(p1, p2) {
        return {
            x: p1.x + (p2.x - p1.x) / 2,
            y: p1.y + (p2.y - p1.y) / 2
        };
    }
    function prepObj(obj, compressed) {
        var copy = JSON.parse(JSON.stringify(obj))
        if (!copy.content) return null

        delete copy['created']
        copy.type = JSON.stringify({ 'compressed': compressed })
        if (compressed)
            copy.content = pako.deflate(copy.content, { to: 'string' });
        return copy
    }
    function compress(content, compressed) {
        var copy = {}
        copy.type = JSON.stringify({ 'compressed': compressed })
        if (compressed)
            copy.content = pako.deflate(content, { to: 'string' });
        return copy
    }
    function checkUpdateUserColor() {
        var ct = {}
        if (!gUser) {
            return
        }
        if (gUser.content) {
            ct = JSON.parse(gUser.content)
        }
        if (ct.color !== ctx.color) {
            ct.color = ctx.color
            gUser.content = JSON.stringify(ct)
            setUser(gUser)
            delete gUser['Session']
            ib.updateUser(gUser)
        }
    }


    function updateDraw(newSession, endSession) {
        var compressed = false
        if (!session && newSession) {
            return
        }
        if (ctx.points.length > 70) {
            compressed = true
        }
        var content = {
            type: "drawFree", points: ctx.points, ended: endSession,
            color: mkColor(ctx.color, ctx.opacity), brushRadius: ctx.brushRadius, compressed: compressed,
            dashArray: ctx.lineStyle
        }
        if (newSession) {
            const id = uuid()
            myObj.id = id
            myObj.created = false
            myObj.SessionID = gSessionID
            objDict[id] = { lastIndex: 0, mine: true, obj: JSON.parse(JSON.stringify(myObj)) }
            try {
                checkUpdateUserColor()
                const userid = gUser ? gUser.id : null
                ib.createObject(id, "name", session.id, JSON.stringify(content), "drawFree", userid, null, session.ttl).then(res => {
                    const obj = res.data.createObject
                    if ((ctx.mode === "magicDraw" || ctx.mode === "magicHand") && magicDrawObj.pobj) {
                        magicDrawObj.pobj.push(obj);
                    }
                    // myObj.CreatedBy = obj.CreatedBy
                    // myObj.updatedAt = obj.updatedAt
                    // if finshed by now we should update 
                    if (obj && objDict[obj.id] && objDict[obj.id].obj && objDict[obj.id].obj.ended) {
                        var copy = prepObj(objDict[obj.id].obj, compressed)
                        if (copy) {
                            copy.id = obj.id
                            var svg = getCompressedSvg(ctx.drawPath)
                            if (svg) copy.SVGObj = svg
                            ib.updateObject(copy)
                        }
                    }
                    if (obj && objDict[obj.id] && objDict[obj.id].obj) {
                        objDict[obj.id].obj.CreatedBy = userid
                        objDict[obj.id].obj.created = true
                    }
                })
            } catch (err) {
                console.error("ERROR D", err)
            }
        } else {
            if (myObj.id && myObj.id in objDict) {
                var obj = objDict[myObj.id].obj
                if (!obj) return
                obj.ended = endSession
                obj.content = JSON.stringify(content)
                if (!obj.created) return
                if (endSession) {
                    clearTimeout(debounceTimer)
                    debounceTimer = null
                    var copy = prepObj(obj, compressed);
                    if (copy) {
                        var svg = getCompressedSvg(ctx.drawPath)
                        if (svg) copy.SVGObj = svg
                        ib.updateObject(copy)
                    }
                    setMyObj()
                    myObj.id = null
                } else {
                    if (debounceTimer === null) {
                        debounceTimer = setTimeout(() => {
                            async function write() {
                                if (obj && obj.id) {
                                    var copy = prepObj(obj, compressed)
                                    copy.ended = false
                                    if (copy) ib.updateObject(copy)
                                }
                                // Once the debounceTimer fires, we have to reset it
                                debounceTimer = null;
                            }
                            write()
                        }, 800)
                    }
                }
            }
        }
    }

    function drawText(txt, x, y, size) {
        var font = mylocalStorage.getItem("font")
        font = font ? font : "Roboto"

        var col = savePos && savePos.fonts ? savePos.fonts.color : mkColor(ctx.SavedColor, ctx.opacity)
        font = savePos && savePos.fonts ? savePos.fonts.font : font
        var sz = savePos && savePos.fonts ? savePos.fonts.sz : gText
        var content = {
            type: "text", points: { x: x, y: y }, text: txt,
            ended: true, color: col,
            brushRadius: sz, font: font
        }
        if (!session) return
        const id = uuid()
        myObj.id = id
        myObj.SessionID = gSessionID
        objDict[id] = { lastIndex: 0, mine: true, obj: null }
        let pobj = drawPText(txt, x, y, content.color, content.brushRadius, myObj, content.font)
        if (size) pobj.data.textBoxSize = size
        pobj.data.fonts = { font: font, sz: sz, color: col }
        if (savePos && savePos.tsz) {
            if (pobj.bounds.topLeft.y < y) {
                pobj.position.y += y - pobj.bounds.topLeft.y
                content['points'] = { x: x, y: pobj.position.y }
            }
        }
        if (size) content["size"] = size
        if (savePos && savePos.tbdata) {
            if (savePos.tbdata.obj) {
                var obd = savePos.tbdata.obj.data.linkData
                gTextBox[obd.id] = pobj
                content['textBoxID'] = obd.id
            }
        }
        const userid = gUser ? gUser.id : null
        var svg = null
        if (pobj)
            svg = getCompressedSvg(pobj)
        ib.createObject(id, "name", session.id, JSON.stringify(content), "text", userid, null, session.ttl, svg).then((res) => {
            const robj = res.data.createObject
            if (robj && robj.id && objDict[robj.id]) {
                objDict[robj.id].obj = robj
            }
            // myObj.CreatedBy = robj.CreatedBy
            // myObj.updatedAt = robj.updatedAt
        })
        return pobj
    }

    function drawTextRemote(obj) {
        try {
            if ('type' in obj) {
                obj['type'] = JSON.parse(obj['type'])
                if (obj.type && obj.type.compressed) {
                    obj.content = pako.inflate(obj.content, { to: 'string' });
                    delete obj['type']
                }
            }
        } catch {

        }
        try {
            var c = JSON.parse(obj.content)
        } catch (e) {
            console.log("Cannot parse ob", e, c)
            return
        }
        var font = c.font ? c.font : "Roboto"
        let pobj = drawPText(c.text, c.points.x, c.points.y, c.color, c.brushRadius, obj, font)
        if (c['textBoxID']) {
            if (obj.SessionID === gSessionID && pobj)
                gTextBox[c['textBoxID']] = pobj
        }
        if (c.pointObj && pobj) {
            pobj.data.lock = true
            pobj.data.pointObj = true
            pobj.data.cannotdelete = true
            pobj.data.unselectable = true
        }
        if (pobj && c.size) {
            pobj.data.textBoxSize = c.size
            pobj.data.fonts = { font: font, sz: c.brushRadius, color: c.color }
        }
    }

    function handleEscape() {
        if (inpBox) {
            try {
                inpBox.blur()
            } catch { }
        }

        clearBound()
        clearRect()
        if (isApiMode) return
        ctx.mode = defaultTool
        ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
        setdrawMode({ name: 'draw' })
        ctx.color = ctx.SavedColor
        ctx.brushRadius = ctx.drawBrushRadius
        if (ctx.slider && ctx.slider.brush && ctx.slider.brush.value) ctx.slider.brush.value = ctx.brushRadius
        seteraseMode(false)
        delete ctx['lastMode']
        return
    }

    function ApplyLineBreaks(oTextarea) {
        if (oTextarea.wrap) {
            oTextarea.setAttribute("wrap", "off");
        }
        else {
            oTextarea.setAttribute("wrap", "off");
            var newArea = oTextarea.cloneNode(true);
            newArea.value = oTextarea.value;
            oTextarea.parentNode.replaceChild(newArea, oTextarea);
            oTextarea = newArea;
        }

        var strRawValue = oTextarea.value;
        oTextarea.value = "";
        var nEmptyWidth = oTextarea.clientWidth;
        var nLastWrappingIndex = -1;
        for (var i = 0; i < strRawValue.length; i++) {
            var curChar = strRawValue.charAt(i);
            if (curChar === ' ' || curChar === '-' || curChar === '+')
                nLastWrappingIndex = i;
            oTextarea.value += curChar;
            if (oTextarea.scrollWidth > nEmptyWidth) {
                var buffer = "";
                if (nLastWrappingIndex >= 0) {
                    for (var j = nLastWrappingIndex + 1; j < i; j++)
                        buffer += strRawValue.charAt(j);
                    nLastWrappingIndex = -1;
                }
                buffer += curChar;
                oTextarea.value = oTextarea.value.substr(0, oTextarea.value.length - buffer.length);
                oTextarea.value += "\n" + buffer;
            }
        }
        oTextarea.setAttribute("wrap", "");
    }
    function closeInput() {
        if (hasInput) {
            if (inpBox.value.length > 0) {

                try {
                    ApplyLineBreaks(inpBox)
                } catch (e) {
                    console.log("CANNOT APPLY BREAK", e)
                }
                var size = { w: inpBox.clientWidth, h: inpBox.clientHeight }
                if (savePos && savePos.tbdata && savePos.tbdata.cb) {
                    savePos.tbdata.cb(inpBox.value)
                } else {
                    drawText(inpBox.value, savePos.x, savePos.y, size);
                }
            }
            lastText = new Date().getTime();
            lastObjDraw = false;
            hasInput = false;
            try {
                document.body.removeChild(inpBox);
            } catch {
                console.log("Cannot remove")
            }
            inpBox = null
        }
    }
    function handleBlur(e) {
        if (!inpBox) {
            return
        }
        if (savePos.tbdata) {
            var rr = new Date()
            var ff = rr - savePos.tbdata.tm
            if (ff < 300) {
                inpBox.focus()
                return
            }
        }
        if (!contorlButtonClick && !gSpeechText) closeInput()
        if (gSpeechText) {
            setTimeout(function () {
                if (!contorlButtonClick) closeInput()
            }, 300)
            return
        }
        contorlButtonClick = false
    }


    function typeHelp() {
        if (!onceMessage && !mobile) {
            onceMessage = true
        }
    }

    function addInput(x, y) {
        typeHelp()
        if (inpBox && hasInput) {
            closeInput()
        }
        var font = mylocalStorage.getItem("font")
        font = font ? font : "Roboto"
        var input = document.createElement('textarea');
        // input.type = 'text';
        //replace(/\+/g, '-')
        if (inValue && inValue.text && !savePos.tbdata) input.value = inValue.text
        if (inValue && inValue.text && savePos.tbdata) input.value = inValue.text
        if (!gEnter) {
            input.placeholder = "Ctrl+Enter for new line"
        } else {
            input.placeholder = "Please enter text"
        }
        var left = x - 4
        var top = y - 4
        if (savePos && savePos.sz && savePos.sz !== 0) {
            //grid mode
            left = left + 4
            top = 4 + top - savePos.sz / 2
            input.placeholder = ""
            if (!inValue) {
                inValue = {}
                if (savePos.sz > 40) {
                    inValue['size'] = { h: savePos.sz, w: savePos.sz }
                }
                if (savePos.sz === 40) {
                    inValue['size'] = { h: savePos.sz, w: 700 }
                }
            }
        }
        if (savePos && savePos.tsz) {
            if (!inValue) {
                inValue = {}
            }

            inValue['size'] = { h: savePos.tsz.h, w: savePos.tsz.w }
        }
        input.style.color = ctx.SavedColor
        if (inValue && inValue.font) {
            font = inValue.font
            input.style.color = inValue.color
            savePos['fonts'] = { sz: inValue.brushSize, font: font, color: inValue.color }
        }
        if (!Boolean(gTeacher)) {
            input.setAttribute('spellcheck', false)
            input.setAttribute('autocorrect', 'off')
        }
        input.style.font = 35 + 'px ' + font;
        input.style.position = 'absolute';
        input.style.left = left + 'px';
        input.style.top = top + 'px';
        input.style.maxWidth = maxWidth - x + "px";
        input.style.maxHeight = maxHeight - y + "px";

        input.style.zIndex = 100
        if (inValue && inValue.size) {
            input.style.height = inValue.size.h + "px"
            input.style.width = inValue.size.w + "px"
        }
        if (gBackGround && gBackGround.data.backGroundCol) input.style.background = gBackGround.data.backGroundCol.color
        else input.style.background = styleVariables.bgColor;
        if (savePos && savePos.tbdata) {
            input.style.background = "transparent"
            input.style.borderStyle = "none"
            input.style.outline = "none"
            input.placeholder = ""
        }
        input.wrap = "hard";
        input.onblur = handleBlur;
        input.onkeydown = handleEnter;
        input.onkeyup = handleKeyUp;
        document.body.appendChild(input);

        ///make windo grow bigger when you keep typing 
        var observe;
        if (window.attachEvent) {
            observe = function (element, event, handler) {
                element.attachEvent('on' + event, handler);
            };
        }
        else {
            observe = function (element, event, handler) {
                element.addEventListener(event, handler, false);
            };
        }
        function delayedResize() {
            window.setTimeout(resize, 0);
        }
        function resize() {
            if (input.scrollHeight > input.clientHeight) {
                input.style.height = 'auto';
                input.style.height = input.scrollHeight + 'px';
            }
        }
        observe(input, 'change', resize);
        observe(input, 'cut', delayedResize);
        observe(input, 'paste', delayedResize);
        observe(input, 'drop', delayedResize);
        observe(input, 'keydown', delayedResize);
        /// end windo keep growing 
        input.focus();
        hasInput = true;
        inpBox = input
        inValue = null
    }

    var ctrlDown = false
    const ctrlKey = 17

    function handleKeyUp(e) {
        if (e.keyCode === ctrlKey) ctrlDown = false;
        if (e.keyCode === 18) {
            if (ctx.isPressing) handlePointerUp(e)
        }
    }

    function handleEnter(e) {
        var keyCode = e.keyCode;
        if (e.keyCode === ctrlKey) ctrlDown = true;
        if (e.keyCode === 27) return handleEscape()
        var keymove = inpBox && hasInput && (gGrid || gTables !== 0)
        if (inpBox && ctrlDown && keyCode === 13) {
            inpBox.value = inpBox.value + "\r\n"
            return
        }
        if (inpBox && hasInput && keyCode === 13 && !gEnter) {
            e.preventDefault();
            var x = savePos.x
            var y = savePos.y
            closeInput()
            // inpBox.onblur = null
            // handleBlur(e)
            if (gGrid) {
                if (savePos.sz) {
                    if (savePos.sz === 40)
                        y += 30
                    else
                        y += savePos.sz
                } else {
                    y += 70
                }
                setupNext(x, y)
            }

        }
        if (keymove && keyCode === 9) {
            e.preventDefault();
            x = savePos.x
            y = savePos.y
            closeInput()
            if (savePos.sz) {
                x += savePos.sz
            } else {
                x += 70
            }
            if (savePos.tsz) {
                x += savePos.tsz.w
            }

            setupNext(x, y)
        }
        //37, 38, 39, 40 - left, up, right, down
        if (keymove && keyCode === 37) {
            e.preventDefault();
            x = savePos.x
            y = savePos.y
            closeInput()
            if (savePos.sz) {
                x -= savePos.sz
            } else {
                x -= 70
            }
            if (savePos.tsz) {
                x -= savePos.tsz.w
            }
            setupNext(x, y)
        }
        if (keymove && keyCode === 38) {
            e.preventDefault();
            x = savePos.x
            y = savePos.y
            closeInput()
            if (savePos.sz) {
                if (savePos.sz === 40)
                    y -= 45
                else
                    y -= savePos.sz
            } else {
                y -= 70
            }
            if (savePos.tsz) {
                y -= savePos.tsz.h
            }
            setupNext(x, y)
        }
        if (keymove && keyCode === 39) {
            e.preventDefault();
            x = savePos.x
            y = savePos.y
            closeInput()
            if (savePos.sz) {
                x += savePos.sz
            } else {
                x += 70
            }
            if (savePos.tsz) {
                x += savePos.tsz.w
            }
            setupNext(x, y)
        }
        if (keymove && keyCode === 40) {
            e.preventDefault();
            x = savePos.x
            y = savePos.y
            closeInput()
            if (savePos.sz) {
                if (savePos.sz === 40)
                    y += 30
                else {
                    y += savePos.sz * (1.5)
                }
            } else {
                y += 70
            }
            if (savePos.tsz) {
                y += savePos.tsz.h
            }
            setupNext(x, y)
        }

        function setupNext(x, y) {
            //var half = savePos./2

            var sy = ctx.canvasContainer.scrollTop
            var sx = ctx.canvasContainer.scrollLeft
            savePos = { x: x, y: y }

            var drawCanvasPos = getElPosition(document.getElementById("canvas_drawing"))
            addInput(x - sx + drawCanvasPos.x, y - sy + drawCanvasPos.y);
        }

    }
    function drawObj(obj, lastIdx, col) {
        try {
            if ('type' in obj) {
                obj['type'] = JSON.parse(obj['type'])
                if (obj.type && obj.type.compressed) {
                    obj.content = pako.inflate(obj.content, { to: 'string' });
                    delete obj['type']
                }
            }
            var arr = JSON.parse(obj.content)
        } catch (e) {
            console.error("cannot decode", e, obj)
            return
        }
        if (!arr) {
            return
        }
        objDict[obj.id].content = arr

        const points = arr.points
        if (points.length <= lastIdx + 1) {
            return lastIdx + 1
        }
        var color = null
        if ('color' in arr) {
            color = arr['color']
        } else {
            color = col
        }
        var ct = ctx.context.drawing

        // if (color === "#ffffff")
        //     ct = ctx.context.white

        ct.strokeStyle = color
        ct.lineStyle = ctx.lineStyle
        ct.lineWidth = ctx.brushRadius * 2
        if ('brushRadius' in arr)
            ct.lineWidth = arr.brushRadius * 2
        ct.lineStyle = arr.dashArray
        ct.lineJoin = 'round'
        ct.lineCap = 'round'
        drawPaperPath(obj, points.slice(lastIdx, points.length), color, ct.lineWidth / 2, ct.lineStyle, ct.lineStyle)
        return points.length
    }

    function handleResize(x, y) {
        var rect = selectedObj
        var pt = new paper.Point(x, y)
        if (!rect) return
        // scale by distance from down point
        var bounds = rect.data.bounds;
        var scale = pt.subtract(bounds.center).length /
            rect.data.scaleBase.length;
        if (scale > 8) scale = 8
        if (scale < 0.2) scale = 0.2
        //rect.scale(scale)
        var tlVec = bounds.topLeft.subtract(bounds.center).multiply(scale);
        var brVec = bounds.bottomRight.subtract(bounds.center).multiply(scale);
        var newBounds = new paper.Rectangle(tlVec.add(bounds.center), brVec.add(bounds.center));
        rect.bounds = newBounds;
        // createBound(rect)
    }

    function handleRotate(x, y) {
        var rect = selectedObj
        var pt = new paper.Point(x, y)
        if (!rect) return
        var center = rect.bounds.center;
        var lastPoint = rect.data.lastPoint
        var baseVec = center.subtract(lastPoint);
        var nowVec = center.subtract(pt);
        var angle = nowVec.angle - baseVec.angle;
        rect.rotate(angle);
        rect.data.lastPoint = pt
    }

    var lastx = 0, lasty = 0;
    function changeZoom(oldZoom, delta, c, p, factor) {
        var newZoom;

        factor = Math.min(factor, 2)
        if (delta < 0) {
            newZoom = oldZoom * factor;
        }
        if (delta > 0) {
            newZoom = oldZoom / factor;
        }
        let beta = oldZoom / newZoom;
        p.add(new Point(7.5, 7.5));
        let pc = p.subtract(c);
        let a = p.subtract(pc.multiply(beta)).subtract(c);
        return [newZoom, a];
    };

    function getOffsetZoom(e) {
        let x = e.offsetX, y = e.offsetY;
        if (!x && !y) {
            [x, y] = getTouchPos(e); // for stylus, cant get offset
            return { x: x, y: y }
        }
        return changeXYZoom(x, y)
    }
    function getBrushfromLazy() {
        var brush = ctx.lazy.getBrushCoordinates()
        if (translateIfNeed()) return brush
        var mousePosition = new paper.Point(brush.x, brush.y);
        var viewPosition = paper.view.viewToProject(mousePosition);
        brush.x = viewPosition.x
        brush.y = viewPosition.y
        return brush
    }

    function translateIfNeed() {
        if (paper.view.zoom === 1 && !gPanned) return true
        return !gPanned
    }
    function changeXYZoom(x, y) {
        var b = { x: x, y: y }
        if (translateIfNeed()) return b
        var mousePosition = new paper.Point(b.x, b.y);
        var viewPosition = paper.view.viewToProject(mousePosition);
        if (viewPosition) {
            b.x = viewPosition.x
            b.y = viewPosition.y
        }
        return b
    }

    function pointerMove(e) {
        if (translateIfNeed()) return handlePointerMove(e.offsetX, e.offsetY, e)
        var mousePosition = new paper.Point(e.offsetX, e.offsetY);

        var viewPosition = paper.view.viewToProject(mousePosition);
        if (viewPosition) handlePointerMove(viewPosition.x, viewPosition.y, e)
        else handlePointerMove(e.offsetX, e.offsetY, e)
        // handlePointerMove(e.offsetX, e.offsetY, e)

    }

    function drawBoundries() {
        if (gPanned) return
        gPanned = true

        let rectanglePath = new Path.Rectangle(paper.view.bounds)
        rectanglePath.strokeColor = "#1CB1C4"
        rectanglePath.strokeWidth = 1;
        rectanglePath.dashArray = [2, 2];

    }
    function checkZoomLimit(req) {
        drawBoundries()
        if (req > 0.4 && req < 6) return { zm: req, reached: false }
        if (req < paper.view.zoom) return { zm: 0.4, reached: true }
        return { zm: 6, reached: true }
    }

    function spointerdown(e) {
        var mousePosition = new paper.Point(e.offsetX, e.offsetY);

        ctx.pointertype = { type: e.pointerType, pressure: e.pressure, downPoint: mousePosition }
    }

    function zoomClick(e) {
        e.preventDefault()
        // let point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        var delta = 1
        var mousePosition = new paper.Point(e.offsetX, e.offsetY);
        let view = paper.view
        var viewPosition = view.viewToProject(mousePosition);
        var factor = 1.05
        delta = delta * -1
        let _ref1 = changeZoom(view.zoom, delta, view.center, viewPosition, factor);
        var newZoom = _ref1[0];
        var offset = _ref1[1];

        let zm = checkZoomLimit(newZoom);
        view.zoom = zm.zm
        if (zm.reached) return
        //canvas.style.width = (origCanvasWidth * newZoom) + "px";
        //canvas.style.height = (origCanvasHeight * newZoom) + "px";            
        view.center = view.center.add(offset);
        sendPositionUpdate()

    }

    function PanModeMove(x, y, e) {
        if (!ctx.isPressing) return
        sendPositionUpdate()
        let p1 = paper.DomEvent.getOffset(e, ctx.canvasContainer)

        let point = paper.view.viewToProject(p1)
        let downPoint = paper.view.viewToProject(ctx.pointertype.downPoint)
        var pan_offset = point.subtract(downPoint);
        paper.view.center = paper.view.center.subtract(pan_offset);
        ctx.pointertype.downPoint = p1
        drawBoundries()

        // paper.view.draw()
        // var point = new paper.Point(x ,y)
        // var a = ctx.pointertype.downPoint.subtract(point);
        // a = a.add(paper.view.center);
        // paper.view.center = a;

        // let view = paper.view

        // var viewPosition = view.viewToProject(point);

        // let _ref1 = changeZoom(view.zoom, 1, view.center, viewPosition, 1);
        // var offset = _ref1[1];

        // view.center = view.center.add(offset);
    }
    function handleTouchZoom(x, y, delta, f) {
        var mousePosition = new paper.Point(x, y);

        let view = paper.view
        var viewPosition = view.viewToProject(mousePosition);
        var factor = 1.05 * (1 + Math.abs(f / 100))

        let _ref1 = changeZoom(view.zoom, delta, view.center, viewPosition, factor);
        var newZoom = _ref1[0];
        var offset = _ref1[1];

        let zm = checkZoomLimit(newZoom);
        view.zoom = zm.zm
        if (zm.reached) return

        view.center = view.center.add(offset);
        // paper.view.draw()

    }
    function handleMouseWheel(e) {
        if (e.ctrlKey && (gZoomEnabled || ctx.mode === "zoomIn" || ctx.mode === "panMode")) {
            // cross-browser wheel delta
            e.preventDefault()
            var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
            var mousePosition = new paper.Point(e.offsetX, e.offsetY);
            let view = paper.view
            var viewPosition = view.viewToProject(mousePosition);
            var factor = 1.05 * (1 + Math.abs(e.deltaY / 100))
            delta = delta * -1
            let _ref1 = changeZoom(view.zoom, delta, view.center, viewPosition, factor);
            var newZoom = _ref1[0];
            var offset = _ref1[1];

            let zm = checkZoomLimit(newZoom);
            view.zoom = zm.zm
            if (zm.reached) return
            //canvas.style.width = (origCanvasWidth * newZoom) + "px";
            //canvas.style.height = (origCanvasHeight * newZoom) + "px";            
            view.center = view.center.add(offset);
            sendPositionUpdate()

            // paper.view.draw()

            // var beforeLeft = parseInt((canvas.style.left.split("px"))[0]);
            // var beforeTop = parseInt((canvas.style.top.split("px"))[0]);
            // var beforeWidth = parseInt((canvas.style.width.split("px"))[0]);
            // var beforeHeight = parseInt((canvas.style.height.split("px"))[0]);

            // var canvasDiff = origCanvasWidth - (origCanvasWidth * newZoom);

            //canvas.style.left = Math.max(0, Math.round(beforeLeft+canvasDiff/2)) + "px";
            //canvas.style.top = Math.max(0, Math.round(beforeTop+canvasDiff/2)) + "px";
        }
        return false;
    }


    function handlePointerMove(x, y, e) {
        if (gLocked) return
        if (lastx === x && lasty === y) {
            return
        }
        lastx = x;
        lasty = y;
        if (ctx.mode !== "draw") {
            if (ctx && ctx.lazy) ctx.lazy.update({ x: x, y: y })
            if (ctx.mode === "panMode") return PanModeMove(x, y, e)
            if (ctx.mode === "zoomIn") return PanModeMove(x, y, e)
            if (ctx.mode === "move") return pathMove(x, y)

            if (ctx.mode === "edit") return objMove(x, y)
            if (ctx.mode === "line") {
                let rm = mylocalStorage.getItem('lineMode')
                if (rm && rm === 'Line') {
                    return lineMove(x, y, e, true)
                } else {
                } return lineMove(x, y, e);
            }

            if (ctx.mode === "rect") {
                let rm = mylocalStorage.getItem('rectMode')
                if (rm && rm === 'Square') {
                    return rectMove(x, y, e, true)
                } else {
                    return rectMove(x, y, e);
                }
            }

            if (ctx.mode === "circle") {
                let rm = mylocalStorage.getItem('circleMode')
                if (rm && rm === 'Circle') {
                    return circleMove(x, y, e, true);
                } else {
                    return circleMove(x, y, e);
                }
            }

            if (ctx.mode === "resize") return handleResize(x, y)
            if (ctx.mode === "rotate") return handleRotate(x, y)

            if (ctx.mode === "text") {
                return
            }

        }


        if (!session) {
            return
        }
        lastTap = 0
        ctx.lazy.update({ x: x, y: y })
        if (ctx.isPressing && !ctx.isDrawing) {
            ctx.isDrawing = true
            // ctx.points.push(ctx.lazy.brush.toObject())
            ctx.drawPath = null
            if (ctx.startPoint) {
                var sp = ctx.startPoint
                ctx.points.push({ x: sp.x, y: sp.y });
            } else {
                ctx.points.push({ x: x, y: y });
                return
            }
        }
        if (!ctx.isDrawing || ctx.mode === "erase") {
            sendPeers({
                type: "mouseMove", x: x, y: y, brush: ctx.brushRadius,
                color: mkColor(ctx.SavedColor, ctx.opacity), name: myName,
            })
        }

        if (ctx.isPressing && ctx.isDrawing) {

            if (!ctx.drawPath) {
                ctx.drawPath = new Path();
                ctx.drawPath.strokeColor = mkColor(ctx.color, ctx.opacity);
                ctx.drawPath.strokeWidth = ctx.brushRadius * 2;
                if (ctx.points && ctx.points.length > 0) {
                    let tempPoint = new Point(ctx.points[0].x, ctx.points[0].y)
                    ctx.drawPath.add(tempPoint);
                }

                updateDraw(true, false)
            }
            var tempPoint = new paper.Point(x, y)
            ctx.drawPath.add(tempPoint);

            // var lb = ctx.lazy.brush.toObject()
            ctx.mouseHasMoved = true
            if (ctx.mode === "erase") {
                return handleZap(e)
            }
            var len = ctx.points.length
            var lp = ctx.points[len - 1]
            ctx.points.push({ x: x, y: y })
            if (len % 5 === 0) ctx.drawPath.smooth({ type: 'continuous' });
            updateDraw(false, false)
            if (!gSyncDisabled) {
                sendPeers({
                    type: "mouseDraw", p1: { x: lp.x, y: lp.y }, p2: { x: x, y: y },
                    draw: ctx.isDrawing, brush: ctx.brushRadius, x: x, y: y,
                    color: mkColor(ctx.color, ctx.opacity), name: myName, id: myObj.id, dashArray: ctx.lineStyle
                })
            }
            // ctx.context.temp.moveTo(p2.x, p2.y)
            // ctx.context.temp.beginPath()

            // for (var i = 1, len = ctx.points.length; i < len; i++) {
            //     // we pick the point between pi+1 & pi+2 as the
            //     // end point and p1 as our control point
            //     var midPoint = midPointBtw(p1, p2)
            //     ctx.context.temp.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
            //     p1 = ctx.points[i]
            //     p2 = ctx.points[i + 1];
            // }
            // Draw last line as a straight line while
            // we wait for the next point to be able to calculate
            // the bezier control point
            // ctx.context.temp.lineTo(p1.x, p1.y)
            // ctx.context.temp.stroke()
        }

        ctx.mouseHasMoved = true
    }

    function clearCanvas() {
        ctx.valuesChanged = true
        ctx.context.drawing.clearRect(0, 0, ctx.canvas.drawing.width, ctx.canvas.drawing.height)
        // ctx.context.others.clearRect(0, 0, ctx.canvas.others.width, ctx.canvas.others.height)
        paper.project.activeLayer.removeChildren();
        clearGlobal()
        paper.view.draw();

    }

    const autoScroll = useSelector((state) => state.autoScroll);

    React.useEffect(() => {
        if (autoScroll) {
            var dt = new Date()
            var eventDat = new Date(autoScroll.updatedAt)
            var diff = dt - eventDat
            if (diff > 20000) {
                return
            }
            processScroll(autoScroll.Content.state)
        }
    }, [autoScroll])

    function drawScrollMove(mm) {
        if (mm.myID === myPeerID) return
        var p = peers[mm.myID]
        if (!p) {
            return
        }
        const m = mm.msg
        processScroll(m.scroll)
    }
    function processScroll(sc) {
        if (gScrollFollow) return // i am teacher 
        if (!sc.scroll) return

        // let s =sc.scroll
        // if (s.lastScrollUpdate < lastScrollUpdate) return 
        // lastScrollUpdate = s.lastScrollUpdate
        if (!paper || !paper.view) return
        paper.view.center = new Point(sc.scroll.x, sc.scroll.y)
        paper.view.zoom = sc.scroll.zoom
        var doc = document.querySelector('#canvas_container')
        doc.scroll(sc.scroll.left, sc.scroll.top)
    }
    function sendPositionUpdate() {

        if (!gScrollFollow) return

        function calcScroll(calc) {

            var x = paper.view.center.x, y = paper.view.center.y, zoom = paper.view.zoom
            var doc = document.querySelector('#canvas_container'),
                scrollPositionTop = doc.scrollTop,
                scrollPositionLeft = doc.scrollLeft

            if (gScrollFollow.x === x && gScrollFollow.y === y && gScrollFollow.zoom === zoom &&
                gScrollFollow.top === scrollPositionTop && gScrollFollow.left === scrollPositionLeft && !calc)
                return null

            //scrollbar 
            gScrollFollow = {
                x: x, y: y, zoom: zoom, left: scrollPositionLeft, top: scrollPositionTop
            }
            var position = {
                scroll: gScrollFollow
            }
            if (positionTimer && !calc) {
                sendPeers({
                    type: "scrollMove", scroll: gScrollFollow,
                    color: mkColor(ctx.SavedColor, ctx.opacity), name: myName,
                })
                return null
            }
            return position
        }

        let c = calcScroll(false)
        if (!c) return
        positionTimer = setTimeout(function () {
            let position = calcScroll(true)
            ib.sendClassEvent("SendPosition", position,
                gSession.parentID, gSession.parentID, null, gSession.ttl, true, null);
            positionTimer = null
            return
        }, 500)

    }
    function drawCursor(x, y) {
        if (ctx.mode === "erase") {
            if (!ctx.cursor) {
                // ctx.tempLayer.activate()
                var point = new Point(x - 15, y - 7);
                var size = new paper.Size(30, 15);
                var dial = new paper.Shape.Rectangle(point, size)
                dial.rotate(45);
                dial.data.cursor = true
                ctx.cursor = dial;
                // ctx.drawPath.strokeWidth = ctx.brushRadius * 2;
                // ctx.drawPath.strokeColor = mkColor(ctx.color, ctx.opacity);
                ctx.cursor.fillColor = styleVariables.colorPrimary
            }
            ctx.cursor.position.x = x
            ctx.cursor.position.y = y
            return //handleZap(e)
        }
        if (ctx.cursor) {
            ctx.cursor.remove()
            ctx.cursor = null
        }
    }
    function onFrame(step) {
        if (gpause) return
        //console.log("LAST OBJ", lastObj)
    }
    var debounceOff = null
    function handlePointerDown(e) {
        var leftClick = !e.button || e.button === 0
        if (gLocked) {
            if (Boolean(gTeacher)) {
                if (debounceOff) {
                    return
                }
                if (isApiMode) return
                alert("Please close sidebar from bottom left, when the side bar is open, the board is read only")
                debounceOff = setTimeout(function () {
                    clearTimeout(debounceOff)
                    debounceOff = null
                }, 5000)
            }
            return
        }
        if (ctx.mode === 'zoomIn') {
            ctx.isPressing = true
            return zoomClick(e)
        }

        if (ctx.mode === "dot") return DrawDotHere(e)
        if (ctx.mode === "line") {
            startLine(e)
            return
        }
        if (ctx.mode === "moveResize" && leftClick) {
            return startMoveResize(e)
        }
        if (ctx.mode === "rect" && leftClick) {
            startRect(e)
            return
        }
        if (ctx.mode === "circle") {
            startCircle(e)
            return
        }

        // get position 
        const [x, y] = getPoint(e)
        ctx.startPoint = { x: x, y: y }
        ctx.isPressing = true
        if (e.button === 2) {
            if (!isApiMode) handleButtonDraw('Erase')
            return
        }
    }

    function loop({ once = false } = {}) {
        if (ctx.mouseHasMoved || ctx.valuesChanged) {
            const pointer = ctx.lazy.getPointerCoordinates()
            drawCursor(pointer.x, pointer.y)
            //   drawInterface(ctx, pointer, brush)
            ctx.mouseHasMoved = false
            ctx.valuesChanged = false
        }

        if (!once) {
            window.requestAnimationFrame((step) => {
                // console.log("STEP", step)
                onFrame(step)
                loop()
            })
        } else {

        }
    }

    function getInsertIndex(no) {
        var children = paper.project.activeLayer.children;

        // Iterate through the items contained within the array:
        for (var i = 0; i < children.length; i++) {
            var child = children[i];
            if (child instanceof paper.Raster || child.data.stackwithtime) {
                if (child.data && child.data.createdAt && no.data.createdAt) {
                    if (no.data.createdAt > child.data.createdAt) continue
                } else {
                    continue
                }
            }
            if (child.data && child.data.background) continue
            if (child.data && child.data.grid) continue
            if (child.data && child.data.backGroundCol) continue
            return i
        }
    }

    function drawImage(obj) {
        // console.log("drawImage:", obj);
        if (!obj.content) return
        if (obj.id in paperObj) {
            return
        }
        // paperObj[obj.id] = { obj: null, type: "picture", gqlObj: obj }
        try {
            if ('type' in obj) {
                obj['type'] = JSON.parse(obj['type'])
                if (obj.type && obj.type.compressed) {
                    obj.content = pako.inflate(obj.content, { to: 'string' });
                }
            }
        } catch (e) {
            console.error("cannot decode", e, obj)
            return
        }
        const ct = JSON.parse(obj.content)
        var url = ct.url
        renderImage()

        function renderImage() {
            // url.replace("https://www.whiteboard.chat", "http://localhost:3001/")
            // console.log("renderImage called");
            var raster = new paper.Raster({ crossOrigin: 'anonymous', source: url });
            paperObj[obj.id] = { obj: raster, type: "picture", gqlObj: obj }

            raster.onError = function (f) {
                console.log('The image not loaded.', url, f);
                iu.GetImage(url).then((ff) => {
                    raster = new paper.Raster({ crossOrigin: 'anonymous', source: ff.img });
                    raster.onLoad = function () {
                        done(raster)
                    }
                })
            };
            // raster.position = paper.view.center;
            raster.onLoad = function () {
                done(raster)
            }
            function done(raster) {
                paperObj[obj.id] = { obj: raster, type: "picture", gqlObj: obj }
                raster.data.createdAt = new Date(obj.createdAt).getTime() / 1000
                let idx = getInsertIndex(raster)
                paper.project.activeLayer.insertChild(idx, raster);
                if (ct && ct.pdfText) {
                    raster.data.pdfText = ct.pdfText;
                }
                var xx = raster._size.width / 2
                var yy = raster._size.height / 2

                raster.position = new Point(xx + 100, yy + 100)
                raster.data.id = obj.id
                if (ct.type === "bingo") {
                    raster.data.bingo = true;
                    raster.data.lock = true;
                    updateDrawPaperObj(raster, donePaper)
                    function donePaper() {
                        raster.remove() // remove the original image 
                    }
                    return
                }
                attachTools(raster)
            }
        }
    }

    function loadImage(file) {
        if (gLocked) return
        var raster = new paper.Raster({ source: file.img, crossOrigin: 'anonymous' });
        raster.data.createdAt = new Date().getTime() / 1000
        let idx = getInsertIndex(raster)
        paper.project.activeLayer.insertChild(idx, raster);
        handleEscape()
        //raster.position = paper.view.center;

        raster.onLoad = function () {
            const id = uuid()
            myObj.id = id
            var xx = raster._size.width / 2
            var yy = raster._size.height / 2
            raster.position = new Point(xx + 80, yy + 90)
            paperObj[id] = { obj: raster, type: "picture", gqlObj: null }
            raster.data.id = id
            var content = {
                type: "image", points: { x: 0, y: 0 }, url: file.url,
                ended: true, color: mkColor(ctx.SavedColor, ctx.opacity),
                brushRadius: ctx.drawBrushRadius
            }
            const userid = gUser ? gUser.id : null
            objDict[id] = { lastIndex: 0, mine: true, obj: null }
            ib.createObject(id, "name", gSessionID, JSON.stringify(content), "image", userid, null, gSession ? gSession.ttl : 0).then(res => {
                console.error("loadImage:", file, res);
                const robj = res.data.createObject
                if (robj && robj.id && objDict[robj.id]) {
                    objDict[robj.id].obj = robj
                }
                delete robj["Session"]
                paperObj[id].gqlObj = robj
            })
        };
        raster.onError = function (f) {
            console.log('The image not loaded.', f);
        };

    }

    function HandleMoveResize() {
        handleEscape()
        ctx.mode = "moveResize"
        ctx.subMode = "move"
        ctx.canvas.interface.style.cursor = "move"
    }

    function startMoveResize(e) {
        selectedObj = getHitObj(e)
        var addBound = true
        if (selectedObj) {
            var skip = false
            if (selectedObj && selectedObj.data && selectedObj.data.lock) {
                clearSelect()
                return
            }
            var gg = findSelectedGQL()
            if (!gg) skip = true
            if (gg && gg.SessionID !== gSessionID) {
                skip = true;
                if (selectedObj.data.studentClone) {
                    clearAllSelected()
                    selectedObj.data.notSession = true
                    let rect = selectedObj.clone()
                    rect.data.id = null
                    rect.data.studentClone = false

                    rect.data.fixSize = selectedObj.data.fixSize;
                    selectedObj = rect
                    rect.selected = true
                    skip = false
                    addBound = false
                }
                if (selectedObj.data.studentMove) {
                    clearAllSelected()
                    let rect = selectedObj.clone()
                    rect.data.id = null
                    selectedObj.remove() //remove the parent 
                    rect.data.studentMove = false
                    rect.data.studentMoveParent = selectedObj.data.studentMoveParentID;
                    rect.data.cannotdelete = true
                    rect.data.fixSize = selectedObj.data.fixSize;
                    selectedObj = rect
                    rect.selected = true
                    skip = false
                    addBound = false
                }
                if (skip === true) {
                    clearSelect()
                    ctx.mode = "moveResize"
                    ctx.canvas.interface.style.cursor = ctx.subMode
                    return
                }
            } else {
                if (gg && gg.SessionID === gSessionID && ctx.subMode === "copy") {
                    clearAllSelected()
                    let rect = selectedObj.clone()
                    rect.data.id = null
                    if (selectedObj.data.studentMoveParentID) {
                        rect.data.studentMoveParentID = uuid() //new one for the clone 
                    }
                    selectedObj = rect
                    rect.selected = true
                    ctx.mode = "edit"
                    ctx.lastMode = "moveResize"
                    return
                }

            }
            if (selectedObj.data.boundingObj) {
                if (selectedObj.data.boundingResize) {
                    var rect = selectedObj.data.boundingObj
                    selectedObj = rect;
                    var pp = getPaperPoint(e)
                    if (rect && rect.data) {
                        ctx.mode = "resize"
                        ctx.lastMode = "moveResize"
                        rect.data.state = 'resizing';
                        rect.data.bounds = rect.bounds.clone();
                        rect.data.scaleBase = pp.subtract(rect.bounds.center);
                    }
                    return
                }
                if (selectedObj.data.boundingRotate) {
                    let rect = selectedObj.data.boundingObj
                    selectedObj = rect;
                    let pp = getPaperPoint(e)
                    if (rect && rect.data) {
                        rect.data.state = 'rotating';
                        rect.data.lastPoint = pp;
                        ctx.mode = "rotate"
                        ctx.lastMode = "moveResize"
                    }
                    return
                }
                if (selectedObj.data.c1) {
                    //only allowed if its not a student dragging a clone obj
                    selectedObj = selectedObj.data.boundingObj; // drag by line 
                } else {
                    selectedObj = null
                }
            }
        }
        if (selectedObj) {
            if (ctx.AutoMove && ctx.mode !== "moveResize" && addBound) {
                ctx.mode = "moveResize"
            } else {
                ctx.lastMode = "moveResize"
                ctx.mode = "edit"
            }
            if (addBound) createBound(selectedObj, true, true)
        } else {
            if (ctx.AutoMove) {
                ctx.mode = ctx.AutoMove
                delete ctx['AutoMove']
            }
            clearBound()
        }
    }
    var debouceBRect = null

    function clearBBound() {
        clearTimeout(debouceBRect);
        if (boundBRect) {
            boundBRect.remove()
            boundBRect = null
        }
    }


    function handleBClicked(e) {
        if (boundBRect && boundBRect.data.linkData && boundBRect.data.linkData.link) {
        }
        clearBBound()
    }
    function createButton(pobj) {
        clearBBound()
        // selectedObj = pobj
        debouceBRect = setTimeout(function () {
            clearBBound()
            return
        }, 2000)
        var ff = pobj.bounds
        var rr = {}
        rr.x = ff.x - 5
        rr.y = ff.y - 5
        rr.height = ff.height + 10
        rr.width = ff.width + 10
        let rectanglePath = new Path.Rectangle(rr);
        rectanglePath.strokeColor = ctx.SavedColor;
        rectanglePath.strokeWidth = 3
        rectanglePath.dashArray = dashArray
        rectanglePath.data.boundingObj = pobj
        boundBRect = rectanglePath
        boundBRect.data.linkData = pobj.data.linkData
        boundBRect.selected = false
    }



    const cbs = {
        'loadImage': loadImage,
        'Draw': handleButtonDraw, 'Erase': handleButtonDraw,
        'Text': handleText, 'Circle': handleCircle, 'Line': handleLine,
        "Message": (m) => aniMess(m),
        'Rect': handleRect, 'ColorPicker': handleColorPicker,
        'Move & Resize': HandleMoveResize, 'simpleColor': handleSimpleColor,
        'TableFactory': TableFactory, 'DrawBrush': handleButtonDraw, 'ZoomIn': ZoomInApiMode, 'Pan': PanMode
    }

    const [peerDiag, setPeerDiag] = React.useState({ open: false, peer: null, cb: null })
    function answerCall(call) {
        setPeerDiag({ open: true, peer: null, gPeer: gPeer, cb: gotBall, call: call })
        function gotBall() {
            setPeerDiag({ open: false, peer: null, gPeer: null, cb: null, call: null })
        }
    }


    const [tableFactory, setTableFactory] = React.useState({ open: false, cb: null })

    function drawTable(e) {
        const tableDim = { x: 120, y: 100, totalw: 800, h: 70 }
        var r = parseInt(e.text.row)
        var c = parseInt(e.text.column)
        var obj = []
        var col = mkColor(ctx.color, ctx.opacity)
        var ycor = tableDim.y
        var colSize = tableDim.totalw / c
        if (e.control.makeSquareCells) {
            colSize = 80
            tableDim.totalw = colSize * c
        }
        for (let rr = 0; rr < r; rr++) {
            var leftPoint = new paper.Point(tableDim.x, ycor);
            var rightPoint = new paper.Point(tableDim.x + tableDim.totalw, ycor);
            let line = new paper.Path.Line(leftPoint, rightPoint);
            line.strokeColor = col;

            if (e.control.firstRowHeader && rr === 1) {
                line.strokeWidth = ctx.brushRadius * 2;
                let rectanglePath = new Path.Rectangle(new paper.Point(tableDim.x, tableDim.y), rightPoint);
                rectanglePath.fillColor = mkColor(ctx.color, 7)
                rectanglePath.data.tableRowLine = { rowH: tableDim.h, colH: colSize, columns: c }
                obj.push(rectanglePath)
            } else {
                if (rr !== 0) {
                    let rectanglePath = new Path.Rectangle(new paper.Point(tableDim.x, ycor - tableDim.h),
                        rightPoint);
                    rectanglePath.fillColor = mkColor(ctx.color, 2)
                    rectanglePath.data.tableRowLine = { rowH: tableDim.h, colH: colSize, columns: c }
                    obj.push(rectanglePath)
                }
                line.strokeWidth = 2;
            }
            obj.push(line)
            ycor += tableDim.h
        }
        //last row
        rightPoint = new paper.Point(tableDim.x + tableDim.totalw, ycor);
        let rectanglePath2 = new Path.Rectangle(new paper.Point(tableDim.x, ycor - tableDim.h),
            rightPoint);
        rectanglePath2.fillColor = mkColor(ctx.color, 2)
        rectanglePath2.data.tableRowLine = { rowH: tableDim.h, colH: colSize, columns: c }
        obj.push(rectanglePath2)

        var xcor = tableDim.x + colSize
        for (let rr = 0; rr < c; rr++) {

            leftPoint = new paper.Point(xcor, tableDim.y);
            rightPoint = new paper.Point(xcor, ycor);
            let line = new paper.Path.Line(leftPoint, rightPoint);
            line.strokeColor = col;
            line.strokeWidth = 2;
            xcor += colSize
            obj.push(line)
        }

        var rr = { x: tableDim.x, y: tableDim.y, width: tableDim.totalw, height: tableDim.h * r }
        var cornerSize = new paper.Size(5, 5);
        var rrT = new paper.Rectangle(rr)
        let rectanglePath = new Path.Rectangle(rrT, cornerSize);
        rectanglePath.strokeColor = col;
        rectanglePath.strokeWidth = ctx.brushRadius * 2;
        obj.push(rectanglePath)

        var g1 = new paper.Group(obj)
        g1.data.table = true
        g1.data.stackwithtime = true
        updateDrawPaperObj(g1, donePaper)
        function donePaper(nObj) {
            g1.remove()
            obj.forEach((o) => {
                o.remove()
            })
        }
    }
    function TableFactory() {
        setTableFactory({ open: true, cb: done })
        function done(e) {
            setTableFactory({ open: false, cb: null })
            if (!e) {
                return
            }
            drawTable(e)
        }
    }


    async function textEdit(e, save) {
        if (!e) {
            dispatch(Actions.setRichText({ open: false, object: null, cb: null, loc: null }))
            return
        }
        var rr = await iu.writeFileReturn(e)

        var raster = new paper.Raster({ crossOrigin: 'anonymous', source: rr.img });
        raster.data.createdAt = new Date().getTime() / 1000
        let idx = getInsertIndex(raster)
        paper.project.activeLayer.insertChild(idx, raster);
        raster.data.richText = true
        raster.data.saved = save
        raster.onLoad = function () {
            var xx = raster._size.width / 2
            var yy = raster._size.height / 2

            raster.position = new Point(xx + richText.location.x, yy + richText.location.y)
            updateDrawPaperObj(raster, done)
            function done(no) {
                // clearSelect()
                raster.remove()
                dispatch(Actions.setRichText({ open: false, object: null, cb: null, loc: null }))
            }
            // raster.data.id = obj.id
            // paperObj[obj.id] = { obj: raster, type: "picture", gqlObj: obj }
        }
        raster.onError = function (f) {
            console.log('The image not loaded.', f);
        };

    }

    return (
        <>
            {colorPicker.pickerOpen && (<BoardColorPicker {...colorPicker} inkColor={inkColor} setColorPicker={setColorPicker} ctx={ctx} isApiMode={isApiMode} />)}
            {tableFactory.open && <TableFactoryDialog {...tableFactory} inkColor={inkColor} />}

            {peerDiag.open && <PeerConnection inkColor={inkColor} {...peerDiag} />}

            <div className='flex'>
                <MiniDrawer {...props} inkColor={inkColor} cb={cbs} drawMode={drawMode} eraseMode={eraseMode}
                    sessionId={props.match.params.boardid} sess={sess} user={user} gSession={gSession}
                    userRole={userRole}
                    mutlibcfg={multBoardCfg}
                 setopenTip={setopenTip} openTip={openTip}
                    setParentBoardData={setParentBoardData} parentBoardData={parentBoardData} welcomeTourOpen={overlayHelpOpen} isApiMode={isApiMode} penBtnColor={penBtnColor} />
            </div>


            <div style={{ overflow: 'hidden' }}>
                <div id="Canvas" style={{ display: tabValue.display0 }}>


                    <div className={clsx('canvas-container', 'canvContainer')} id="canvas_container">

                        <div id="message">
                        </div>
                        {/* <canvas className={mobSize} tabIndex="0" id="canvas_interface"></canvas> */}
                        <div id='Cc'>
                            <canvas className={canvasClass}
                                id="canvas_drawing" tabIndex="0"
                                style={{ width: "2000px", height: "1280px" }}>
                            </canvas>

                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default WhiteBoard