import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import {
    List,
    ListItem,
    ListItemText,
    Button, Divider, IconButton
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import * as ib from "./ibdata"
import ClearAllIcon from '@material-ui/icons/ClearAll';
import * as mylocalStorage from "./mylocalStorage";
const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
    },
    inviteButton: {
        backgroundColor: "#3174F5",
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: '5px',
        margin: "5px",
        padding: "5px",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    iconButtonClear: {
        backgroundColor: '#3174F5',
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        color: '#ffffff',
        margin: "0 auto",
        padding: "0",
        width: "100%",
        borderRadius: '5px',
        "&:hover": {
            color: '#3174F5',
        },
    },
    paper: {
        position: "absolute",
        top:"80px",
        border: '6px solid',
        borderRadius: "25px",
        backgroundColor: "#ffffff",
        padding: theme.spacing(1),
        maxHeight: "56vh",
        width: "auto",
        overflow: "auto",
        minWidth: "120px",
        left: "-60px",
    },
    typography: {
        padding: theme.spacing(2),
    },
}));

var gevents = {}
export default function EventPopper(props) {
    const [open3, setOpen] = React.useState(true);
    const [events, setEvents] = React.useState({});
    const classes = useStyles();
    const teacherR = useSelector((state) => state.teacher);
    const boardChat = useSelector((state) => state.boardChat);

    const tpopperRef = React.useRef(null)
    const [ref, setRef] = React.useState(props.popperRef)
    // const handleClick = (newPlacement) => (event) => {
    //     setAnchorEl(event.currentTarget);
    //     setOpen((prev) => placement !== newPlacement || !prev);
    //     setPlacement(newPlacement);
    // };
    const dispatch = useDispatch();

    React.useEffect(() => {
        var s1, s2; 
        if (!props.sess) return 
        let sub = props.sess.Classroom ? props.sess.Classroom : props.sess.parentID
        function classroomEventSubCB(classroomEventSub) {
            var f = classroomEventSub
            s1 = f[0]
            s2 = f[1]
        }

        ib.SubscribeClassroomEventByClass({
            "ClassroomID": sub,
            "cb": gotEvent,
            "doList": true,
            "delCB": delEvent, 
            "subCB": classroomEventSubCB
        });
        return () => {
            if (s1) s1.unsubscribe()
            if (s2) s2.unsubscribe()
            gevents = {}
        }
        /* eslint-disable react-hooks/exhaustive-deps  */
    }, [props.sess, teacherR])
    function processHand(e, luid, rid) {
        if (teacherR || rid === luid) {
            dispatch(Actions.assistanceRequest(e))
        }
    }
    function processTimer(e) {
        if ((e.For === props.sess.Classroom) ||
            (e.For === props.sess.parentID)) {
            dispatch(Actions.setTimerEvent(e))
        }
    }
    function processLock(e) {
        if ((e.For === props.sess.Classroom) ||
            (e.For === props.sess.parentID)) {
            dispatch(Actions.setBoardLock(e))
        }
    }
    function processDice(e) {
        if ((e.For === props.sess.Classroom) ||
            (e.For === props.sess.id)) {
            var teacher = false
            if (e.For === props.sess.Classroom) teacher = true
            e.teacherDice = teacher
            if (e.Content.autoLocalEnable && props.sess.id !== props.sess.parentBoardID) {
                // auto create local dice if it doesnt exist 
                ib.sendClassEvent(e.type, e.State, props.sess.Classroom, props.sess.id,
                    e.Content.value, props.sess.ttl, false, donecb)
            } else {
                dispatch(Actions.showDice(e))
            }
            function donecb(e) {
                // timing isse on create and subscribe
                gotEvent([e])
            }
        }
    }
    async function processChat(e) {
        var luid = mylocalStorage.getItem('mystoreID');

        if ((e.For === props.sess.parentID) ||
            (e.For === props.sess.Classroom)) {
            var sendChat = false
            if (teacherR && props.sess.parentID === props.sess.Classroom) sendChat = true
            if (!sendChat && e.For !== props.sess.Classroom) {
                // child in group board or individual board
                if (props.sess.Classroom && (teacherR || props.sess.isGroup || e.Content.luid === luid))
                    sendChat = true
            }
            if (!sendChat && e.Content && e.Content.isTeacher) {
                // message from teacher from class board
                sendChat = true
            }
            if (sendChat) {
                var index = boardChat.messages.findIndex(id => id === e.id)
                if (index === -1) {
                    var rr = mylocalStorage.getItem("chatLang")
                    if (rr && rr !== "default") {
                        var r = await ib.gTranslate(e.Content.msg, rr)
                        if (r) {
                            e.Content.msg = r
                        }
                    }
                    dispatch(Actions.setChatMsg(e))
                }
            }
        }
    }
    function processSpinner(e) {
        if ((e.Classroom === props.sess.Classroom) ||
            (e.For === props.sess.id)) {
            dispatch(Actions.spinnerEvent(e))
        }
    }
    function processFdice(e) {
        if ((e.Classroom === props.sess.Classroom) ||
            (e.For === props.sess.id)) {
            dispatch(Actions.fDiceEvent(e))
        }
    }
    function processLetItSnow(e) {
        if ((e.Classroom === props.sess.Classroom) ||
            (e.For === props.sess.id)) {
            dispatch(Actions.letItSnowEvent(e))
        }
    }

    function processFF(ff, ev, mine) {
        if (ev.State === "Clear") {
            delete ff[ev.event]
            return
        }

        if (mine) {
            if (ev.State === "Voted") {
                // i already voted
                ff[ev.event].voted = true
                //summerize the rest 
            } else {
                //i didnt vote yet show all the buttons 
                ff[ev.event].voted = false
            }
            ff[ev.event].mine = ev
        }
        if (ff[ev.event].voted) {
            // summerize 
            ff[ev.event].summary = {}
            for (let i in ff[ev.event].events) {
                const r = ff[ev.event].events[i]
                if (r.State === "Voted") {
                    if (r.Content.text in ff[ev.event].summary) {
                        ff[ev.event].summary[r.Content.text]++
                    } else {
                        ff[ev.event].summary[r.Content.text] = 1
                    }
                }
            }
        }
    }

    function delEvent(ev) {
        if (ev && ev.type && ev.type === "Chat") {
            ev.Content = JSON.parse(ev.Content)
            ev.deleted = true 
            dispatch(Actions.setChatMsg(ev))
            setTimeout(function () {
                window.location.reload()
            }, 300);
        }
    }
    function gotEvent(evs) {
        var luid = mylocalStorage.getItem('mystoreID');

        var ff = { ...gevents }
        for (let i = 0; i < evs.length; i++) {
            var ev = evs[i]

            if (ev && ev.type && ev.type === "SendPosition") {
                ev.Content = JSON.parse(ev.Content)
                dispatch(Actions.addAutoScroll(ev))
                continue
            }
            if (ev && ev.type && ev.type === "Chat") {
                ev.Content = JSON.parse(ev.Content)
                processChat(ev)
                continue
            }
            const rid = ev.For.split(":")[0]
            const rname = ev.For.split(":")[1]
            ev.name = rname
            ev.Content = JSON.parse(ev.Content)
            if (ev && ev.type && ev.type === "RaiseHand") {
                processHand(ev, luid, rid)
                continue
            }
            if (ev && ev.type && ev.type === "Timer") {
                processTimer(ev)
                continue
            }
            if (ev && ev.type && ev.type === "LockClass") {
                dispatch(Actions.addAllEvents(ev))
                processLock(ev)
                continue
            }
            if (ev && ev.type && ev.type === "FORMSUBMIT") {
                dispatch(Actions.addFormSubmit(ev))
                continue
            }
            if (ev && ev.type && ev.type.indexOf("LockPage") !== -1) {
                dispatch(Actions.setPageLock(ev))
                continue
            }
            if (ev && ev.type && ev.type === "SendDice") {
                processDice(ev)
                continue
            }
            if (ev && ev.type && ev.type === "Spinner") {
                processSpinner(ev)
                continue
            }
            if (ev && ev.type && ev.type === "LetItSnow") {
                processLetItSnow(ev)
                continue
            }
            if (ev && ev.type && ev.type === "Fdice") {
                processFdice(ev)
                continue
            }
            if (ev && ev.type && ev.type === "SHOWCASE") {
                dispatch(Actions.setShowcase(ev))
                continue
            }
            if (ev && ev.type && ev.type === "FollowTeacher") {
                dispatch(Actions.setFollow(ev))
                continue
            }
            if (ev.Content.action === "link") {
                ev.Render = ev.Content.text
            }
            if (ev.Content.action === "poll") {
                var ffd = ev.Content.text.split("?")
                if (ffd && ffd.length > 1) {
                    var ans = ffd[1].split(",")
                    ev.RenderButtons = ans
                    ev.Render = ffd[0] + "?"
                } else {
                    ev.RenderButtons = []
                    ev.Render = ""
                }
            }
            if (ev.Content.action === "vote") {
                let ans = ev.Content.text.split(",")
                var mine = false
                if (rid === luid) mine = true

                ev.RenderButtons = ans
                if (ev.event in ff) {
                    ff[ev.event].events[ev.id] = ev
                } else {
                    ff[ev.event] = { events: {}, type: ev }
                    ff[ev.event].events[ev.id] = ev
                }
                if (ev.State !== "Voted") ff[ev.event].choices = ans
                processFF(ff, ev, mine)
                continue
            }
            if (ev.Content.action === "press") {
                let ffd = ev.Content.text.split("?")
                if (!ffd || ffd.length < 2) {
                    ev.Render = ""
                    ev.Ans = ""
                } else {
                    ev.Render = ffd[0] + "?"
                    ev.Ans = ffd[1]
                }
            }

            if (ev.event in ff) {
                if (ev.id in ff[ev.event].events) {
                    ff[ev.event].events[ev.id] = ev
                    if (ev.State === "Clear") delete ff[ev.event]
                    if (!teacherR) {
                        if (ev.State === "Done") {
                            delete ff[ev.event]
                        }
                    }
                } else {
                    if (teacherR || rid === luid) {
                        ff[ev.event].events[ev.id] = ev
                        if (ev.State === "Clear") delete ff[ev.event]
                        if (!teacherR) {
                            if (ev.State === "Done") {
                                delete ff[ev.event]
                            }
                        }
                    }
                }
            } else {
                if (teacherR || rid === luid) {
                    ff[ev.event] = { events: {}, type: ev }
                    ff[ev.event].events[ev.id] = ev
                    if (ev.State === "Clear") delete ff[ev.event]
                    if (!teacherR) {
                        if (ev.State === "Done") {
                            delete ff[ev.event]
                        }
                    }
                }
            }
        }
        gevents = ff
        setEvents(ff)
    }
    function actionButton(e) {
        if (e.Content && e.Content.action === "link") {
            window.open(e.Content.text)
            var copy = JSON.parse(JSON.stringify(e))
            delete copy['Content']
            copy['State'] = "Done"
            ib.updateClassroomEvent(copy)
        }
    }
    function SelectButton(e, b) {
        var copy = JSON.parse(JSON.stringify(e))

        copy['Content'] = JSON.stringify({ action: "press", text: e.Render + b })
        copy['State'] = "Done"
        ib.updateClassroomEvent(copy)
    }
    function VoteButton(ev, b) {
        var copy = JSON.parse(JSON.stringify(events[ev].mine))

        copy['Content'] = JSON.stringify({ action: "vote", text: b })
        copy['State'] = "Voted"
        ib.updateClassroomEvent(copy)
    }

    function togglePolls(e) {
        setOpen(!open3)
    }

    React.useEffect(() => {
        var reff = teacherR && tpopperRef && tpopperRef.current ? tpopperRef.current : props.popperRef
        setRef(reff)
    }, [teacherR, tpopperRef.current])
    function clearAll() {
        for (let ev in events) {
            for (let e in events[ev].events) {
                const cp = events[ev].events[e]
                var copy = JSON.parse(JSON.stringify(cp))
                delete copy['Ans']
                var ct = copy['Content']
                copy['Content'] = JSON.stringify({ action: "clear" , ...ct})
                copy['State'] = "Clear"
                ib.updateClassroomEvent(copy)
            }
            // var sr = assistanceRequest.pendingList[i]
            // delete sr['Content']
            // sr['State'] = "Done"
            // ib.updateClassroomEvent(sr)
        }
    }
    const open = Object.keys(events).length > 0
    const id = open ? 'transitions-popper' : undefined;
    const pollButton = Boolean(teacherR) && open
    if (!tpopperRef) return (
        <>
        </> 
    )
    return (
        <div>
            {pollButton && (
                <Button ref={tpopperRef} className={classes.inviteButton} style={{ backgroundColor: props.inkColor }}
                    onClick={togglePolls}>Polls</Button>
            )}
            <Popper id={id} open={open && open3} anchorEl={ref} >
                <div className={classes.paper} style={{ borderColor: props.inkColor }} >
                    {Boolean(teacherR) && (
                        <IconButton className={classes.iconButtonClear} onClick={clearAll}>
                            <ClearAllIcon />
                                        Clear All
                        </IconButton>
                    )}
                    {Object.keys(events).map((ev) => (
                        <React.Fragment key={ev}>
                            {events[ev].type.Content.action === "vote" ? (
                                <>
                                    {events[ev].voted ? (
                                        <List dense key={ev}>
                                            {Object.keys(events[ev].summary).map((k) => (
                                                <ListItemText
                                                    primary={k + " : " + events[ev].summary[k]}
                                                />
                                            ))}
                                        </List>
                                    ) : (
                                            <List dense key={ev}>
                                                {events[ev].choices && events[ev].choices.map((e) => (
                                                    <ListItem key={e} component="button" button onClick={() => VoteButton(ev, e)}
                                                        className={classes.inviteButton}
                                                        style={{ backgroundColor: props.inkColor }}
                                                    >
                                                        {e}
                                                    </ListItem>
                                                ))}
                                            </List>
                                        )}
                                </>
                            ) : (
                                    <>
                                        <div> {events[ev].type.Render} </div>
                                        <List dense key={ev}>
                                            {Object.keys(events[ev].events).map((e) => (
                                                <ListItem dense key={e}>
                                                    {teacherR ? (
                                                        <>
                                                            <ListItemText primary={events[ev].events[e].name} secondary={events[ev].events[e].State} />
                                                            {events[ev].events[e].Ans && (
                                                                <div> {events[ev].events[e].Ans} </div>
                                                            )}

                                                        </>
                                                    ) : (
                                                            <>
                                                                {events[ev].events[e].Content.action === "link" && (
                                                                    < Button
                                                                        className={classes.inviteButton}
                                                                        fullWidth
                                                                        onClick={() => actionButton(events[ev].events[e])}>
                                                                        Click To Open
                                                                    </Button>
                                                                )}
                                                                {events[ev].events[e].Content.action === "poll" && (
                                                                    <>
                                                                        {events[ev].events[e].RenderButtons.map((b) => (
                                                                            <Button className={classes.inviteButton} key={b} onClick={() => SelectButton(events[ev].events[e], b)}>
                                                                                {b}
                                                                            </Button>
                                                                        ))}
                                                                    </>
                                                                )}

                                                            </>
                                                        )}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                )}
                            <Divider />
                        </React.Fragment>
                    ))}

                </div>
            </Popper>
        </div >
    )
}