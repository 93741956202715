import React, { Suspense } from 'react';
import './App.css';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import WhiteBoard from './WhiteBoard'

import { Auth } from 'aws-amplify'
import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import { ThemeProvider } from '@material-ui/core/styles'
import { createStore } from 'redux';
import reducer from "./store/reducer"
import { Provider } from 'react-redux'
import * as Actions from "./store/actions"
// import Privacy from './Home/Privacy';
// import TermsOfService from './Home/TermsOfService';
import * as ib from "./ibdata";
import StackTrace from 'stacktrace-js';
import { DateUtils } from '@aws-amplify/core';
import * as config from './Config'
import NotFound from "./NotFound"
//Max clock offset in milliseconds. Using 2 Minutes
const MAX_CLOCK_OFFSET = 2 * 60 * 1000
// Enable debugging for AWS SDK
// window.LOG_LEVEL = 'DEBUG';

const OBS = process.env.REACT_APP_OBS
const store = createStore(reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: {
      main: "#1CB1C4"
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 7,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          'scrollbar-width': 'thin',
        },
        '*::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '*::-webkit-scrollbar-track': {
          background: 'white'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'grey',
          borderRadius: '2px',
        },
      }
    }
  }
});

// import { withAuthenticator } from '@aws-amplify/ui-react'

export const whiteboardChatHomeURL = window.location.origin // Used by MS Teams App
// export const whiteboardChatHomeURL = "https://localhost:3000" // Used by MS Teams App For Dev
export var gMSTeamsContext = null;
window.insideMSTeams = undefined;

function App(props) {
  const [userLoaded, setUserLoaded] = React.useState(false);
  React.useLayoutEffect(() => {
    var cUser = null;
    if (process.env && process.env.NODE_ENV === "development") {
      cUser = config.configs[0].CommunityEmailId
    } else {
      cUser = config.configs[1].CommunityEmailId
    }
    if (cUser) {
      ib.findUserProfile(cUser, cbUserProfile)
    }

    Auth.currentUserCredentials().then((cred) => {
      try {
        var serverDate = new Date(cred.expiration)
        var smill = serverDate.getTime()
        var nowTime = new Date()
        var nmill = nowTime.getTime()
        var offset = smill - (nmill + 3600 * 1000)
        // If the local clock is ahead by  more than MAX OFFSET
        if (Math.abs(offset) > MAX_CLOCK_OFFSET) {
          DateUtils.setClockOffset(offset)
        }
      } catch { }
    })
      .catch(e => console.log('error: ', e))

    Auth.currentUserInfo()
      .then((d) => {
        setUserLoaded(true);
        store.dispatch(Actions.setUser(d))
        if (d && d.username) {
          ib.getUserProfie(d.username).then((res) => {
            if (res && res.data && res.data.getUserProfile) {
              store.dispatch(Actions.setUserProfile(res.data.getUserProfile));
            }
          })
        }
        if (OBS) store.dispatch(Actions.setNoUser())
      })
      .catch(e => console.log('error: ', e))
    window.onerror = function (message, file, line, column, error) {
      var callback = function (stackframes) {
        var firstStack = null;
        var stringifiedStack = stackframes.map(function (sf) {
          var retMsg = sf.toString();
          if (!firstStack) {
            firstStack = retMsg;
          }
          return retMsg;
        }).join('\n');
        var err = {
          name: "exception Stack",
          content: message + '\n' + stringifiedStack
        }
        ib.createErrorLog(err)
      };

      var errback = function (err) {
        try {
          /*
          var tmpMsg = error ? error.stack : "argument error is null";
          var err2 = {
            name: "exception failed stack",
            content: `${err}:\nORIG: ${message}\nORIG_ERROR: ${tmpMsg}`
          }
          ib.createErrorLog(err2)
          */
        } catch {
          console.log("ERROR IN ERROR")
        }
      };
      StackTrace.fromError(error).then(callback).catch(errback);

      // _gaq.push(['_trackEvent', "Global", "Exception", file + "(" + line + "): " + message])
    }

    // window.onunhandledrejection = function (e) {
    //   var callback = function(stackframes) {
    //     var firstStack = null;
    //     var stringifiedStack = stackframes.map(function(sf) {
    //         var retMsg = sf.toString();
    //         if (!firstStack) {
    //           firstStack = retMsg;
    //         }
    //         return retMsg;
    //     }).join('\n');
    //     var err = {
    //       name: "unhandledRejection stack",
    //       content: e.reason.stack.slice(0, e.reason.stack.indexOf('\n')) + '\n' + stringifiedStack
    //     }
    //     ib.createErrorLog(err)
    //   };

    //   var errback = function(err) {
    //     var tmpMsg = "e.reason is null";
    //     var tmpMsg2 = "e.reason is null";
    //     if (e && e.reason) {
    //       tmpMsg = e.reason.stack;
    //       tmpMsg2 = e.reason.message;
    //     }
    //     var err3 = {
    //       name: "unhandledRejection failed stack",
    //       content: `${err}:\nORIG: ${tmpMsg2}\nORIG_ERROR: ${tmpMsg}`
    //     }
    //     ib.createErrorLog(err3)
    //   };

    //   StackTrace.fromError(e.reason).then(callback).catch(errback);
    // }
  }, [])

  function cbUserProfile(up) {
    if (up && up.length > 0) {
      store.dispatch(Actions.setCommunityUser(up))
    }
  }



  return (
    <div >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {userLoaded && (
            <>
              <Router>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route path="/boards/:boardid" component={WhiteBoard} />
   
                  <Route component={NotFound} />
                </Switch>
                </Suspense>
              </Router>
            </>
          )}
        </ThemeProvider>
      </Provider>
    </div>

  );
}

//export default withAuthenticator(App)
export default App
