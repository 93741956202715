import React from 'react';
// import "./home.css"
import { makeStyles } from '@material-ui/core/styles';
import {
    Button
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        backgroundColor: "#3174F5",
        fontSize: "3rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        color: "#ffffff",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    homeButton: {
        backgroundColor: "#ffffff",
        fontSize: "2rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        borderRadius: '5px',
        margin: "5px",
        padding: "5px",
        color: "#3174F5",
        "&:hover": {
            color: 'yellow',
        },
    },
}))
export default function NotFound(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div style={{width:"80%"}}>
                Page Not Found
            </div>
            <Button className={classes.homeButton} onClick={() => { props.history.push("/") }}>
                Home
                    </Button>
        </div>
    )
}