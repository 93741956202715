import * as ib from './ibdata'
import * as iu from "./ImageUtils"
import pako from 'pako'
import JSZip from 'jszip'

export async function loadFile(file, user, cb) {
    const files = file.target.files
    if (files.length !== 1) return
    cb("Importing file, please wait")
    let isJsonFile = false
    let impFile = files && files[0]
    if (impFile && impFile.type === "application/json") {
        isJsonFile = true
        var fr = new FileReader();
        fr.onload = function (e) {
            var result = JSON.parse(e.target.result);
            if (result && result.length > 0) {
                HandleImport(result, user, cb, isJsonFile)
            }else{
                cb('Invalid whiteboard.chat file')
            }
        }
        fr.readAsText(impFile);
        file.target.files = null
    } else {
        var unZippedFiles = []
        if (impFile.name.includes('.wbc') || impFile.type === 'application/x-zip-compressed' || impFile.type === 'application/zip') {
            JSZip.loadAsync(files.item(0)).then(function (zip) {
                let noOfFiles = Object.keys(zip.files).length
                Object.keys(zip.files).forEach(function (filename) {
                    zip.files[filename].async('string').then(function (fileData) {
                        let obj = fileData
                        try {
                            if (filename.includes('.json')) {
                                var result = JSON.parse(fileData);
                                obj = { key: 'jsondata', value: result }
                            } else {
                                obj = { key: filename, value: fileData }
                            }
                            unZippedFiles.push(obj)
                            if (noOfFiles === unZippedFiles.length) {
                                HandleImport(unZippedFiles, user, cb, isJsonFile)
                            }
                        } catch {
                            cb('Invalid whiteboard.chat file')
                        }
                    })
                })
            })
        }else{
            cb('Imported file does not appear to be in whiteboard.chat format.')
        }
 
    }
}
    
    function deepReplaceSourceURLs(content) {
    if (content && content[0] && (typeof content[0] === 'string' || content[0] instanceof String)) {
        if (content[0] === "Group") {
            if (content[1].children) {
                content[1].children.forEach(c => deepReplaceSourceURLs(c))
            }
        }
        if ((content[1] && content[1].data && content[1].data.palette) ||
            (content[0] === "Raster" && content[1].source && content[1].source !== "")) {
            var newUrl = new URL(content[1].source)
            var source = window.location.origin + newUrl.pathname
            if(newUrl.pathname && !newUrl.pathname.includes('public')){
                //this checking public inside path is to exclude the my objects
                content[1].source = source
            }
        }
    }
}


function getFileExt(buff){
    if(buff){
        let spltStr = buff.key.split(' ')
        let type = 'image/png'
        if(spltStr[spltStr.length - 1]){
            switch (spltStr[spltStr.length - 1]) {
                case 'image':
                    type = 'image/png'
                    break;
                case "audio":
                    type = 'mp4'
                    break;
                case "video":
                    type = 'mp4'
                    break;
                case "mp4":
                    type = 'mp4'
                    break;
                default:
                    break;
            }
        }
        return type
    }
}

function HandleImport(unZippedFilesOrJsonData, user, cb, isJsonFile) {
    var importData
    try {
        if (isJsonFile) {
            importData = unZippedFilesOrJsonData
        } else {
            var jsData = unZippedFilesOrJsonData.find((f) => f.key === 'jsondata')
            importData = jsData.value
        }
    } catch (err) {
        cb('Invalid whiteboard.chat file')
        return
    }
    var sesssions, objects
    var randompages = 'none'
    try {
        sesssions = importData.find(i => i.objType === "session")
        objects = importData.find(i => i.objType === "objects")
    } catch (err) {
        cb('Invalid whiteboard.chat file')
        return
    }

    var promiseArr = [];
    if (objects.data && objects.data.length > 0) {
        objects.data.forEach(element => {
            try{
            const name = 'new file'
            if (element.objType === 'image') {
                let buffer;
                if(isJsonFile){
                    const i = element.imgB64.indexOf('base64,');
                    buffer = Buffer.from(element.imgB64.slice(i + 7), 'base64');
                }else{
                    const objContent = unZippedFilesOrJsonData.find((u) => u.key === element.fileName)
                    const i = objContent.value.indexOf('base64,');
                    buffer = Buffer.from(objContent.value.slice(i + 7), 'base64');
                }
                
                const file = new File([buffer], name, { type: 'image/png' });
                var p = iu.UploadImportedFile(file, randompages).then(res => {
                    var cont = JSON.parse(element.content)
                    cont.url = res.url
                    element.content = JSON.stringify(cont)
                    delete element['imgB64']
                    delete element['fileName']
                })
                promiseArr.push(p)
            } else if (element.objType === 'drawPaper') {
                //inbuilt stickers issue of importing from another instance
                var isCroppedOrCopied = false
                var eleType = JSON.parse(element.type)
                var inflatContent;
                if (eleType && eleType.compressed) {
                    inflatContent = pako.inflate(element.content, { to: 'string' });
                    const ct = JSON.parse(inflatContent)
                    if(typeof(ct[1].source) ==='string'){
                        isCroppedOrCopied = ct[1].source.includes('data:image/png;base64')
                        if(isCroppedOrCopied){
                            element.imgB64 = ct[1].source
                        }
                    }
                    deepReplaceSourceURLs(ct)
                    var deflatContent = pako.deflate(JSON.stringify(ct), { to: 'string' });
                    element.content = deflatContent
                }
                if (!element.fileName && !element.imgB64 && !isCroppedOrCopied) {
                    //this is for drawPaper obj but inbuilt stickers/objects
                    return
                }
                var file;
                if (isJsonFile|| element.imgB64) {
                    const i = element.imgB64.indexOf('base64,');
                    const buffer = Buffer.from(element.imgB64.slice(i + 7), 'base64');
                    file = new File([buffer], name, { type: 'image/png' });
                } else {
                    const currObj = unZippedFilesOrJsonData.find((u) => u.key === element.fileName)
                    let fExt = getFileExt(currObj)
                    const i = currObj.value.indexOf('base64,');
                    let buffer = Buffer.from(currObj.value.slice(i + 7), 'base64');
                    file = new File([buffer], name, { type: fExt });
                }
                
                var ff = iu.UploadImportedFile(file, randompages).then(res => {
                    if (res === undefined)
                        return
                    var eleType = JSON.parse(element.type)
                    var inflatContent;
                    var deflatContent;
                    if (eleType && eleType.compressed) {
                        inflatContent = pako.inflate(element.content, { to: 'string' });
                        const ct = JSON.parse(inflatContent)
                        if (ct[0] === "Group" && !isJsonFile) {
                            if (ct[1] && ct[1].data && ct[1].data.linkData && ct[1].data.linkData.type === 'media') {
                                ct[1].source = res.img
                                ct[1].data.linkData.file = res.url
                                deepReplaceMediaURLs(ct, res.url)
                                deflatContent = pako.deflate(JSON.stringify(ct), { to: 'string' });
                                element.content = deflatContent
                            }
                        } else {
                            ct[1].source = res.img
                            deflatContent = pako.deflate(JSON.stringify(ct), { to: 'string' });
                            element.content = deflatContent
                        }
                    } else {
                        var cont = JSON.parse(element.content)
                        cont[1].source = res.img
                        element.content = JSON.stringify(cont)
                    }
                    delete element['imgB64']
                    delete element['fileName']
                })
                promiseArr.push(ff)
            }
        } catch (error) {
                console.error('HandleImport Error', error)
        }
        });
    }

    var count = 1;
    Promise.all(promiseArr).then(values => {
        ib.importBoard(user, sesssions.data, objects.data, done)
        function done(id) {
            if (count === sesssions.data.length) {
                cb('Imported Successfully, Board will be available under Manage Boards')
            }
            count++;
            return
        }
    });
}


export function getIconColor(id) {
    var splitedId = id.split("-pgNum-")
    var index = 0
    if(splitedId[0]){
        var sesId = splitedId[0]
        //used sessIds last number and converted to hexa number and then used the rightmost digit
        // using this sessIds to keep the color of the board as same even if new board is added or modified
        var rightMostChars = sesId.slice(sesId.length - 2, sesId.length)
        index = parseInt(rightMostChars, 16)
    }
    
    var colorsSet = [
        { color: '#5491CE' },
        { color: '#F4881C' },
        { color: '#009CE0' },
        { color: '#C2CFD7' },
        { color: '#87DEFF' },
        { color: '#9F8FCE' },
        { color: '#71C001' },
        { color: '#27BBCB' },
        { color: '#FED766' },
        { color: '#0C797D' },
    ]
    return colorsSet[index % 10]
}

function deepReplaceMediaURLs(content, newUrl) {
    if (content && content[0] && (typeof content[0] === 'string' || content[0] instanceof String)) {
        if (content[0] === "Group") {
            if (content[1].children) {
                content[1].children.forEach(c => deepReplaceMediaURLs(c, newUrl))
            }
        }
        if ((content[1] && content[1].data && content[1].data.linkData && content[1].data.linkData)) {
            content[1].data.linkData.file = newUrl
        }
    }
}

export function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}


export function RGBAToHexA(r,g,b,a) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    a = Math.round(a * 255).toString(16);
  
    if (r.length === 1)
      r = "0" + r;
    if (g.length === 1)
      g = "0" + g;
    if (b.length === 1)
      b = "0" + b;
    if (a.length === 1)
      a = "0" + a;
  
    return "#" + r + g + b + a;
  }