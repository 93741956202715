/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import WbcJrLongMenu from './WbcJrLongMenu'
// import { ReactComponent as ShareIcon } from './share.svg';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import './styles/style.css'
import EventPopper from "./EventPopper";

import * as mylocalStorage from "./mylocalStorage";
const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        // zIndex: theme.zIndex.drawer + 1,
        background: 'transparent',
        boxShadow: 'none',
        color: '#72869F',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    typographyCenter: {
        flexGrow: 1,
        align: "center"
    },
    menuButton: {
        background: "#3174F5",
        color: "#ffffff",
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        height: 'auto',
        // backgroundColor: "#3174F5",
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        top: "8vh",
        height: "auto",
        // background: "#3174F5",
        borderRadius: "0px",
        overflowX: 'hidden',
        width: theme.spacing(6) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(6) + 1,
        },
    },
    drawerCloseTop: {
        top: "8vh",
        margin: '0px 0px 0px 10px',
        width: '60px',
        height: 'auto',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerCloseTopMSTeams: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerOpenTop: {
        margin: '0px 0px 0px 10px',
        width: drawerWidth,
        borderRadius: '0px',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerOpenTopMSTeams: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 0),
        // necessary for content to be below app bar
        // ...theme.mixins.toolbar,
    },
    iconButton: {
        color: '#ffffff',
    },
    iconButtonHelp: {
        color: '#3174F5',
        marginRight: "15px",
    },
    inviteButton: {
        backgroundColor: "#3174F5",
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: '5px',
        marginRight: "5px",
        padding: "3px",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    rightSide: {
        marginLeft: 'auto',
        overflowX: 'auto',
        overflowY: 'hidden'
    },
    inviteBar: {
        backgroundColor: '#D9FFA2',
        justifyContent: 'space-between !important'
    },
    ml4: {
        marginLeft: '4px'
    },
    paper: {
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        maxHeight: "56vh",
        width: "345px",
        overflow: "auto",
        zIndex: "105",
        boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
    },
    tooltip: {
        margin: "4px 0",
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#1E2639",
        fontSize: "0.8rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16)'
    },
    customArrow: {
        color: "#1E2639",
    },
    dialog: {
        width: "250px",
        height: "65px",
        backgroundColor: "#FFFFFF",
    },
    dialogText: {
        textAlign: "center",
    },
    okButton: {
        backgroundColor: "#3174F5",
        margin: "0px 20px",
        height: '38px',
        width: "80px",
        fontSize: "14px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'flex',
        justifyContent: "space-around",
        borderRadius: '999px',
        border: "1px solid #C8CEDA",
        color: "#ffffff",
        "&:hover": {
            color: '#2F3D5B !important',
        },
    },
    appRelative: {
        left: '0px',
        background: 'white',
        padding: '0 10px 0 65px',
        boxShadow: '0px 0px 8px 2px rgba(13, 20, 22, 0.02)'
    },
    drawerNew: {
        width: '50px',
        top: '0',
        margin: '0',
        borderRadius: '0',
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        overflow: 'hidden'
    },

    marginy10: {
        margin: '0px 0px 0px 10px'
    },
    dots: {
        color: "#7D8FB6",
        position: "relative",
        top: "-5px",
        "&:hover": {
            color: "#3174F5",
            cursor: "move",
        },
    },
    headerDots: {
        width: "100%",
        height: "7px",
        display: "flex",
        justifyContent: "space-around",
        backgroundColor: "#E2E6E9",
    },
    replication: {
        fontSize: "16px",
        fontFamily: 'lato',
        fontWeight: 'normal',
        color: "#2F3D5B",
        marginRight: "10px",
    },
    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        height: "fit-content",
        zIndex: 105,
        color: "#ffffff",
        padding: '8px 10px 2px',
        boxShadow: '0px 10px 15px -3px rgb(0 0 0 / 10%), 0px 4px 6px -2px rgb(0 0 0 / 5%)',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF'

    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "20px",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1"
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        color: "#ffffff",
    },
}));

function MiniDrawer(props) {
    const classes = useStyles();
    const boardConfig = useSelector((state) => state.BoardConfig);
    const tab = useSelector((state) => state.tab);
    const dispatch = useDispatch();
    const teacherR = useSelector((state) => state.teacher);
    const popperRef = React.useRef(null)
    const [boardName, setBoardName] = React.useState("");
    const [statusMsg, setStatusMsg] = React.useState("")
    React.useEffect(() => {
        var lu = mylocalStorage.getItem("palette")
        if (lu) {
            dispatch(Actions.setPalette(JSON.parse(lu)))
        }
        if (props.sess) {
            var jj = JSON.parse(props.sess.boardConfig)
            if (jj && jj['Video'] && tab.allTabs.length === 0) {
                addVideoCall(jj['Video'])
            }
            if (jj && jj['Video'] === false && tab.allTabs.length !== 0) {
                //close video 
                dispatch(Actions.setTab({
                    selected: 0,
                    allTabs: []
                }))
            }


            if (boardName !== props.sess.name) {
                let bname = props.sess.name;
                if (props.sess.name === "unsaved" || props.sess.name.match(/^unsaved\(([\d]+)\)$/)) {
                    bname = "";
                }
                setBoardName(p => bname);
            }

        } else {
            dispatch(Actions.setTab({
                selected: 0,
                allTabs: []
            }))
        }
    }, [props.sess, props.gridView, teacherR])

    React.useEffect(() => {
        if (!props.sess) {
            return;
        }
        if (props.sess.isGroup) {
            return;
        }
        var localID = "";
        if (props.user) {
            localID = JSON.parse(props.user.content).localID;
        }
        if (props.sess.CreatorLocalID === localID) {
            return;
        }
    }, [props.sess, props.user, teacherR])


    function addVideoCall(server) {
        var tabA = []
        mylocalStorage.setItem("videoserver", server)
        dispatch(Actions.setBoardConfig({
            ...boardConfig,
            Video: server,
        }))
        tabA.push({ name: 'Whiteboard', type: 'whiteboard' })
        tabA.push({ name: 'Conference', type: 'conference' })
        dispatch(Actions.setTab({
            ...tab,
            selected: 0,
            allTabs: [...tabA]
        }))
    }

    const handleDrawerOpen = () => {
        if (props.drawerCB) {
            props.drawerCB(true);
        }
    };


    function clickAwayBoardName(e) {
        document.removeEventListener('click', clickAwayBoardName);
        var name = document.getElementById("bname");
        if (!name) return;
        if (e.target !== name) {
            handleBoardNameChange(e);
        }
    }

    function handleBoardNameChange(e) {
        e.preventDefault();
        document.removeEventListener('click', clickAwayBoardName);
        // Don't use boardName here, it could be stale.
        props.renameCB(document.getElementById("bname").value);
    }
    return (
        <div className={classes.root}>

            <Drawer
                variant="permanent"
                className='newDrawer wbcDrawer'
            >
                <EventPopper  {...props} popperRef={popperRef.current} />

                <WbcJrLongMenu {...props} handleDrawerOpen={handleDrawerOpen} statusMsg={statusMsg} setStatusMsg={setStatusMsg} penBtnColor={props.penBtnColor} />
            </Drawer>


        </div >
    );
}
export default React.memo(MiniDrawer)